import React from 'react'
import styled from 'styled-components'

export const UserMenuItem = ({ icon, label, additional, href, onClick, target = '_self' }) => {
  return (
    <StyledLink href={href} onClick={onClick} target={target}>
      {icon}
      {label}
      {additional}
    </StyledLink>
  )
}

const StyledLink = styled.a`
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px 16px 8px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: ${({ theme }) => theme.headerLogin.userMenu.item.color};
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.headerLogin.userMenu.item.hover.color};
    background: ${({ theme }) => theme.headerLogin.userMenu.item.hover.backgroundColor};
    text-decoration: none;
  
    path {
      fill: ${({ theme }) => theme.headerLogin.userMenu.item.hover.color};
  }
}
`
