import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import MobileArticleImageBox from '../../../boxes/StyledMobileArticleImageBox'
import WidgetContainer from '../../../containers/WidgetContainer'
import { ImageRow } from '../../../utils/Grid'
import MobileBoxesGenerator from '../../../utils/MobileBoxesGenerator'
import ImageBoxWrapper from '../../Articles/ImageBoxWrapper'
import RegionPickerTrigger from '../RegionPickerTrigger'
import Regions from '../Regions'
import { DRBNA_REGIONS } from '../../../../constants'
import Loader from '../../../utils/Loader'
import RegionalWidget from '../RegionalWidget'
import type { BaseWidgetProps } from '../../../Types'

export default class MobileRegional extends RegionalWidget {
  constructor (props: BaseWidgetProps) {
    super(props)

    this.persistedKeys.push('isOpen')

    Object.assign(this.state, {
      isOpen: true
    })

    this.handleToggleRegionsPicker = this.handleToggleRegionsPicker.bind(this)
  }

  handleToggleContent () {
    const {
      isOpen
    } = this.state

    this.setState({
      isOpen: !isOpen
    })
  }

  handleToggleRegionsPicker: () => void
  handleToggleRegionsPicker () {
    this.setState({
      isRegionPickerOpen: !this.state.isRegionPickerOpen
    })
  }

  render () {
    const {
      props: {
        activeDynamicLayout,
        isOpening,
        widget,
        widget: {
          ad,
          imageSize,
          name,
          boxes,
          utmContent,
          utmMedium,
          utmSource
        },
        renderAdditionalComponent,
        googleAnalytics
      },
      state: {
        isOpen,
        isRegionPickerOpen,
        activeRegion,
        selectedRegionNews,
        isLoading,
        shouldShow
      }
    } = this

    if (!shouldShow) return null

    const utmParams = {
      utmContent,
      utmMedium,
      utmSource
    }

    const renderImageBox = (box, index) => <ImageBoxWrapper
      box={box}
      imageBoxComponent={MobileArticleImageBox}
      imageSize={imageSize}
      index={index}
      key={`mobileArticlesBox${index}`}
      utmParams={utmParams}
      handleAnchorClick={this.handleAnchorClick}
    />

    const additionalHeadlineContent = (
      <Fragment>
        <RegionPickerTrigger
          activeRegion={activeRegion}
          isOpen={isRegionPickerOpen}
          onClick={this.handleToggleRegionsPicker}
          activeDynamicLayout={activeDynamicLayout}
          ref={ref => { this.regionPickerRef = ReactDOM.findDOMNode(ref) }}
        />
      </Fragment>
    )

    return (
      <WidgetContainer
        activeDynamicLayout={activeDynamicLayout}
        handleToggleContent={this.handleToggleContent.bind(this)}
        additionalHeadlineContent={isOpen && additionalHeadlineContent}
        isOpen={isOpen}
        isOpening={isOpening}
        widget={widget}
        googleAnalytics={googleAnalytics}
      >
        {
          isLoading &&
          <Loader/>
        }
        { renderAdditionalComponent && renderAdditionalComponent() }
        <ImageRow>
          <MobileBoxesGenerator
            ad={ad}
            boxes={selectedRegionNews.length ? selectedRegionNews : boxes}
            device={activeDynamicLayout}
            name={name}
            renderImageBox={renderImageBox}
          />
        </ImageRow>
        <Regions
          ref={ref => { this.regionsRef = ReactDOM.findDOMNode(ref) }}
          regions={Object.keys(DRBNA_REGIONS)}
          activeRegion={activeRegion}
          device={activeDynamicLayout}
          isOpen={isRegionPickerOpen}
          onRegionClick={(newRegion) => {
            this.handleRegionChange(newRegion)
            this.handleToggleRegionsPicker()
          }}
          onRemoveClick={() => {
            this.removeWidget()
            this.handleToggleRegionsPicker()
          }}
        />
      </WidgetContainer>
    )
  }
}
