import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row'
import styled from 'styled-components'

const BottomSection = styled(Row)`
  background: #fff;
  margin: 0;
  padding: 0 8px;
  position: relative;
  z-index: 10;
`

const SectionWrapper = styled.div`
  flex: 1 1 1164px;
  -ms-flexbox: 1 1 1164px;
  max-width: 1164px;
`

export default class Bottom extends Component {
  render () {
    return (
      <BottomSection>
        <SectionWrapper>
          { this.props.children }
        </SectionWrapper>
      </BottomSection>
    )
  }
}
