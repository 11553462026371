import styled from 'styled-components'
import ArticleBox from './ArticleBox'

export default styled(ArticleBox)`
  border-top: 1px solid #d6d6d6;
  color: #8b8b8b;
  display: flex;
  display: -ms-flexbox;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-top: 10px;
  width: 100%;

  & .image-column {
    height: 62px;
    width: 82px;
    position: relative;
  }

  & .image-column img {
    height: 62px;
    width: 82px;
  }
  
  & .image-column::before {
    background-color: #8b8b8b;
    bottom: 0;
    color: #fff;
    content: 'Reklama';
    display: block;
    font-size: 8px;
    left: 0;
    line-height: 11px;
    opacity: 0.8;
    padding: 1px 5px;
    position: absolute;
    z-index: 1;
  }

  & .title-column {
    font-size: 12px;
    flex: 1 1 0%;
    -ms-flexbox: 1 1 0%;
    -ms-flex-negative: 1;
    line-height: 18px;
    margin-left: 10px;
  }
`
