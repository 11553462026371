import React, { createContext } from 'react'
import System from '../components/utils/System'

const SystemContext = createContext({})
const SystemConsumer = SystemContext.Consumer

function SystemProvider (props) {
  return (
    <SystemContext.Provider value={new System()}>
      {props.children}
    </SystemContext.Provider>
  )
}

export {
  SystemContext,
  SystemConsumer,
  SystemProvider
}
