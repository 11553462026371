import React, { Component } from 'react'
import ImageBoxWrapper from './ImageBoxWrapper'
import FirstBox from './StyledFirstBox'
import SmallBox from './StyledSmallBox'
import ContainerRow from './StyledContainerRow'
import LeftColumn from './StyledLeftColumn'
import RightColumn from './StyledRightColumn'
import { sendArticleClick } from '../../utils/ga4Events'

export default class Dynamiclead extends Component {
  render () {
    const {
      widget: {
        boxes,
        name,
        imageSize,
        utmContent,
        utmMedium,
        utmSource
      },
      activeDynamicLayout
    } = this.props

    if (!boxes || !boxes.length) return null

    const smallBoxes = [...boxes]
    const firstBox = Object.assign({}, smallBoxes.shift(), {
      featuredSize: 'big'
    })

    const utmParams = {
      utmContent,
      utmMedium,
      utmSource
    }

    const handleClick = ({ box, position }) => () =>
      sendArticleClick({
        position,
        boxName: name,
        url: box.link,
        id: box.guid,
        title: box.title
      })

    const renderSmallImageBox = (box, index) =>
      <ImageBoxWrapper
        box={box}
        imageBoxComponent={SmallBox}
        imageBoxComponentProps={{ handleAnchorClick: handleClick({ box, position: index + 2 }) }}
        imageSize={imageSize}
        index={index + 1}
        key={`dynamicLeadBox${index + 1}`}
        utmParams={utmParams}
        layout={activeDynamicLayout}
      />

    return (
      <ContainerRow id={name}>
        <LeftColumn>
          <ImageBoxWrapper
            box={firstBox}
            imageBoxComponent={FirstBox}
            imageBoxComponentProps={{ handleAnchorClick: handleClick({ box: firstBox, position: 1 }) }}
            imageSize='1_1L'
            index={0}
            utmParams={utmParams}
            layout={activeDynamicLayout}
          />
        </LeftColumn>
        <RightColumn>
          {
            smallBoxes.map(renderSmallImageBox)
          }
        </RightColumn>
      </ContainerRow>
    )
  }
}
