import React, { memo, useEffect, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { sashecQueuePush } from '../../utils/SashecHelpers'

const SashecAdvert = ({
  id,
  type = 'position',
  pos,
  async = true,
  supertag,
  group = 'default'
}) => {
  useLayoutEffect(() => {
    const callback = event => {
      if (event.type === 'insert' && !event.is_empty) {
        const advertLabelContainerNode = document.getElementById(id).parentNode.firstChild
        if (advertLabelContainerNode) {
          advertLabelContainerNode.style.display = 'block'
        }
      }
    }

    const sashecPayload = [
      type,
      id,
      {
        pos,
        targets: { supertag },
        async,
        callback
      },
      group
    ]

    sashecQueuePush(sashecPayload)

    return () => {
      sashecQueuePush(['removePosition', id])
    }
  }, [])

  useLayoutEffect(() => {
    sashecQueuePush(['loadPosition', { elementId: id, groupId: group }])
  }, [])

  return (
    <div id={id}/>
  )
}

const ClientOnly = ({ children }) => {
  const [showContent, setShowContent] = useState(false)

  useEffect(() => {
    setShowContent(true)
  }, [])

  if (showContent) {
    return <>{children}</>
  }
  return null
}

const SashecWrapper = props => <ClientOnly><SashecAdvert {...props}/></ClientOnly>

SashecAdvert.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string,
  async: PropTypes.bool,
  pos: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  supertag: PropTypes.array.isRequired,
  group: PropTypes.string
}

export default memo(SashecWrapper, (prevProps, nextProps) => prevProps.id === nextProps.id)
