import styled from 'styled-components'
import { RegularCol } from '../../utils/Grid'
import { SIZES } from '../../../constants'

export default styled(RegularCol)`
  display: flex;
  display: -ms-flexbox;
  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    flex-direction: column;
    -ms-flex-direction: column;
  }
`
