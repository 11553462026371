import styled from 'styled-components'
import { SIZES } from '../../../../constants'
import { rgba } from 'polished'

export default styled.input`
  border: 2px solid ${({ theme }) => rgba(theme.header.search.form.input.borderColor, 0.5)};
  border-radius: 6px 0 0 6px;
  border-right: 0;
  color: ${props => props.theme.header.search.form.input.color};
  font-size: 14px;
  height: 47px;
  text-indent: 12px;
  padding: 0 32px;
  width: 100%;
  
  background-image: url(${STATICS_PATH}images/search/magnifying-glass.svg);
  background-position: 16px center;
  background-repeat: no-repeat;
  background-size: 17px 17px;

  &:focus {
    outline: 0;
  }

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    height: 30px;
  }

  @media screen and (max-width: 800px) and (-ms-high-contrast: active), screen and (max-width: 800px) and (-ms-high-contrast: none) {
    margin-bottom: 10px;
  }
`
