export const trimToFullWordsString = (stringToTrim, maxLength) => {
  if (stringToTrim.length <= maxLength) {
    return stringToTrim
  }
  const trimmedString = stringToTrim.slice(0, maxLength)

  const delimiter = /[,.;:]? /g
  const lastIndex = trimmedString.match(delimiter)
  if (lastIndex && lastIndex.length > 0) {
    const trimPosition = trimmedString.lastIndexOf(lastIndex[lastIndex.length - 1])

    return `${trimmedString.slice(0, trimPosition)}...`
  }

  return `${trimmedString}...`
}
