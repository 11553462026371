import React from 'react'
import PropTypes from 'prop-types'
import PersistedComponent from '../../PersistedComponent'
import Form from './StyledSearchForm'
import AutocompleteSearch from './Autocomplete/index'
import NormalSearch from './NormalSearch'
import SearchButton from './StyledSearchButton'
import DictionarySelect from './StyledDictionarySelect'
import { SECTION_NAMES, SERVICE_URL_PATTERN } from '../../../../constants'
import { createLinkSignature } from '../../../utils/linkSignature'
import { sendWidgetClick } from '../../../utils/ga4Events'

class SearchForm extends PersistedComponent {
  constructor (props) {
    super(props)

    this.persistedKeys = [ 'shouldShowAutocomplete' ]

    this.state = Object.assign({
      shouldShowAutocomplete: true
    }, this.state)

    this.handleSearchFormSubmit = this.handleSearchFormSubmit.bind(this)
    this.handleDisableAutocompleteClick = this.handleDisableAutocompleteClick.bind(this)
  }

  handleSearchFormSubmit () {
    const {
      props: {
        secret
      },
      searchForm: {
        elements: {
          q: {
            value
          }
        }
      }
    } = this

    const csrfInput = this.createHiddenInput('csrf', createLinkSignature(value, secret))
    this.searchForm.appendChild(csrfInput)
    this.searchForm.submit()
  }

  handleDisableAutocompleteClick () {
    if (typeof window !== 'undefined') {
      if (window.confirm('Opravdu chcete našeptávač vypnout?')) {
        this.setState({
          shouldShowAutocomplete: false
        })
      }
    }
  }

  isAutocompleteEnabledForSection (section) {
    return section === SECTION_NAMES.INTERNET
  }

  handleSubmit (e) {
    e.preventDefault()

    if (e.target.elements['q']) {
      const {
        secret,
        section,
        widget
      } = this.props

      const value = e.target.elements['q'].value
      const csrfInput = this.createHiddenInput('csrf', createLinkSignature(value, secret))
      e.target.appendChild(csrfInput)
      sendWidgetClick({
        name: widget.name,
        click: {
          value,
          type: `searchTab - ${section.name}`
        }
      })
    }

    e.target.submit()
  }

  createHiddenInput (name, value) {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = name
    input.value = value
    return input
  }

  render () {
    const {
      activeSection: {
        name,
        params,
        url: searchUrl
      },
      activeSection,
      activeSection: {
        name: activeSectionName
      },
      widget: {
        autoFocus
      },
      proxyOptions,
      utmMedium,
      utmSource
    } = this.props

    const {
      shouldShowAutocomplete
    } = this.state

    const SearchComponent = shouldShowAutocomplete && this.isAutocompleteEnabledForSection(activeSectionName) ? (
      <AutocompleteSearch
        serviceUrlPattern={SERVICE_URL_PATTERN}
        activeSection={activeSection}
        autoFocus={autoFocus}
        handleDisableAutocompleteClick={this.handleDisableAutocompleteClick}
        handleSearchFormSubmit={this.handleSearchFormSubmit}
        proxyOptions={proxyOptions}
      />
    ) : <NormalSearch activeSection={activeSection} autoFocus={autoFocus}/>

    return (
      <Form
        action={searchUrl}
        method='GET'
        onSubmit={e => this.handleSubmit(e)}
        innerRef={form => { this.searchForm = form }}
      >
        { SearchComponent }
        {
          (name === SECTION_NAMES.DICTIONARIES && activeSection.dictionaries.length > 0) && (
            <DictionarySelect name='lang'>
              {
                activeSection.dictionaries.map(({ value, text }) => {
                  return (
                    <option key={`dictionary-${value}`} value={value}>
                      { text }
                    </option>
                  )
                })
              }
            </DictionarySelect>
          )
        }
        <SearchButton>
          <span className='desktopText'>Hledat</span>
          <img src={`${UNVERSIONED_STATICS_PATH}images/header/search/search.svg`} alt='Hledat' className='mobileIcon'/>
        </SearchButton>
        <input type='hidden' name='utm_source' value={utmSource}/>
        <input type='hidden' name='utm_medium' value={utmMedium}/>
        {
          Object.values(params).map(param => {
            return <input key={param.name} type='hidden' name={param.name} value={param.value}/>
          })
        }
      </Form>
    )
  }
}

SearchForm.propTypes = {
  activeSection: PropTypes.object.isRequired,
  utmMedium: PropTypes.string.isRequired,
  utmSource: PropTypes.string.isRequired
}

export default SearchForm
