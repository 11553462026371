import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import parse from 'url-parse'
import { useAddToHomescreenPrompt } from './useAddToHomescreenPrompt'

const Wrapper = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  background: #00467f;
  color: #fff;
  right: 0;
  font-family: "Roboto", Arial, lucida, helvetica, sans-serif;
`

const Info = styled.div`
  display: flex;
  flex-direction: row;
`

const Icon = styled.img`
  height: 43px;
  width: 43px;
`

const Content = styled.div`
  padding-left: 10px;
  flex-direction: column;
`

const Title = styled.h3`
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  
  img {
    padding-bottom: 5.6px;
  }
`

const Text = styled.p`
  font-size: 12px;
  line-height: 14px;
  margin: 0;
`

const Buttons = styled.div`
  display: flex;
  padding-top: 19px;
  font-size: 13px;
  flex-direction: row;
  justify-content: flex-end;
`

const Reject = styled.button`
  text-decoration: underline;
  border: none;
  background: none;
  color: #fff;
`

const Submit = styled.button`
  border-radius: 2px;
  border-style: none;
  background: #f06600;
  color: #fff;
  margin-left: 17px;
  padding: 6px 20px;
  font-weight: bold;
`

export default props => {
  const {
    widget: {
      name,
      meta: {
        gaCategory,
        gaLink
      }
    },
    googleAnalytics
  } = props

  const [ prompt, promptToInstall ] = useAddToHomescreenPrompt()
  const [ isVisible, setVisibleState ] = useState(false)
  const [ isInstalled, setInstalled ] = useState(false)

  const handleReject = () => handleButton('No')
  const handleSubmit = () => handleButton('Yes')

  const handleButton = action => {
    if (action === 'Yes') {
      promptToInstall()
      setInstalled(true)
    }

    setVisibleState(false)
    googleAnalytics.send(gaCategory, action, gaLink)
  }

  useEffect(() => {
    if (prompt && !isInstalled) {
      setVisibleState(true)
    }
  }, [ prompt ])

  const {
    query: {
      testPWA
    }
  } = parse(window.location, true)

  if (!isVisible || !testPWA) {
    return null
  }

  return (
    <Wrapper>
      <Info>
        <Icon src={`${STATICS_PATH}images/${name}/icon.svg`}/>
        <Content>
          <Title>
            <img src={`${STATICS_PATH}images/${name}/logo.svg`}/> vždy po ruce.
          </Title>
          <Text>
            Rychlejší přístup k aktuálnímu dění, zprávám i e-mailu.
          </Text>
        </Content>
      </Info>
      <Buttons>
        <Reject onClick={handleReject}>Ne, děkuji</Reject>
        <Submit onClick={handleSubmit}>Nastavit</Submit>
      </Buttons>
    </Wrapper>
  )
}
