import styled from 'styled-components'

const Label = styled.div`
  flex-grow: 1;
  font-size: 12px;
`

export {
  Label
}
