export const getConsent = () => {
  const didomi = getDidomi()
  const hasDidomiUserConsentStatus = (typeof didomi !== 'undefined') && (didomi.hasOwnProperty('getUserConsentStatus'))
  const {
    vendorId,
    contentPersonalization,
    adsPersonalization,
    productResearch,
    analytics
  } = didomiConfig

  if (!hasDidomiUserConsentStatus) {
    return {
      vendorId: false,
      contentPersonalization: false,
      adsPersonalization: false,
      analytics: false,
      productResearch: false
    }
  }

  const enabledVendorConsents = didomi.getUserStatus().vendors.consent.enabled
  const enabledPurposesConsents = didomi.getUserStatus().purposes.consent.enabled

  return {
    vendorId: enabledVendorConsents.includes(vendorId),
    contentPersonalization: enabledPurposesConsents.includes(contentPersonalization),
    adsPersonalization: enabledPurposesConsents.includes(adsPersonalization),
    analytics: enabledPurposesConsents.includes(analytics),
    productResearch: enabledPurposesConsents.includes(productResearch)
  }
}

export const setGtmConsent = (gtm, action) => {
  gtm('consent', action || 'default', getGtmConsent())
  if (window && window.dataLayer) {
    window.dataLayer.push({ event: 'consent-update' })
  }
}

export const didomiConfig = {
  vendorId: 'c:economiaa-ndEUAX3g',
  contentPersonalization: 'publishers-XWZ2b3AK',
  adsPersonalization: 'publishers-UPqtcgaE',
  analytics: 'publishers-Kqq3iPge',
  productResearch: 'publishers-jRQF2F4U'
}

const getGtmConsent = () => {
  const { contentPersonalization, adsPersonalization, analytics } = getConsent()
  return {
    ad_storage: adsPersonalization ? 'granted' : 'denied',
    analytics_storage: analytics ? 'granted' : 'denied',
    personalization_storage: contentPersonalization ? 'granted' : 'denied'
  }
}

export const getAdsPersonalizationConsent = () => {
  const { adsPersonalization, vendorId } = getConsent()
  return Boolean(adsPersonalization) && Boolean(vendorId)
}

const getDidomi = () => {
  if (window && window.Didomi) {
    return window.Didomi
  }
}
