import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SearchInput from './StyledSearchInput'
import SearchInputWrapper from './StyledSearchInputWrapper'

class NormalSearch extends Component {
  componentDidUpdate () {
    this.input.focus()
  }

  render () {
    const {
      activeSection: {
        inputName,
        inputPlaceholder
      },
      autoFocus
    } = this.props

    return (
      <SearchInputWrapper>
        <SearchInput
          type='text'
          name={inputName}
          accessKey='4'
          innerRef={input => { this.input = input }}
          placeholder={inputPlaceholder || 'Vyhledávat...'}
          autoFocus={autoFocus}
        />
      </SearchInputWrapper>
    )
  }
}

NormalSearch.propTypes = {
  activeSection: PropTypes.object.isRequired
}

export default NormalSearch
