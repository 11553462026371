import React, { Component } from 'react'
import styled from 'styled-components'
import { SIZES } from '../../../constants'

const AdvertSign = styled.div`
  width: 7px;
  height: 35px;
  display: none;
  position: absolute;
  left: -10px;
  top: 1px;
  background: url('${STATICS_PATH}images/com/vertical.png') center no-repeat;
  z-index: -1;

  @media screen and (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    width: 35px;
    height: 7px;
    top: -10px;
    right: 1px;
    left: inherit;
    background: url('${STATICS_PATH}images/com/horizontal.png') center no-repeat;
    z-index: inherit;
  }
`

export default class AdvertSignWrapper extends Component {
  shouldComponentUpdate () {
    return false
  }

  render () {
    return <AdvertSign />
  }
}
