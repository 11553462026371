import React from 'react'
import PersistedComponent from '../../PersistedComponent'
import MobileArticleImageBox from '../../../boxes/StyledMobileArticleImageBox'
import WidgetContainer from '../../../containers/WidgetContainer'
import { ImageRow } from '../../../utils/Grid'
import MobileBoxesGenerator from '../../../utils/MobileBoxesGenerator'
import ImageBoxWrapper from '../ImageBoxWrapper'
import { type ArticlesWidgetProps } from '../Types'
import { NewLabelMobile } from '../../../utils/NewLabelMobile'
import URL from 'url-parse'
import { sendArticleClick } from '../../../utils/ga4Events'

export default class MobileArticles extends PersistedComponent<ArticlesWidgetProps> {
  constructor (props: ArticlesWidgetProps) {
    super(props)

    this.persistedKeys.push('isOpen')

    this.state = Object.assign({ isOpen: true }, this.state)
  }

  handleToggleContent () {
    const {
      isOpen
    } = this.state

    this.setState({
      isOpen: !isOpen
    })
  }

  render () {
    const {
      props: {
        activeDynamicLayout,
        isOpening,
        widget,
        widget: {
          ad,
          boxes,
          imageSize,
          name,
          utmContent,
          utmMedium,
          utmSource,
          enhancedEcommerce,
          isNew,
          sendGaCategory
        },
        renderAdditionalComponent,
        itemVariant
      },
      state: {
        isOpen
      }
    } = this

    const utmParams = {
      utmContent,
      utmMedium,
      utmSource
    }

    const handleEcommerceLink = (event, location, box, position) => {
      event.preventDefault()

      const {
        guid: id,
        title: name,
        link
      } = box

      const purchaseFieldObject = {
        id,
        name
      }

      sendGaCategory && Object.assign(purchaseFieldObject, { category: new URL(link).hostname })
      document.location.href = location
      sendArticleClick({
        id,
        position,
        url: link,
        title: name,
        boxName: widget.name,
        variant: itemVariant
      })
    }

    const handleAnchorClick = enhancedEcommerce ? handleEcommerceLink : null
    const headlineContent = isNew ? <NewLabelMobile/> : null

    const renderImageBox = (box, index) => <ImageBoxWrapper
      box={box}
      imageBoxComponent={MobileArticleImageBox}
      imageSize={imageSize}
      index={index}
      key={`mobileArticlesBox${index}`}
      utmParams={utmParams}
      handleAnchorClick={handleAnchorClick}
    />

    return (
      <WidgetContainer
        activeDynamicLayout={activeDynamicLayout}
        handleToggleContent={this.handleToggleContent.bind(this)}
        isOpen={isOpen}
        isOpening={isOpening}
        widget={widget}
        headlineContent={headlineContent}
      >
        { renderAdditionalComponent && renderAdditionalComponent() }
        <ImageRow>
          <MobileBoxesGenerator
            ad={ad}
            boxes={boxes}
            device={activeDynamicLayout}
            name={name}
            renderImageBox={renderImageBox}
          />
        </ImageRow>
      </WidgetContainer>
    )
  }
}
