// @flow
import React, { type Element } from 'react'
import MobilePrAnnotationBox from '../boxes/StyledMobilePrAnnotationBox'
import SpirMonWrapperWithoutImpression from '../widgets/Advert/SpirMonWrapperWithoutImpression'
import { type BoxType as Box } from '../Types'
import Prannotation from './Prannotation'

const Ad = props => {
  const {
    commentBottom,
    commentTop,
    id: guid,
    image: {
      default: {
        url: image
      }
    },
    title,
    url: link,
    wrapper
  } = props

  return (
    <SpirMonWrapperWithoutImpression commentTop={commentTop} commentBottom={commentBottom} wrapper={wrapper}>
      <MobilePrAnnotationBox
        image={image}
        imageClass={`image-4_3`}
        isImage={true}
        key={guid}
        link={link}
        title={title}
      />
    </SpirMonWrapperWithoutImpression>
  )
}

type Props = {|
  ad?: Object,
  boxes: Array<Box>,
  device: string,
  name: string,
  renderImageBox: (box: Box, index: number) => Element<*>
|}

export default (props: Props) => {
  const {
    ad,
    boxes,
    device,
    name,
    renderImageBox
  } = props

  const renderedBoxes = boxes.map(renderImageBox)

  return ad
    ? <Prannotation AdComponent={Ad} device={device} id={`ad-${name}`} sashec={ad}>
      { renderedBoxes }
    </Prannotation>
    : <>{ renderedBoxes }</>
}
