import styled from 'styled-components'
import { SIZES } from '../../../constants'

export default styled.div`
  padding-right: 10px;
  width: calc((100% - 230px) / 5);

  &:last-child {
    padding-right: 0;
  }
  & h4 {
    font-weight: bold;
    font-size: 15px;
  }
  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    display: none;
  }
`
