import React, { Component, createRef } from 'react'
import styled from 'styled-components'
import AdvertSignWrapper from './AdvertSignWrapper'
import SashecAdvert from './SashecAdvert'
import { sashecReloadPosition } from '../../utils/SashecHelpers'
import { SIZES } from '../../../constants'
import { ResizeObserver } from '@juggle/resize-observer'

const TOP_BANNER_ID = 'top-banner'

const TopBanner = styled.div`
  display: block;
  height: auto;
  max-height: 210px;
  overflow: hidden;
  position: relative;
  text-align: center;

  @media screen and (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    margin: 15px auto;
    width: 100%;
  }

  @media screen and (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    margin-bottom: 10px;
  }
`

const BannerWrapper = styled.div`
  display: block;
  margin: 0 auto;
  max-width: 1080px;
  position: relative;
  text-align: center;
  width: auto;

  @media screen and (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    max-width: 100%;
  }
`

const LEADER_POS = 1

export default class LeaderBoard extends Component {
  constructor (props) {
    super(props)

    this.ref = createRef()

    this.handleVisibilityChange = this.handleVisibilityChange.bind(this)
  }

  shouldComponentUpdate () {
    return false
  }

  componentDidMount () {
    document.addEventListener('visibilitychange', this.handleVisibilityChange)

    this.resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        if (entry.target.id === TOP_BANNER_ID) {
          const height = entry.contentRect.height
          const onHeightChange = this.props.onHeightChange
          onHeightChange && onHeightChange(height)
        }
      }
    })

    if (this.ref.current) {
      this.resizeObserver.observe(this.ref.current)
    }
  }

  componentWillUnmount () {
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    )

    this.resizeObserver.disconnect()
  }

  handleVisibilityChange () {
    const {
      widget: {
        meta: { targetId }
      }
    } = this.props

    if (document.visibilityState === 'visible') {
      sashecReloadPosition(targetId, LEADER_POS, 'rel')
    }
  }

  render () {
    const {
      widget: {
        meta: { targetId, supertag },
        group
      }
    } = this.props

    return (
      <TopBanner id={TOP_BANNER_ID} innerRef={this.ref}>
        <BannerWrapper>
          <div id={`${targetId}-wrapper`}>
            <AdvertSignWrapper />
            <SashecAdvert id={targetId} supertag={supertag} group={group} pos={LEADER_POS} />
          </div>
        </BannerWrapper>
      </TopBanner>
    )
  }
}
