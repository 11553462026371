import styled from 'styled-components'
import ArticleBox from '../../../boxes/ArticleBox'

export default styled(ArticleBox)`
  color: #333;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 10px;
  width: 100%;

  & .image-column {
    height: auto;
    margin-bottom: 5px;
    position: relative;
    width: 100%;
  }
  
  & .image-column img {
    height: 100%;
    width: 100%;
  }

  & .title-column {
    font-size: 14px;
    font-weight: bold;
    flex: 1 1 auto;
    -ms-flexbox: 1 1 auto;
    -ms-flex-negative: 1;
    line-height: 18px;
  }
`
