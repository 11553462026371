import React from 'react'
import styled from 'styled-components'
import NewsFeedAdvertSign from './StyledNewsFeedAdvertSign'
import NewsFeedItem from './NewsFeedItem'

const StyledNewsFeedAdvertSign = styled(NewsFeedAdvertSign)`
  background: url('${STATICS_PATH}images/com/horizontal.png') right no-repeat;
`

const NewsFeedItemAd = props => {
  const {
    box
  } = props

  return (
    <NewsFeedItem {...box}>
      <StyledNewsFeedAdvertSign/>
    </NewsFeedItem>
  )
}

export default NewsFeedItemAd
