import { StyledAvatarContainer } from '../../../../ui/Avatar/StyledAvatar'
import React, { forwardRef } from 'react'

export const InitialsAvatar = forwardRef(({ firstName, lastName, onClick }, ref) => {
  const initials = `${firstName[0] || ''}${lastName[0] || ''}`

  return (
    <StyledAvatarContainer onClick={onClick} innerRef={ref}>
      {initials}
    </StyledAvatarContainer>
  )
})
