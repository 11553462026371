export const TOGGLE_WIDGET_NAMES = {
  news: 'zprávy',
  video: 'video',
  woman: 'magazín',
  sport: 'sport',
  finance: 'finance',
  mobileHousing: 'reality',
  exchange: 'kurzy',
  regional: 'regiony',
  leaflets: 'letáky',
  celebrities: 'celebrity',
  mobileBydleni: 'bydlení',
  car: 'auta'
}
