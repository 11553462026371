import styled from 'styled-components'

const TextContainer = styled.div`
  font-size: 13px;
  margin-bottom: 5px;
  overflow: hidden;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-align: center;
  align-items: center;
`

const ProgressContainer = styled.div`
  height: 2px;
  margin-bottom: 5px;
  background: #dedede;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-align: center;
  align-items: center;
`

export { TextContainer, ProgressContainer }
