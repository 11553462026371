import React, { Component } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import Container from 'reactstrap/lib/Container'
import themes from '../../themes'

export const CENTER_CONTENT_CONTAINER_ID = 'center-content-container'

const CenterContent = styled(Container)`
  background-color: #fff;
  max-width: 1080px;
  padding: 0 8px;
  position: relative;

  @-ms-viewport { 
    width: device-width; 
  }
`

export default class Main extends Component {
  render () {
    const {
      children,
      className,
      layout
    } = this.props

    return (
      <ThemeProvider theme={themes[layout]}>
        <CenterContent id={CENTER_CONTENT_CONTAINER_ID} fluid={true} className={className}>
          { children }
        </CenterContent>
      </ThemeProvider>
    )
  }
}
