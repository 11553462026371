import styled from 'styled-components'
import Link from '../../utils/Link'
import NewsFeedInfo from './StyledNewsFeedInfo'
import NewsFeedTitle from './StyledNewsFeedTitle'
import NewsFeedAdvertSign from './StyledNewsFeedAdvertSign'

export default styled(Link)`
  color: #333;
  height: 100%;

  &:hover {
    text-decoration: none;
  }

  &:hover ${NewsFeedTitle} {
    text-decoration: underline;
  }

  &:hover ${NewsFeedInfo}, &:hover ${NewsFeedAdvertSign} {
    color: #333;
  }
`
