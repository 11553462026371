import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Autocomplete from 'react-autocomplete'
import ExternalService from '../../../../utils/ExternalService'
import request from '../../../../utils/Request'
import AutocompleteSearchInput from './StyledSearchInput'
import AutocompleteResults from './StyledResults'
import AutocompleteResult from './StyledResult'
import AutocompleteFooter from './StyledFooter'

const AUTOCOMPLETE_REQUEST_DELAY_IN_MS = 300

class AutocompleteSearch extends Component {
  constructor (props) {
    super(props)

    this.state = {
      items: [],
      query: ''
    }

    this.handleAutocompleteChange = this.handleAutocompleteChange.bind(this)
    this.handleAutocompleteSelect = this.handleAutocompleteSelect.bind(this)
    this.renderAutocompleteInput = this.renderAutocompleteInput.bind(this)
    this.renderAutocompleteMenu = this.renderAutocompleteMenu.bind(this)
    this.renderAutocompleteItem = this.renderAutocompleteItem.bind(this)
  }

  requestAutocompleteData (query) {
    const {
      proxyOptions
    } = this.props

    const serviceHelper = new ExternalService(proxyOptions)
    const serviceUri = serviceHelper.getServiceUrl(this.props.serviceUrlPattern, {
      query: encodeURIComponent(query)
    })

    if (query !== '') {
      request({
        url: serviceUri
      }).then(response => {
        if (response.data && Array.isArray(response.data) && response.data[1]) {
          return response.data[1].map(result => {
            return {
              originalItem: result,
              highlightedItem: this.highlightSubstring(result, query)
            }
          })
        } else {
          throw new Error('No data received')
        }
      }).then(items => {
        this.setState({
          items
        })
      }).catch(() => {
        this.setState({
          items: []
        })
      })
    } else {
      this.setState({
        items: []
      })
    }
  }

  handleAutocompleteChange (event) {
    const query = event.target.value

    this.setState({
      query
    })

    clearTimeout(this.requestTimeout)
    this.requestTimeout = setTimeout(() => this.requestAutocompleteData(query.toLowerCase()), AUTOCOMPLETE_REQUEST_DELAY_IN_MS)
  }

  handleAutocompleteSelect (query) {
    const {
      handleSearchFormSubmit
    } = this.props

    this.setState({
      query
    }, () => handleSearchFormSubmit())
  }

  renderAutocompleteInput (props) {
    const {
      activeSection: {
        inputName,
        inputPlaceholder
      },
      autoFocus
    } = this.props

    const {
      ref,
      ...rest
    } = props

    return (
      <AutocompleteSearchInput
        {...rest}
        accessKey={4}
        name={inputName}
        innerRef={ref}
        autoFocus={autoFocus}
        placeholder={inputPlaceholder || 'Vyhledávat...'}
      />
    )
  }

  highlightSubstring (item, query) {
    return item.split(query).reduce((prev, current, i) => {
      if (!i) {
        return [current]
      }
      return prev.concat(<b key={i}>{ query }</b>, current)
    }, [])
  }

  renderAutocompleteItem (item, isHighlighted) {
    const {
      originalItem,
      highlightedItem
    } = item

    return (
      <AutocompleteResult key={`autocomplete-${originalItem}`} className={isHighlighted ? 'active' : ''}>
        { highlightedItem }
      </AutocompleteResult>
    )
  }

  renderAutocompleteMenu (items) {
    const {
      activeSection: {
        brandText
      },
      handleDisableAutocompleteClick
    } = this.props

    if (items.length) {
      return (
        <AutocompleteResults>
          { items }
          <AutocompleteFooter>
            <div className='brand-text'>
              { brandText }
            </div>
            <div className='autocomplete-disable' onClick={handleDisableAutocompleteClick}>
              Vypnout našeptávač
            </div>
          </AutocompleteFooter>
        </AutocompleteResults>
      )
    } else {
      return <div/>
    }
  }

  render () {
    const {
      items,
      query
    } = this.state

    return (
      <Autocomplete
        autoHighlight={false}
        getItemValue={item => item.originalItem}
        items={items}
        onChange={this.handleAutocompleteChange}
        onSelect={this.handleAutocompleteSelect}
        renderInput={this.renderAutocompleteInput}
        renderItem={this.renderAutocompleteItem}
        renderMenu={this.renderAutocompleteMenu}
        value={query}
        wrapperProps={{ className: 'autocompleteWrapper' }}
      />
    )
  }
}

AutocompleteSearch.propTypes = {
  activeSection: PropTypes.object.isRequired,
  handleDisableAutocompleteClick: PropTypes.func.isRequired,
  handleSearchFormSubmit: PropTypes.func.isRequired
}

export default AutocompleteSearch
