import styled from 'styled-components'

export default styled.li`
    display: inline-block;
    padding: 0;
    ::after {
      content: "|";
      margin: 0 5px;
      }
    &:last-child {
    ::after {
      content: "";
      margin: 0;
      }
    }
`
