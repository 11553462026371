import React from 'react'
import PersistedComponent from '../../PersistedComponent'
import MobileArticleImageBox from '../../../boxes/StyledMobileArticleImageBox'
import WidgetContainer from '../../../containers/WidgetContainer'
import { ImageRow } from '../../../utils/Grid'
import MobileBoxesGenerator from '../../../utils/MobileBoxesGenerator'
import ImageBoxWrapper from '../ImageBoxWrapper'
import { sendArticleClick } from '../../../utils/ga4Events'

export default class MobileFinance extends PersistedComponent {
  constructor (props) {
    super(props)

    this.persistedKeys.push('isOpen')

    this.state = Object.assign({ isOpen: true }, this.state)
  }

  handleToggleContent () {
    const {
      isOpen
    } = this.state

    this.setState({
      isOpen: !isOpen
    })
  }

  render () {
    const {
      props: {
        activeDynamicLayout,
        isOpening,
        widget,
        googleAnalytics,
        widget: {
          ad,
          boxes,
          imageSize,
          name,
          utmContent,
          utmMedium,
          utmSource
        },
        itemVariant
      },
      state: {
        isOpen
      }
    } = this

    const utmParams = {
      utmContent,
      utmMedium,
      utmSource
    }

    const handleAnchorClick = ({ box, position }) => () =>
      sendArticleClick({
        position,
        id: box.guid,
        url: box.link,
        title: box.title,
        boxName: widget.name,
        variant: itemVariant
      })

    const renderImageBox = (box, index) => <ImageBoxWrapper
      activeDynamicLayout={activeDynamicLayout}
      box={box}
      imageBoxComponent={MobileArticleImageBox}
      imageSize={imageSize}
      index={index}
      key={`mobileFinanceBox${index}`}
      utmParams={utmParams}
      handleAnchorClick={handleAnchorClick({ box, position: index + 1 })}
    />

    return (
      <WidgetContainer
        activeDynamicLayout={activeDynamicLayout}
        handleToggleContent={this.handleToggleContent.bind(this)}
        isOpen={isOpen}
        isOpening={isOpening}
        widget={widget}
        googleAnalytics={googleAnalytics}
      >
        <ImageRow>
          <MobileBoxesGenerator
            ad={ad}
            boxes={boxes}
            device={activeDynamicLayout}
            name={name}
            renderImageBox={renderImageBox}
          />
        </ImageRow>
      </WidgetContainer>
    )
  }
}
