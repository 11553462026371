import styled from 'styled-components'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  color: #fff;
  background: #ef6600;
`

const Container = styled.div`
  max-width: 1180px;
  min-height: 63px;
  margin: auto;
  padding: 0 8px;
  display: flex;
  align-items: center;

  img {
    width: 72px;
  }
`

export {
  Wrapper,
  Container
}
