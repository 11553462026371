import sha256 from 'sha256'
import { hexParse, base64Stringify } from './cryptoJsFunctions'
import Cookie from './Cookie'

const getHexadecimalTimestamp = timestamp => `00000000${timestamp.toString(16)}`.slice(-8)

const getWebSafeSignature = signature => signature.replace(/[//]/g, '_').replace(/[/+]/g, '-')

export const createLinkSignature = (search, secret) => {
  const timestamp = Math.round(new Date().getTime() / 1000) - 1500000000
  const signature = sha256(`${timestamp}-${secret}-${search}`)

  const hexadecimalSignature = `${signature}${getHexadecimalTimestamp(timestamp)}`
  const bites = hexParse(hexadecimalSignature)

  const csrf = base64Stringify(bites)

  const res = getWebSafeSignature(csrf)

  const cookieHandler = new Cookie()
  if (cookieHandler.isSetWithValue('csrf_debug', secret)) {
    console.log('query:', search)
    console.log('signature:', `${timestamp}-${secret}-${search}`)
    console.log('sha256 of query:', sha256(search).toString())
    console.log('sha256 of signature:', signature.toString())
    console.log('csrf base64:', csrf)
    console.log('csrf:', res)
  }

  return res
}
