import styled from 'styled-components'
import { SIZES } from '../../../../constants'

export default styled.div`
  display: flex;
  height: auto;
  position: relative;
  
  @media (min-width: ${SIZES.BREAKPOINT_XS}px) {
    width: 100%;
  }
`
