/* @flow */
import React, { createContext, type Node, PureComponent } from 'react'
import Settings from '../components/utils/Settings'
import utmLinkGenerator from '../components/utils/UTMLinkGenerator'
import { SIGN_INTERVALS, SIGNS_LIST, SIGN_NAMES, SIGNS } from '../constants'

type HoroscopesProviderProps = {|
  children: Node,
  options: {
    boxes: Array<Object>,
    meta: {
      detailLinkPattern: string
    },
    name: string,
    utmContent: string,
    utmMedium: string,
    utmSource: string
  }
|}

type HoroscopesProviderState = {|
  horoscope: Object,
  sign: string
|}

const HoroscopesContext: Object = createContext()

const HoroscopesConsumer = HoroscopesContext.Consumer

class HoroscopesProvider extends PureComponent<HoroscopesProviderProps, HoroscopesProviderState> {
  cachedHoroscopes: Object = {}

  constructor (props: HoroscopesProviderProps) {
    super(props)

    this.state = {
      horoscope: {},
      sign: SIGN_NAMES.BERAN
    }

    this.handleSignChange = this.handleSignChange.bind(this)
  }

  componentWillMount () {
    const {
      cachedHoroscopes,
      props: {
        options: {
          boxes = []
        }
      },
      state: {
        sign
      }
    } = this

    const utmDetailLink = this.createUtmSignLink(sign)
    const horoscope = Object.assign({}, SIGNS[sign], boxes[0] || {}, { link: utmDetailLink })

    this.setState({
      horoscope
    })

    cachedHoroscopes[sign] = horoscope
  }

  componentDidMount () {
    const sign = this.getSignFromSettings()

    if (sign !== SIGN_NAMES.BERAN) {
      this.loadSignData(sign)
    }
  }

  getSignFromSettings () {
    const sign = Settings.getKey(this.props.options.name, {}).sign

    return SIGNS_LIST.includes(sign) ? sign : this.getCurrentSign()
  }

  getCurrentSign (): string {
    const today = new Date()
    const month = today.getMonth()
    const date = today.getDate()

    const monthSign = SIGN_INTERVALS[month]
    const dates = Object.keys(monthSign)

    if (dates[1] > date) {
      return monthSign[dates[0]]
    } else {
      return monthSign[dates[1]]
    }
  }

  loadSignData (sign: string): void {
    const horoscope = this.cachedHoroscopes[sign]

    if (horoscope) {
      this.setState({
        horoscope,
        sign
      })
    } else {
      this.setState({
        horoscope: this.getHoroscopeData(sign),
        sign
      })
    }
  }

  getHoroscopeData (sign: string) {
    const {
      props: {
        options: {
          boxes
        }
      }
    } = this

    return {
      link: this.createUtmSignLink(sign),
      ...SIGNS[sign],
      ...boxes.find(({ guid }) => guid === sign)
    }
  }

  createUtmSignLink: (sign: string) => string
  createUtmSignLink (sign: string): string {
    const {
      options: {
        meta: {
          detailLinkPattern
        },
        utmMedium
      }
    } = this.props

    const utmParams = {
      utmMedium,
      utmTerm: `position-${SIGNS_LIST.indexOf(sign)}`
    }

    const detailLink = detailLinkPattern.replace('{sign}', sign)
    return utmLinkGenerator.createLink(detailLink, utmParams)
  }

  handleSignChange: (newSign: string) => void
  handleSignChange (newSign: string): void {
    Settings.setKey(this.props.options.name, {
      sign: newSign
    })

    this.loadSignData(newSign)
  }

  render () {
    const {
      props: {
        children
      },
      state
    } = this

    const contextValue = Object.assign({}, state, {
      handleSignChange: this.handleSignChange
    })

    return (
      <HoroscopesContext.Provider value={contextValue}>
        { children }
      </HoroscopesContext.Provider>
    )
  }
}

export {
  HoroscopesConsumer,
  HoroscopesProvider
}
