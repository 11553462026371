import styled from 'styled-components'
import Link from '../../../utils/Link'

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`

const AddressInboxLink = styled(Link)`
  display: block;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  color: ${props => props.theme.email.full.link};
  margin-left: 30px;
  
  &:before {
    position: absolute;
    content: '';
    background: url('${props => props.theme.email.full.inboxIcon.background}') center center no-repeat;
    width: 24px;
    height: 24px;
    top: -4px;
    left: -25px;
  }
  
  &:hover {
    color: ${props => props.theme.email.full.link};
  }
`

const DivThemeColor = styled.div`
  color: ${props => props.theme.email.full.number};
  background: ${props => props.theme.email.full.bgNumber};
  border: 1px solid ${props => props.theme.email.full.borderNumber};
  width: 36px;
  height: 24px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
`

export { Header, AddressInboxLink, DivThemeColor }
