/* @flow */
import React, { Fragment } from 'react'
import MoreLink from '../../utils/MoreLink'
import Loader from '../../utils/Loader'
import WidgetContainer from '../../containers/WidgetContainer'
import MoreLinkWrapper from '../../containers/StyledMoreLinkWrapper'
import SignText from './StyledSignText'
import SignSelect from './SignSelect'
import NameContainer from './StyledNameContainer'
import HoroscopeLink from './StyledHoroscopeLink'
import SignIcon from './StyledSignIcon'
import { HoroscopesConsumer } from '../../../context/Horoscopes'
import { type HoroscopesWidgetProps } from './Types'
import { sendWidgetClick } from '../../utils/ga4Events'
import { getLinkTarget } from '../../utils/getLinkTarget'

export default (props: HoroscopesWidgetProps) => {
  const {
    embedded,
    widget,
    googleAnalytics,
    activeDynamicLayout,
    widget: {
      meta: {
        headline: {
          location: headlineLocation
        } = {}
      }
    }
  } = props

  const handleSignClick = (handleSignChange) => (sign) => {
    sendWidgetClick({
      name: widget.name,
      click: {
        value: sign,
        type: 'click'
      }
    })
    handleSignChange(sign)
  }

  const widgetContent = (
    <HoroscopesConsumer>
      {
        ({ handleSignChange, horoscope, isLoading }) => {
          const {
            date,
            guid,
            link,
            name,
            title
          } = horoscope

          return (
            <Fragment>
              {
                isLoading &&
                <Loader/>
              }
              <SignSelect handleSignChange={handleSignClick(handleSignChange)}/>
              <HoroscopeLink href={link} target={getLinkTarget(activeDynamicLayout)} rel='noopener'>
                <NameContainer>
                  <SignText>
                    { name }
                  </SignText>
                  <div>
                    ({ date })
                  </div>
                  <SignIcon sign={guid}/>
                </NameContainer>
                <div>
                  { title }
                </div>
              </HoroscopeLink>
            </Fragment>
          )
        }
      }
    </HoroscopesConsumer>
  )

  if (embedded) {
    const widgetWithMoreLink = Object.assign({}, widget, {
      meta: {
        more: {
          text: 'Více horoskopů',
          location: headlineLocation
        }
      }
    })

    return (
      <Fragment>
        { widgetContent }
        <MoreLinkWrapper>
          <MoreLink widget={widgetWithMoreLink} layout={activeDynamicLayout}/>
        </MoreLinkWrapper>
      </Fragment>
    )
  } else {
    return (
      <WidgetContainer googleAnalytics={googleAnalytics} widget={widget}>
        { widgetContent }
      </WidgetContainer>
    )
  }
}
