import React from 'react'
import styled from 'styled-components'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import { getLinkTarget } from '../../utils/getLinkTarget'

const Container = styled.a`
  position: relative;
  display: flex;
  align-items: start;
  gap: 8px;
  margin: 5px 0 16px;
  white-space: nowrap;

  &:hover {
    text-decoration: none;
`

const Image = styled.img`
  height: 35px;
  width: auto;
  align-self: start;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-size: 19px 27px;
`

const BoldText = styled.p`
  font-weight: bold;
  color: ${props => props.theme.header.promolinkheader.darkColor};
  font-size: 13px; 
  line-height: 16px;
  margin: 0;
`

const ColorfulText = styled.p`
  font-weight: normal;
  color: ${props => props.theme.header.promolinkheader.color};
  font-size: 13px;
  word-wrap: break-word;
  white-space: normal;
  margin: 0;
  line-height: 16px;
`

// guarantees that the icon is always wrapped together with the word preceding it
// the goal is to prevent an edge case where the icon is wrapped alone to the next line
const WordWithIcon = styled.span`
  white-space: nowrap;
  
  &:after {
    content: '';
    background: url('${STATICS_PATH}images/promolinkheader/promo_arrow.svg') left top no-repeat;
    background-size: cover;
    width: 14px;
    Height: 14px;
    display: inline-block;
    margin-left: 5px;
    vertical-align: sub;
  }
`

const SelfPromo = ({ widget, layout }) => {
  const {
    utmMedium,
    utmCampaign,
    boxes: [
      {
        title,
        images,
        link,
        annotation
      }
    ]
  } = widget

  const [imageUrl] = Object.values(images)

  return (
    <Container
      href={utmLinkGenerator.createLink(link, {
        utmMedium,
        utmCampaign
      })}
      target={getLinkTarget(layout)}
      rel='noopener'
    >
      <Image src={imageUrl} alt={title}/>
      <TextContainer>
        <BoldText>{title}</BoldText>
        <Annotation annotation={annotation}/>
      </TextContainer>
    </Container>
  )
}

const Annotation = ({ annotation }) => {
  if (annotation.trim() === '') {
    return null
  }

  const words = annotation.split(' ')
  const lastWord = words[words.length - 1]
  const allButLast = words.slice(0, -1).join(' ')

  return (
    <ColorfulText>
      {allButLast}{' '}<WordWithIcon>{lastWord}</WordWithIcon>
    </ColorfulText>
  )
}

export default SelfPromo
