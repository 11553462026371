import parse from 'url-parse'
import UrlPattern from 'url-pattern'
const REDIRECT_PATTERNS = {
  SAS: 'https\\://a.centrum.cz/cent/adclick/FCID=(:fcid)/site=hp/area=(:area)/size=measurement/device=(:device)/relocate=(:relocate)'
}

/**
 * Link Handling Helper Class
 */
class LinkHandler {
  /**
   * Constructor
   * @param {string} [link = './']
   */
  constructor (link = './') {
    this._link = link
  }

  /**
   * get object with UTM params according to specification
   *
   * @link https://en.wikipedia.org/wiki/UTM_parameters
   * @param {Object} [utmParams = {}]
   * @param {string} utmParams.utmSource
   * @param {string} utmParams.utmMedium
   * @param {string} utmParams.utmContent
   * @param {string} utmParams.utmTerm
   * @param {string} utmParams.utmCampaign
   * @return {Object}
   */
  getUTMQueryParams (utmParams = {}) {
    const {
      utmSource,
      utmMedium,
      utmContent,
      utmTerm,
      utmCampaign
    } = utmParams

    const utmQueryParams = {}

    if (utmSource) {
      utmQueryParams['utm_source'] = utmSource
    }

    if (utmMedium) {
      utmQueryParams['utm_medium'] = utmMedium
    }

    if (utmContent) {
      utmQueryParams['utm_content'] = utmContent
    }

    if (utmTerm) {
      utmQueryParams['utm_term'] = utmTerm
    }

    if (utmCampaign) {
      utmQueryParams['utm_campaign'] = utmCampaign
    }

    return utmQueryParams
  }

  /**
   * get parsed url
   *
   * @see https://www.npmjs.com/package/url-parse
   * @return {*}
   */
  getParsedLink () {
    return parse(this.link, true)
  }

  /**
   * get link extended by given UTM parameters
   *
   * @param {Object} [utmParams = {}]
   * @param {string} utmParams.utmSource
   * @param {string} utmParams.utmMedium
   * @param {string} utmParams.utmContent
   * @param {string} utmParams.utmTerm
   * @param {string} utmParams.utmCampaign
   * @return {LinkHandler}
   */

  addUTMParams (utmParams = {}) {
    const utmQueryParams = this.getUTMQueryParams(utmParams)

    const url = this.getParsedLink()
    Object.assign(url.query, utmQueryParams)

    this.link = url.toString()

    return this
  }

  /**
   * get link extended by given UTM parameters
   *
   * @param {Object} [utmParams = {}]
   * @param {string} utmParams.utmSource
   * @param {string} utmParams.utmMedium
   * @param {string} utmParams.utmContent
   * @param {string} utmParams.utmTerm
   * @param {string} utmParams.utmCampaign
   * @return {LinkHandler}
   */

  mergeUTMParams (utmParams = {}) {
    const utmQueryParams = this.getUTMQueryParams(utmParams)

    const url = this.getParsedLink()
    url.query = Object.assign({}, utmQueryParams, url.query)

    this.link = url.toString()

    return this
  }

  /**
   * apply Admon options and modify link to SAS redirect
   *
   * @param {Object} options
   * @return {LinkHandler}
   */
  applySASRedirect (options = {}) {
    if (options && Object.keys(options).length > 0) {
      options.relocate = this.link
      if (!options.device) {
        options.device = 'd'
      }
      const pattern = new UrlPattern(REDIRECT_PATTERNS.SAS)
      const urlEncodedOptions = Object.assign({}, options)
      Object.keys(urlEncodedOptions).forEach(index => {
        urlEncodedOptions[index] = encodeURIComponent(urlEncodedOptions[index])
      })
      this.link = pattern.stringify(urlEncodedOptions)
    }

    return this
  }

  set link (link) {
    this._link = link
  }

  get link () {
    return this._link
  }
}

export default LinkHandler
