import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'

export const Modal = ({ onClose, children }) => {
  const ref = useRef()

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [])

  const handleOutsideClick = (e) => {
    const element = ref.current

    if (!element) {
      return
    }

    if (!element.contains(e.target)) {
      onClose()
    }
  }

  return (
    <StyledBackdrop>
      <StyledContent innerRef={ref}>
        <StyledHeader>
          <StyledCloseButton onClick={onClose}>&times;</StyledCloseButton>
        </StyledHeader>
        <StyledScrollableContent>
          {children}
        </StyledScrollableContent>
      </StyledContent>
    </StyledBackdrop>
  )
}

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`

const StyledContent = styled.div`
  background-color: white;
  padding: 32px 7px 32px 32px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  max-width: 640px;
  width: 100%;
`

const StyledHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  padding: 6px;
`

const StyledCloseButton = styled.button`
  background: transparent;
  border: 1px solid;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  padding: 0;
`

const StyledScrollableContent = styled.div`
  min-height: 540px;
  max-height: 540px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: #e7e7e7;
    border: 0.5px solid #cacaca;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.15);
  }
`
