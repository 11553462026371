import styled from 'styled-components'

export default styled.div`
  display: flex;
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  height: 26px;
  justify-content: space-between;
  margin-bottom: 10px;
`
