import React, { forwardRef } from 'react'
import styled from 'styled-components'
import { SIZES } from '../../../../../constants'
import { SIZE } from '../../../../ui/Avatar/StyledAvatar'

export const UserAvatar = forwardRef(({ avatar, ecoIdentity, onClick, onError }, ref) => {
  const avatarURL = buildAvatarURL(avatar, ecoIdentity)

  return (
    <StyledImage
      src={avatarURL}
      alt="Avatar"
      onClick={onClick}
      innerRef={ref}
      onError={onError}
    />
  )
})

const buildAvatarURL = (avatar, ecoIdentity) => {
  if (!ecoIdentity?.avatar?.baseLocation) {
    return ''
  }

  return `${ecoIdentity.avatar.baseLocation}avatar_${avatar}.svg`
}

const StyledImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;

  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    width: ${SIZE}px;
    height: ${SIZE}px;
  }
`
