import { PersonIcon } from '../../../../icons/Person/PersonIcon'
import { StyledAvatarContainer } from '../../../../ui/Avatar/StyledAvatar'
import React, { forwardRef } from 'react'

export const DefaultAvatar = forwardRef(({ onClick }, ref) => {
  return (
    <StyledAvatarContainer onClick={onClick} innerRef={ref}>
      <PersonIcon />
    </StyledAvatarContainer>
  )
})
