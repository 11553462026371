import styled from 'styled-components'
import { SIZES } from '../../../constants'

export default styled.div`
  height: 56px;
  max-width: calc(100% - 160px);
  position: relative;

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    width: 150px;
  }
`
