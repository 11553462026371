import React, { PureComponent } from 'react'
import { VerticalRow } from '../../utils/Grid'
import LeafletBox from '../../boxes/LeafletBox'
import styled from 'styled-components'
import { DEVICES, SIZES } from '../../../constants'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'

const LeafletRow = styled(VerticalRow)`
  display: -ms-flexbox;
  -ms-flex-direction: row;
  -ms-flex-pack: justify;
  -ms-flex-wrap: wrap;

  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    flex-flow: wrap;
    align-content: stretch;
  }

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    overflow-y: scroll;
    height: auto;
    scroll-behavior: smooth;
    white-space: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
    flex-flow: nowrap;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export default class LeafletsContainer extends PureComponent {
  render () {
    const {
      boxes,
      imageSize,
      utmParams,
      handleAnchorClick,
      activeDynamicLayout: device
    } = this.props

    if (!boxes.length) return null

    const visibleBoxes = device === DEVICES.MOBILE ? boxes : boxes.slice(0, 2)

    return (
      <LeafletRow>
        {
          visibleBoxes.map((box, index) => {
            const {
              guid,
              images,
              link,
              title
            } = box

            const utmLink = utmLinkGenerator.createLinkWithOriginalQuery(link, utmParams)

            return <LeafletBox
              key={guid}
              image={images[imageSize]}
              imageClass={`image-${imageSize}`}
              link={utmLink}
              position={index}
              title={title}
              handleAnchorClick={handleAnchorClick}
              guid={guid}
              layout={device}
            />
          })
        }
      </LeafletRow>
    )
  }
}
