import styled from 'styled-components'
import { SIZES } from '../../../../constants'

export default styled.button`
  background: ${props => props.theme.button.background};
  border: 1px solid ${props => props.theme.button.border};
  border-radius: 0 6px 6px 0;
  cursor: pointer;
  height: 47px;
  outline: 0;
  width: 95px;

  & .desktopText {
    color: ${props => props.theme.button.color};
    display: block;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
  }

  & .mobileIcon {
    display: none;
  }

  &:hover, &:focus, &:active {
    background: ${props => props.theme.button.hover.background};
    border-color: ${props => props.theme.button.hover.border};
  }

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    border-radius: 0 2px 2px 0;
    font-size: 12px;
    height: 30px;
    width: 40px;
    
    & .desktopText {
      display: none;
    }

    & .mobileIcon {
      display: block;
      margin: auto;
    }
  }
`
