import React, { Component, forwardRef } from 'react'
import styled from 'styled-components'
import {
  AccountSettingsIcon
} from '../../../../icons/AccountSettings/AccountSettingsIcon'
import { UserMenuItem } from './UserMenuItem'
import { LogoutIcon } from '../../../../icons/Logout/LogoutIcon'
import { MailIcon } from '../../../../icons/Mail/MailIcon'
import { isHeaderFixed } from '../../../../../utils/isHeaderFixed'
import { sendWidgetClick } from '../../../../utils/ga4Events'
import { observeMainContentContainerMarginTopChange } from '../utils'
import { buildLogoutURL } from '../../../../utils/Logout'
import { getLinkTarget } from '../../../../utils/getLinkTarget'
import { buildName } from './utils'

const GAP = 8

class UserMenu extends Component {
  constructor (props) {
    super(props)

    this.cleanupMutationObserver = null
    this.state = {
      isFixed: false,
      mainContentContainerMarginTop: 0
    }

    this.handleScroll = this.handleScroll.bind(this)
    this.sendGaEvent = this.sendGaEvent.bind(this)
    this.navigateToEmail = this.navigateToEmail.bind(this)
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()

    this.cleanupMutationObserver = observeMainContentContainerMarginTopChange((newMarginTop) => {
      this.setState({ mainContentContainerMarginTop: newMarginTop })
    })
  }

  componentDidUpdate () {
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
    this.cleanupMutationObserver()
  }

  handleScroll () {
    const {
      props: {
        leaderboardHeight,
        rtbLeaderboardHeight
      },
      state: {
        mainContentContainerMarginTop,
        isFixed
      }
    } = this

    const fixed = isHeaderFixed({
      leaderboardHeight,
      rtbLeaderboardHeight,
      mainContentContainerMarginTop
    })

    if (isFixed !== fixed) {
      this.setState({ isFixed: fixed })
    }
  }

  navigateToEmail = () => {
    window.location.href = this.props.emailWidget.meta.inbox.location
  }

  sendGaEvent = () => {
    sendWidgetClick({
      name: 'email',
      click: {
        type: 'link',
        value: 'logout'
      }
    })
  }

  render () {
    const {
      unreadString,
      headerHeight,
      innerRef,
      ecoIdentity: {
        logout: {
          location: logoutLocation
        },
        settings: {
          location: settingsLocation
        }
      },
      emailWidget: {
        meta: {
          inbox: {
            location: inboxLocation
          }
        }
      },
      userMenuWidget: {
        meta: {
          accountSettings,
          goToEmail,
          logout
        }
      },
      userInfo: {
        firstName = '',
        lastName = '',
        email = '',
        hasMailbox = false
      },
      layout
    } = this.props
    const { isFixed } = this.state

    const name = buildName(firstName, lastName)

    const offset = headerHeight + GAP

    const logoutURL = buildLogoutURL({ logoutLocation })

    return (
      <StyledModal innerRef={innerRef} $offset={offset} $isFixed={isFixed}>
        <StyledMenuContainer>
          <StyledBasicInfo>
            <StyledName>{name}</StyledName>
            <StyledEmail>{email}</StyledEmail>
          </StyledBasicInfo>
          {name !== '' && email !== '' && <StyledDivider />}
          <UserMenuItem
            href={settingsLocation}
            icon={<AccountSettingsIcon />}
            label={accountSettings.textDesktop}
            target={getLinkTarget(layout)}
          />
          {hasMailbox && <UserMenuItem
            href={inboxLocation}
            icon={<MailIcon type='small' />}
            label={goToEmail.text}
            additional={
              <StyledUnreadMessagesCount>
                {unreadString}
              </StyledUnreadMessagesCount>
            }
          />}
          <StyledDivider />
          <UserMenuItem
            href={logoutURL}
            onClick={this.sendGaEvent}
            icon={<LogoutIcon />}
            label={logout.text}
          />
        </StyledMenuContainer>
      </StyledModal>
    )
  }
}

export default forwardRef((props, ref) => <UserMenu {...props} innerRef={ref} />)

const StyledUnreadMessagesCount = styled.span`
    margin-left: auto;
`

const StyledDivider = styled.div`
  width: 100%;
  border-bottom: ${({ theme }) => `1px solid ${theme.headerLogin.dividerColor}`};
  margin: 8px 0;
`

const StyledBasicInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  background: ${({ theme }) => theme.headerLogin.userMenu.backgroundColor};
  color: ${({ theme }) => theme.headerLogin.userMenu.basicInfo.color};
`

const StyledName = styled.div`
  font-weight: 600;
  line-height: 21px;
`

const StyledEmail = styled.div`
  font-size: 12px;
`

const StyledModal = styled.div`
  position: ${({ $isFixed }) => $isFixed ? 'fixed' : 'static'};
  top: ${({ $offset }) => $offset}px;
  margin-top: ${({ $isFixed }) => $isFixed ? '0px' : '8px'};
  z-index: 11;
`

const StyledMenuContainer = styled.div`
  box-shadow: ${({ theme }) => `0 6px 12px 0 ${theme.headerLogin.shadowColor}`};
  padding: 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.headerLogin.userMenu.backgroundColor};
  min-width: 240px;
`
