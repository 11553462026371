import styled from 'styled-components'
import Link from '../../../utils/Link'

export default styled(Link)`
  border-right: 1px solid #d7d7d7;
  color: ${props => props.theme.header.email.linkColor};
  font-size: 12px;
  margin-right: 7px;
  padding-right: 7px;
  text-decoration: underline;
  &:last-child {
    border-right: none;
    margin: 0;
    padding: 0;
  }
`
