import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { isHeaderFixed } from '../../../../../utils/isHeaderFixed'
import { observeMainContentContainerMarginTopChange } from '../utils'
import { login } from '../../../../../utils/login'

const GAP = 8
export const MORE_INFO_ECO_ID_URL = 'https://freemail.help.economia.cz/articles/123195-o-novem-prihlaseni'

export const LoginModal = forwardRef((props, ref) => {
  const {
    headerHeight,
    ecoIdentity: {
      clientId,
      login: {
        location: loginLocation
      }
    },
    leaderboardHeight,
    rtbLeaderboardHeight,
    modalType
  } = props

  const [isFixed, setIsFixed] = useState(false)
  const [mainContentContainerMarginTop, setMainContentContainerMarginTop] = useState(0)

  const offset = headerHeight + GAP

  const handleScroll = useCallback(() => {
    const fixed = isHeaderFixed({
      leaderboardHeight,
      rtbLeaderboardHeight,
      mainContentContainerMarginTop
    })

    if (isFixed !== fixed) {
      setIsFixed(fixed)
    }
  }, [leaderboardHeight, rtbLeaderboardHeight, mainContentContainerMarginTop, isFixed])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    handleScroll()

    const cleanupMutationObserver = observeMainContentContainerMarginTopChange(newMarginTop => {
      setMainContentContainerMarginTop(newMarginTop)
    })

    return () => {
      window.removeEventListener('scroll', handleScroll)
      cleanupMutationObserver()
    }
  }, [handleScroll])

  const title =
   'Nenechte si nic ujít.'

  const message =
   'Když se přihlásíte, upozorníme vás na nové e-maily i důležité zprávy z našich služeb. Pokud jste aktivní diskutér, najdete zde reakce na své příspěvky.'

  const loginLabel =
    'Přihlásit se'

  return (
    <StyledPositioningContainer
      $isFixed={isFixed}
      $offset={offset}
      $modalType={modalType}
    >
      <StyledContainer innerRef={ref}>
        <StyledLoginModalTitle>{title}</StyledLoginModalTitle>
        <StyledLoginModalMessage>{message}</StyledLoginModalMessage>
        <StyledLoginModalButton
          onClick={() => login({ loginLocation, clientId })}
        >
          {loginLabel}
        </StyledLoginModalButton>
      </StyledContainer>
    </StyledPositioningContainer>
  )
})

export const StyledLoginModalButton = styled.button`
  padding: 8px 0;
  background: ${({ theme }) => theme.headerLogin.loginModal.loginButton.backgroundColor};
  border: 1px solid ${({ theme }) => theme.headerLogin.loginModal.loginButton.borderColor};
  font-size: 11px;
  font-weight: 900;
  line-height: 17px;
  color: ${({ theme }) => theme.headerLogin.loginModal.loginButton.color};
  text-transform: uppercase;
  border-radius: 4px;
  
  &:hover {
    background: ${({ theme }) => theme.headerLogin.loginModal.loginButton.hover.backgroundColor};
    border: ${({ theme }) => `1px solid ${theme.headerLogin.loginModal.loginButton.hover.borderColor}`};
  }
`

export const StyledLoginModalMessage = styled.p`
  color: ${({ theme }) => theme.headerLogin.loginModal.message.color};
  margin: 0;
`

export const StyledLoginModalTitle = styled.div`
  color: ${({ theme }) => theme.headerLogin.loginModal.title.color};
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
`

const StyledPositioningContainer = styled.div`
  position: ${({ $isFixed }) => $isFixed ? 'fixed' : 'static'};
  top: ${({ $offset }) => $offset}px;
  margin-top: ${({ $isFixed }) => $isFixed ? '0px' : '8px'};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 11;
  margin-right: ${({ $modalType }) => !$modalType || $modalType === 'mailIcon' ? '106px' : '0px'};
  border-radius: 4px;
`

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px; 
  max-width: 293px;
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 6px 12px 0 #00000040;
`
