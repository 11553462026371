import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Anchor = styled.a`
  &:hover {
    color: ${props => props.theme.link.colorHover}
  }
`

/**
 * Link Component
 */
const Link = props => (
  <Anchor {...props}>
    { props.children }
  </Anchor>
)

Link.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  title: PropTypes.string
}

export default Link
