/* @flow */
import React from 'react'
import styled from 'styled-components'
import { SIZES } from '../../../constants/index'

const LogoWrapper = styled.div`
  margin-right: 15px;
  padding: 0;
  width: 100px;

  @media (min-width: ${SIZES.BREAKPOINT_XS}px) {
    flex: 1 1 227px;
    display: -ms-flexbox;
    -ms-flexbox: 1 1 227px;
    max-width: 227px;
    position: relative;
  }

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    min-width: 110px;
  }
`

const LogoImg = styled.img`
  height: auto;
  width: 100px;
  
  @media (min-width: ${SIZES.BREAKPOINT_XS}px) {
    bottom: ${props => props.theme.header.logo.bottom}px;
    position: absolute;
    width: 179px;
  }

  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    height: auto;
    width: 179px;
  }

  @media (max-width: ${SIZES.BREAKPOINT_XS}px) {
    width: 100px;
  }
`

declare var STATICS_PATH: string

type Props = {|
  widget: {
    meta: {
      link: {
        location?: string,
        text?: string,
        src?: string
      }
    }
  }
|}

export default (props: Props) => {
  const {
    widget: {
      meta: {
        link: {
          location = '.',
          text = '',
          src = ''
        }
      }
    }
  } = props

  return (
    <LogoWrapper>
      <a href={location}>
        <LogoImg src={`${STATICS_PATH}${src}`} alt={text} title={text}/>
      </a>
    </LogoWrapper>
  )
}
