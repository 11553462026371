import React, { Component } from 'react'
import Link from '../utils/Link'
import { type BaseBoxProps } from './Types'
import { getLinkTarget } from '../utils/getLinkTarget'

export default class Box extends Component<BaseBoxProps> {
  render () {
    const {
      box,
      className,
      image,
      link,
      title,
      handleAnchorClick,
      position = 0,
      layout
    } = this.props

    return (
      <Link
        href={link}
        className={className}
        onClick={(event) => {
          Boolean(handleAnchorClick) && handleAnchorClick(event, link, box, position, layout)
        }}
        target={getLinkTarget(layout)}
      >
        <div className='image-column'>
          { image }
        </div>
        <div className='title-column'>
          { title }
        </div>
      </Link>
    )
  }
}
