import React, { Component } from 'react'
import styled from 'styled-components'
import WidgetContainer from '../containers/WidgetContainer'
import { DEVICES } from '../../constants'
import { NewLabelMobile } from '../utils/NewLabelMobile'
import { NewLabelDesktop } from '../utils/NewLabelDesktop'

const StyledBbxSportOnlineWidget = styled.div`
  margin-bottom: 16px;
`
class BbxOnlineSportWidget extends Component {
  shouldComponentUpdate () {
    return false
  }
  componentDidMount () {
    const s = document.createElement('script')
    s.src = 'https://sport.aktualne.cz/widget_code/5bee2a6c81fd11eeba63ac1f6b220ee8.js'
    s.setAttribute('data-widget-config', JSON.stringify({ insertInto: '#sport-online-container' }))
    document.body.appendChild(s)
  }

  render () {
    const {
      props: {
        activeDynamicLayout,
        widget,
        googleAnalytics,
        widget: {
          isNew
        }
      }
    } = this

    const newLabel = activeDynamicLayout === DEVICES.MOBILE ? <NewLabelMobile/> : <NewLabelDesktop/>
    const headlineContent = isNew ? newLabel : null

    return (
      <WidgetContainer
        googleAnalytics={googleAnalytics}
        widget={widget}
        activeDynamicLayout={activeDynamicLayout}
        headlineContent={headlineContent}>
        <StyledBbxSportOnlineWidget id='sport-online-container' />
      </WidgetContainer>
    )
  }
}

export default BbxOnlineSportWidget
