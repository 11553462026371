import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { SIGNS } from '../../../constants'
import { HoroscopesConsumer } from '../../../context/Horoscopes'

const StyledHoroscopeIcon = styled.span`
  background: url(${UNVERSIONED_STATICS_PATH}images/horoscopes/${props => `${props.theme.name}/${SIGNS[props.sign].guid}`}.png) center no-repeat;
  background-size: contain;
  display: block;
  height: 32px;
  margin: 4px auto;
`

export default class HoroscopeIcon extends PureComponent {
  render () {
    return (
      <HoroscopesConsumer>
        {
          ({ sign }) => {
            return <StyledHoroscopeIcon sign={sign}/>
          }
        }
      </HoroscopesConsumer>
    )
  }
}
