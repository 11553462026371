import styled from 'styled-components'
import { SIZES } from '../../../../../constants'

export default styled.ul`
  padding: 0 0 0 7px;
  margin-top: 7px;
  margin-bottom: 0;
  position: relative;
  z-index: 2;
  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    display: none;
  }
`
