import React from 'react'
import { StyledClickableIcon } from '../StyledClickableIcon'

export const LogoutIcon = () => {
  return (
    <StyledClickableIcon
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M8.95078 10.2335C8.77265 10.0666 8.68358 9.85537 8.68358 9.59991C8.68358 9.34444 8.77265 9.13324 8.95078 8.96631L10.0004 7.91671H5.76678C5.52251 7.91671 5.30865 7.82497 5.12518 7.64151C4.94171 7.45804 4.84998 7.24417 4.84998 6.99991C4.84998 6.75564 4.94171 6.54177 5.12518 6.35831C5.30865 6.17484 5.52251 6.08311 5.76678 6.08311H9.95078L8.91718 5.04951C8.76145 4.87191 8.68651 4.66097 8.69238 4.41671C8.69771 4.17191 8.78385 3.96631 8.95078 3.79991C9.12838 3.63351 9.34492 3.55031 9.60038 3.55031C9.85585 3.55031 10.0724 3.63351 10.25 3.79991L12.8004 6.36631C12.9006 6.45537 12.9729 6.55831 13.0172 6.67511C13.0614 6.79137 13.0836 6.90524 13.0836 7.01671C13.0836 7.12764 13.0614 7.24151 13.0172 7.35831C12.9729 7.47511 12.9006 7.57777 12.8004 7.66631L10.2172 10.2495C10.0502 10.4164 9.84465 10.5026 9.60038 10.5079C9.35612 10.5138 9.13958 10.4223 8.95078 10.2335ZM2.38358 13.3167C1.87265 13.3167 1.43931 13.1388 1.08358 12.7831C0.728381 12.4274 0.550781 11.994 0.550781 11.4831V2.51671C0.550781 2.00577 0.728381 1.57244 1.08358 1.21671C1.43931 0.860972 1.87265 0.683105 2.38358 0.683105H6.15078C6.39505 0.683105 6.60891 0.774839 6.79238 0.958305C6.97531 1.14177 7.06678 1.35564 7.06678 1.59991C7.06678 1.84417 6.97531 2.05804 6.79238 2.24151C6.60891 2.42497 6.39505 2.51671 6.15078 2.51671H2.38358V11.4831H6.15078C6.39505 11.4831 6.60891 11.5748 6.79238 11.7583C6.97531 11.9418 7.06678 12.1556 7.06678 12.3999C7.06678 12.6442 6.97531 12.858 6.79238 13.0415C6.60891 13.225 6.39505 13.3167 6.15078 13.3167H2.38358Z" />
    </StyledClickableIcon>
  )
}
