import React, { Fragment } from 'react'
import styled from 'styled-components'
import DesktopNativeAd from '../boxes/DesktopNativeAd'
import type { BoxType as Box } from '../Types'
import { RegularCol, RegularRow } from '../utils/Grid'
import utmLinkGenerator from '../utils/UTMLinkGenerator'
import ArticleListBox from '../boxes/StyledArticleListBox'
import Prannotation from '../utils/Prannotation'

const ArticleList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
`

type LinksSectionProps = {|
  ad: Object,
  boxes: Array<Box>,
  indexShift: number,
  device: string,
  name: string,
  utmParams: {|
    utmContent: string,
    utmMedium: string,
    utmSource: string
  |},
  handleAnchorClick: () => any
|}

const renderBoxes = (boxes, utmParams, handleAnchorClick, indexShift, layout) => {
  return boxes.map((box, index) => {
    const {
      featuredType,
      guid,
      link,
      source,
      title,
      duration
    } = box

    const utmLinkParams = Object.assign({}, utmParams, {
      utmTerm: `position-${index + indexShift}`,
      utmCampaign: source
    })

    const utmLink = utmLinkGenerator.createLink(link, utmLinkParams)

    return (
      <ArticleListBox
        featuredSize='inline'
        featuredType={featuredType}
        key={guid}
        link={utmLink}
        title={title}
        duration={duration}
        showLabel={true}
        handleAnchorClick={handleAnchorClick}
        box={box}
        position={index + indexShift + 1}
        layout={layout}
      />
    )
  })
}

export default (props: LinksSectionProps) => {
  const {
    ad = {},
    boxes,
    indexShift = 0,
    device,
    name,
    utmParams,
    handleAnchorClick
  } = props

  const listBoxes = renderBoxes(boxes, utmParams, handleAnchorClick, indexShift, device)

  const renderedBoxes = ad[device]
    ? <Prannotation AdComponent={DesktopNativeAd} device={device} id={`ad-${name}`} sashec={ad}>
      { listBoxes }
    </Prannotation>
    : <Fragment>{ listBoxes }</Fragment>

  return (
    <RegularRow>
      <RegularCol>
        <ArticleList>
          { renderedBoxes }
        </ArticleList>
      </RegularCol>
    </RegularRow>
  )
}
