// @flow
import React, { PureComponent, type Node } from 'react'
import { STATIC_PRANNOTATION_CONFIG } from '../../constants'
import { PrannotationConsumer } from '../../context/Prannotation'
import SashecAdvert from '../widgets/Advert/SashecAdvert'

type SashecType = {|
  id: String,
  type: String,
  pos: String,
  async: boolean,
  supertag: Array<String>,
  group?: String
|}

type PrannotationType = {|
  id: String,
  commentTop: String,
  commentBottom: String,
  image: {|
    default: {|
      height: Number,
      width: Number,
      url: String
    |}
  |},
  label: String,
  perex: String,
  pos: String,
  title: String,
  url: String
|}

type ContainerProp = {|
  sashec: SashecType,
  prannotation: PrannotationType,
  AdComponent: any
|}

class Container extends PureComponent<ContainerProp> {
  render () {
    const {
      props: {
        sashec,
        prannotation,
        AdComponent
      }
    } = this

    return (
      <div>
        <SashecAdvert {...sashec}/>
        {prannotation && <AdComponent {...prannotation}/>}
      </div>
    )
  }
}

type PrannotationProps = {|
  AdComponent: any,
  children?: Node,
  device?: string,
  id: string,
  sashec: SashecType
|}

const Prannotation = (props: PrannotationProps) => {
  const {
    AdComponent,
    children,
    device,
    id,
    sashec
  } = props

  const deviceConfig = (device && sashec[device] ? sashec[device] : sashec) || {}

  const adConfig = Object.assign({}, STATIC_PRANNOTATION_CONFIG, deviceConfig, { id })

  return (
    <div>
      {children}
      <PrannotationConsumer>
        {context => (
          <Container
            sashec={adConfig}
            prannotation={context.find(object => object.pos === deviceConfig.pos)}
            AdComponent={AdComponent}
          />
        )}
      </PrannotationConsumer>
    </div>
  )
}

export default Prannotation
