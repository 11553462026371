import styled from 'styled-components'
import { SIZES } from '../../../constants'

export default styled.img`
  height: 22px;
  width: auto;
  margin: 8px auto 15px;
  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    display: block;
  }
`
