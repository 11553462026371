import React, { PureComponent } from 'react'
import styled from 'styled-components'
import Link from '../../utils/Link'
import utmLink from '../../utils/UTMLinkGenerator'
import { WEEKDAYS, NAMEDAYS, HOLIDAYS } from '../../../constants'
import { sendWidgetClick } from '../../utils/ga4Events'
import { getLinkTarget } from '../../utils/getLinkTarget'

const CalendarLink = styled(Link)`
  color: #333;
  line-height: 24px;
`

const LINK = 'http://svatky.centrum.cz'

export default class TinyCalendar extends PureComponent {
  /**
   * get formatted date as cs locale string
   *
   * @param {Object} date
   * @return {string}
   */
  getFormattedDate (date) {
    const weekday = WEEKDAYS[date.getDay()]
    const day = date.getDate()
    const month = date.getMonth() + 1

    return `${weekday} ${day}. ${month}.`
  }

  /**
   * get key for calendar item constant from date object
   *
   * @param {Object} date
   * @return {string}
   */
  getKeyFromDate (date) {
    const rawDay = date.getDate()
    const day = `${rawDay < 10 ? '0' : ''}${rawDay}`
    const rawMonth = date.getMonth() + 1
    const month = `${rawMonth < 10 ? '0' : ''}${rawMonth}`

    return `${day}-${month}`
  }

  /**
   * get whole calendar item string for current date
   *
   * @return {string}
   */
  getCalendarItemString () {
    const today = new Date()
    let calendarItemStringPart = []

    const dayKey = this.getKeyFromDate(today)
    const holiday = HOLIDAYS[dayKey]
    const nameday = NAMEDAYS[dayKey]

    if (holiday) {
      calendarItemStringPart.push(`dnes ${holiday}`)
    }
    if (nameday) {
      calendarItemStringPart.push(`svátek má ${nameday}`)
    }

    return `${this.getFormattedDate(today)} ${calendarItemStringPart.join(', ')}`
  }

  render () {
    const {
      layout
    } = this.props

    const link = utmLink.createLink(LINK)
    const handleClick = () => sendWidgetClick({
      name: 'calendar',
      click: {
        type: 'link',
        value: link
      }
    })
    return (
      <CalendarLink click={handleClick} href={link} target={getLinkTarget(layout)} rel='noopener'>
        { this.getCalendarItemString() }
      </CalendarLink>
    )
  }
}
