import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { SIGNS, SIGNS_LIST, SIZES } from '../../../constants'
import SignIcon from './StyledSignIcon'
import TriggerButton from './StyledTriggerButton'
import { SelectList, SelectListItem } from './StyledSelectList'
import { type SignSelectProps, type SignSelectState } from './Types'

const Container = styled.div`
  max-width: 180px;
  position: absolute;
  right: 0;
  user-select: none;

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    right: 8px;
  }
`

export default class SignSelect extends PureComponent<SignSelectProps, SignSelectState> {
  constructor (props) {
    super(props)

    this.state = {
      isOpen: false
    }

    this.handleClickTrigger = this.handleClickTrigger.bind(this)
    this.handleClickOutsideSelectList = this.handleClickOutsideSelectList.bind(this)
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutsideSelectList)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutsideSelectList)
  }

  handleClickOutsideSelectList (event) {
    if (this.state.isOpen && !this.containerRef.contains(event.target)) {
      this.setState({
        isOpen: false
      })
    }
  }

  handleClickTrigger () {
    this.setState(state => {
      return {
        isOpen: !state.isOpen
      }
    })
  }

  handleListItemClick (sign) {
    const {
      handleSignChange
    } = this.props

    this.setState({
      isOpen: false
    })

    handleSignChange(sign)
  }

  render () {
    const {
      isOpen
    } = this.state

    return (
      <Container innerRef={ref => { this.containerRef = ref }}>
        <TriggerButton onClick={this.handleClickTrigger} className={isOpen && 'open'}>
          Vyber své znamení
          <span className='arrow'/>
        </TriggerButton>
        {
          isOpen &&
          <SelectList>
            {
              SIGNS_LIST.map(signIndex => {
                const {
                  date,
                  guid,
                  name
                } = SIGNS[signIndex]

                return (
                  <SelectListItem onClick={this.handleListItemClick.bind(this, signIndex)} key={guid}>
                    <div className='name'>
                      { name }
                    </div>
                    <div className='date'>
                      ({ date })
                    </div>
                    <SignIcon sign={guid}/>
                  </SelectListItem>
                )
              })
            }
          </SelectList>
        }
      </Container>
    )
  }
}
