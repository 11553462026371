// @flow
import React from 'react'
import PersistedComponent from '../../PersistedComponent'
import Housing from '../'
import type { HousingWidgetProps } from '../../Types'

export default class MobileHousing extends PersistedComponent<HousingWidgetProps> {
  constructor (props: HousingWidgetProps) {
    super(props)

    this.persistedKeys.push('isOpen')

    this.state = Object.assign({ isOpen: true }, this.state)
  }

  handleToggleContent = () => {
    const {
      isOpen
    } = this.state

    this.setState({
      isOpen: !isOpen
    })
  }

  render () {
    const {
      props: {
        activeDynamicLayout,
        isOpening,
        widget,
        widget: {
          boxes
        },
        itemVariant
      },
      state: {
        isOpen
      }
    } = this
    const mobileHousingWidget = Object.assign({}, widget, {
      name: 'mobileHousing',
      boxes: boxes.slice(0, 2)
    })

    return (
      <Housing
        widget={mobileHousingWidget}
        activeDynamicLayout={activeDynamicLayout}
        isOpening={isOpening}
        isOpen={isOpen}
        handleToggleContent={this.handleToggleContent}
        itemVariant={itemVariant}
      />
    )
  }
}
