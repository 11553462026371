import React from 'react'
import styled from 'styled-components'
import Bydleni from '../widgets/Bydleni'
import Housing from '../widgets/Housing'

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const BydleniRealityContainer = ({ bydleniWidget, housingWidget, ...otherProps }) => (
  <Container>
    <Bydleni {...otherProps} widget={bydleniWidget}/>
    <Housing {...otherProps} widget={housingWidget}/>
  </Container>
)

export default BydleniRealityContainer
