import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { RegularCol, RegularRow } from '../../utils/Grid'
import styled from 'styled-components'
import AdvertSignWrapper from './AdvertSignWrapper'
import SashecAdvert from './SashecAdvert'
import { SIZES } from '../../../constants'

const SashecAdvertPosition = styled.div`
  display: block;
  position: relative;
  margin: 0 0 20px 0;
  height: auto;
  
  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
`

class SashecAdvertPositionWrapper extends Component {
  shouldComponentUpdate () {
    return false
  }

  render () {
    const {
      widget: {
        meta: {
          pos,
          targetId,
          supertag
        },
        group
      }
    } = this.props

    return (
      <RegularRow>
        <RegularCol>
          <SashecAdvertPosition>
            <div id={`${targetId}-wrapper`}>
              <AdvertSignWrapper/>
              <SashecAdvert id={targetId} supertag={supertag} pos={pos} group={group}/>
            </div>
          </SashecAdvertPosition>
        </RegularCol>
      </RegularRow>
    )
  }
}

SashecAdvertPositionWrapper.propTypes = {
  widget: PropTypes.shape({
    group: PropTypes.string,
    meta: PropTypes.shape({
      wrapper: PropTypes.shape({
        id: PropTypes.string,
        className: PropTypes.string
      }),
      targetId: PropTypes.string.isRequired,
      pos: PropTypes.number.isRequired,
      sticky: PropTypes.bool
    })
  })
}

export default SashecAdvertPositionWrapper
