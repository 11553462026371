import React from 'react'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import { composeUnversionedStaticsPath } from '../../utils/URL'

export default (props) => {
  const {
    box: {
      featuredType,
      guid,
      images,
      link,
      source,
      title
    },
    imageBoxComponent: ImageBoxComponent,
    imageSize,
    index,
    utmParams,
    handleAnchorClick,
    layout
  } = props

  const image = composeUnversionedStaticsPath(images[imageSize], UNVERSIONED_STATICS_PATH)

  const utmLinkParams = Object.assign({}, utmParams, {
    utmTerm: `position-${index}`,
    utmCampaign: source
  })
  const enhancedLink = utmLinkGenerator.createLink(link, utmLinkParams)

  return (
    <ImageBoxComponent
      featuredType={featuredType}
      image={image}
      imageClass={`image-${imageSize}`}
      isImage={true}
      key={guid}
      link={enhancedLink}
      title={title}
      handleAnchorClick={handleAnchorClick}
      layout={layout}
    />
  )
}
