import React from 'react'
import styled from 'styled-components'
import { type BaseImageProps } from '../Types'

const Container = styled.div`
  flex: 1 1 auto;
  display: -ms-flexbox;
  -ms-flexbox: 1 1 auto;
  background-image: url('${props => props.src}');
  background-size: cover;
  background-position: center center;
`

const ImagePlaceholder = styled.img`
  opacity: 0;
`

export default (props: BaseImageProps) => (
  <Container src={props.src}>
    <ImagePlaceholder {...props}/>
  </Container>
)
