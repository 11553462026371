// @flow
import * as React from 'react'
import styled from 'styled-components'
import parse from 'url-parse'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import Link from '../../utils/Link'
import { createLinkSignature } from '../../utils/linkSignature'
import { sendWidgetClick } from '../../utils/ga4Events'

const GA_CATEGORY = 'Search tab'

const StyledContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1 2 425px;
  display: -ms-flexbox;
  -ms-flex-align: end;
  -ms-flexbox: 1 2 425px;
  -ms-flex-wrap: wrap;
  font-size: 13px;
  margin-left: 0;
`

const StyledWrapper = styled.div`
  overflow: auto;
  width: 100%;
`

const StyledLink = styled(Link)`
  color: ${props => props.theme.moreLink.color};
  display: inline-block;
  max-width: 33%;
  overflow: hidden;
  padding-right: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: ${props => props.theme.moreLink.colorHover}
  }
`

type Box = {|
  guid: string,
  link: string,
  title: string,
  source: string,
  featuredType: string,
  forcedPosition: number,
  images: Object,
|}

type Props = {|
  isFixed: boolean,
  widget: {
    boxes: Array<Box>,
    maxItems: number,
    utmMedium: string,
    utmSource: string,
    utmCustom: string,
    name: string
  },
  googleAnalytics: any,
  secret: string,
  variant: boolean
|}

const handleOnclick = (e, secret, gaData, widgetName) => {
  const url = parse(e.target.href, true)
  sendWidgetClick({
    name: widgetName,
    click: {
      type: GA_CATEGORY,
      value: gaData.label
    }
  })
  e.target.href = utmLinkGenerator.createLinkWithOriginalQuery(e.target.href, {}, {
    csrf: createLinkSignature(url.query.q, secret)
  })
}

export default (props: Props) => {
  const {
    isFixed,
    widget: {
      name,
      boxes = [],
      maxItems,
      utmMedium
    },
    secret,
    googleAnalytics,
    variant
  } = props

  if (isFixed || !boxes.length) {
    return null
  }

  return (
    <StyledContainer $variant={variant}>
      <StyledWrapper>
        <strong>Právě hledáte:</strong>
        <div>
          {
            boxes.slice(0, maxItems).map((box, index) => {
              const link = utmLinkGenerator.createLink(box.link, {
                utmMedium,
                utmTerm: `position-${index + 1}`
              }, {
                csrf: createLinkSignature(box.title, secret)
              })

              return (
                <StyledLink href={link} key={index} onClick={e => handleOnclick(e, secret, {
                  googleAnalytics,
                  label: `${index + 1} - ${box.title}`
                }, name)}>
                  {box.title}
                </StyledLink>
              )
            })
          }
        </div>
      </StyledWrapper>
    </StyledContainer>
  )
}
