import React from 'react'
import styled from 'styled-components'
import PersistedComponent from '../../PersistedComponent'
import utmLinkGenerator from '../../../utils/UTMLinkGenerator'
import Wrapper from '../EmailFull/StyledWrapper'
import { AddressInboxLink, Header } from '../EmailFull/StyledHeader'
import {
  MessageDate,
  MessageLink,
  Sender,
  SenderContainer,
  Subject
} from '../EmailFull/StyledMessage'
import { MailIcon } from '../../../icons/Mail/MailIcon'
import { StyledClickableIcon } from '../../../icons/StyledClickableIcon'
import { DEVICES } from '../../../../constants'

const StyledWriteEmail = styled.a`
  font-size: 12px;
  color: ${props => props.theme.email.full.link};
  font-weight: 600;
  margin-right: 15px;
  
  &:hover {
    color: ${props => props.theme.email.full.link};
  }
`

const StyledHeader = styled(Header)`
  display: flex;
  justify-content: space-between;
`

const MAX_SHOW_MESSAGES = 4
const MAX_SUBJECT_LENGTH = 120
const EMPTY_SUBJECT = '<bez předmětu>'

export class LoggedInEmail extends PersistedComponent {
  constructor (props) {
    super(props)

    this.persistedKeys = ['showInboxPreview']

    this.state = {
      showInboxPreview: true
    }

    this.handleToggleInbox = this.handleToggleInbox.bind(this)
  }

  handleToggleInbox () {
    this.setState(state => {
      return {
        showInboxPreview: !state.showInboxPreview
      }
    })
  }

  timestampToDate (timestamp) {
    const date = new Date(timestamp * 1000)
    return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`
  }

  truncate (text, maxLength = 80, etc = '...') {
    if (text.length > maxLength) {
      text = text.substr(0, maxLength).trim() + etc
    }
    return text
  }

  render () {
    const {
      inbox: {
        messages: {
          messages = []
        } = {}
      },
      userInfo: {
        hasMailbox
      },
      widget: {
        meta: {
          inbox,
          new: {
            location
          }
        }
      },
      embedded,
      ecoIdentity: {
        registration: {
          location: registrationDomain
        }
      },
      layout
    } = this.props
    const {
      showInboxPreview
    } = this.state
    const inboxLink = utmLinkGenerator.createLink(inbox.location, { utmTerm: 'position-0' })

    if (!hasMailbox) {
      if (layout !== DEVICES.MOBILE) {
        return null
      }

      return (
        <StyledRegisterContainer>
          <MailIcon type='small' />
          <StyledRegisterText href={registrationDomain}>
            Založ si u nás účet
          </StyledRegisterText>
        </StyledRegisterContainer>
      )
    }

    return (
      <Wrapper embedded={embedded}>
        <StyledHeader>
          <AddressInboxLink href={inboxLink}>
            Doručená pošta
          </AddressInboxLink>
          <StyledWriteEmail href={location}>Napsat e-mail</StyledWriteEmail>
        </StyledHeader>
        {
          showInboxPreview &&
          messages.slice(0, MAX_SHOW_MESSAGES).map((message, index) => {
            const subject = (message.subject) ? message.subject : EMPTY_SUBJECT

            return (
              <MessageLink key={index} href={message.link}>
                <SenderContainer>
                  <Sender attachment={message.attachment} read={message.read}>
                    {message.sender}
                  </Sender>
                  <Subject read={message.read}>
                    {this.truncate(subject, MAX_SUBJECT_LENGTH)}
                  </Subject>
                  <MessageDate>
                    {this.timestampToDate(message.date)}
                  </MessageDate>
                </SenderContainer>
              </MessageLink>
            )
          })
        }
      </Wrapper>
    )
  }
}

const StyledRegisterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding-left: 8px;
  
  ${StyledClickableIcon} {
    path {
      fill: ${props => props.theme.mobileUserMenu.iconColor};
    }
  }
`

const StyledRegisterText = styled.a`
  font-weight: 600;
  font-size: 14px;
  position: relative;
  color: ${props => props.theme.mobileUserMenu.color};
  cursor: pointer;
`
