import styled from 'styled-components'

export default styled.img`
  display: block;
  object-fit: cover;
  overflow: hidden;
  &.image-4_3 {
    height: 96px;
    width: 128px;
  }
  &.image-16_9 {
    height: 124px;
    width: 220px;
  }
  &.image-5_7 {
    height: 179px;
    width: 128px;
  }
`
