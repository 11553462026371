import React from 'react'
import styled from 'styled-components'
import ImageWrapper from './styled/ImageWrapper'
import Image from './SimpleImage'
import { SIZES } from '../../../constants'

const VerticalImageWrapper = styled(ImageWrapper)`
  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    margin-right: 15px;
  }
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    margin-bottom: 10px;
  }
  @media (min-width: ${SIZES.BREAKPOINT_XS}px) {
    width: auto;
  }
  @media (max-width: ${SIZES.BREAKPOINT_XS}px) {
    margin-right: 0;
  }
`

export default (props) => {
  const {
    image,
    imageClass,
    title
  } = props

  return (
    <VerticalImageWrapper>
      <Image src={image} alt={title} className={imageClass}/>
    </VerticalImageWrapper>
  )
}
