import React, { Fragment } from 'react'
import styled from 'styled-components'
import { buildLogoutURL } from '../components/utils/Logout'
import { LogoutIcon } from '../components/icons/Logout/LogoutIcon'
import {
  AccountSettingsIcon
} from '../components/icons/AccountSettings/AccountSettingsIcon'
import { StyledClickableIcon } from '../components/icons/StyledClickableIcon'
import { EmailConsumer } from '../context/Email'
import { isResponseFull } from '../components/widgets/Email/EmailUtils'
import {
  MobileUserMenuLoggedOff,
  StyledMobileUserMenuLoggedOffContainer
} from './MobileUserMenuLoggedOff'
import {
  StyledLoginModalButton
} from '../components/widgets/Header/HeaderLogin/LoginModal/LoginModal'
import { login } from '../utils/login'

export const MobileUserMenu = ({
  widget,
  ecoIdentity,
  variant,
  activeDynamicLayout
}) => {
  const {
    clientId,
    login: { location: loginLocation },
    logout: { location: logoutLocation },
    settings: { location: settingsLocation }
  } = ecoIdentity
  const {
    meta: {
      accountSettings,
      logout,
      login: loginMeta
    }
  } = widget

  const logoutURL = buildLogoutURL({ logoutLocation })

  const handleLogin = () => {
    login({ loginLocation, clientId })
  }

  return (
    <EmailConsumer>
      {({ inbox }) => {
        const isLoggedIn = isResponseFull(inbox)

        return (
          <StyledContainer>
            {isLoggedIn ? (
              <Fragment>
                <StyledMobileUserMenuLink href={logoutURL}>
                  <LogoutIcon />
                  {logout.text}
                </StyledMobileUserMenuLink>
                <StyledMobileUserMenuLink
                  href={settingsLocation}
                  target='_blank'
                >
                  <AccountSettingsIcon />
                  {accountSettings.textMobile}
                </StyledMobileUserMenuLink>
              </Fragment>
            ) : (
              <MobileUserMenuLoggedOff
                loginText={loginMeta.text}
                onLogin={handleLogin}
              />
            )}
          </StyledContainer>
        )
      }}
    </EmailConsumer>
  )
}

const StyledContainer = styled.div`
  display: flex;
  gap: 24px;
  
  ${StyledClickableIcon} {
    path {
      fill: ${({ theme }) => theme.mobileUserMenu.iconColor};
    }
  }
  
  ${StyledLoginModalButton} {
    width: 100%;
  }
  
  ${StyledMobileUserMenuLoggedOffContainer} {
    width: 100%;
  }
`

export const StyledMobileUserMenuLink = styled.a`
  display: flex;
  gap: 8px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${({ theme }) => theme.mobileUserMenu.color};

  &:hover {
    color: ${({ theme }) => theme.mobileUserMenu.hoverColor};
  }
`
