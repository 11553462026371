// @flow
import React from 'react'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import { composeUnversionedStaticsPath } from '../../utils/URL'
import { type ArticlesImageBoxWrapperProps } from './Types'

declare var UNVERSIONED_STATICS_PATH: string

export default (props: ArticlesImageBoxWrapperProps) => {
  const {
    box,
    box: {
      duration,
      featuredType,
      guid,
      images,
      link,
      source,
      title
    },
    imageBoxComponent: ImageBoxComponent,
    index,
    imageSize,
    utmParams,
    handleAnchorClick,
    layout
  } = props

  const image = composeUnversionedStaticsPath(images[imageSize], UNVERSIONED_STATICS_PATH)

  const utmLinkParams = Object.assign({}, utmParams, {
    utmTerm: `position-${index}`,
    utmCampaign: source
  })

  const utmLink = utmLinkGenerator.createLink(link, utmLinkParams)

  return (
    <ImageBoxComponent
      duration={duration}
      featuredType={featuredType}
      image={image}
      imageClass={`image-${imageSize}`}
      isImage={true}
      key={guid}
      link={utmLink}
      title={title}
      handleAnchorClick={handleAnchorClick}
      box={box}
      index={index}
      layout={layout}
    />
  )
}
