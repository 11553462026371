import styled from 'styled-components'
import ArticleBox from '../../boxes/ArticleBox'

export default styled(ArticleBox)`
  display: flex;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  flex: 1 1 0%;
  line-height: 20px;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #d6d6d6;
  font-size: 15px;
  margin-bottom: 7px;
  padding-bottom: 7px;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  & .image-column {
    position: relative;
  }
  & .title-column {
    color: ${props => props.theme.dynamicLead.smallBox.color};
    margin-left: 10px;
    display: -ms-flexbox;
    -ms-flex-negative: 1;
    flex: 1 1 0%;
    -ms-flexbox: 1 1 0%;
  }
  & .title-column span {
    display: block;
    width: 100%;
    max-height: 62px;
    overflow: hidden;
  }
  &:hover .title-column {
    color: inherit;
  }
  & img.image-4_3 {
    width: 82px;
    height: 62px;
  }
  @media screen and (max-width: 800px) and (-ms-high-contrast: active), screen and (max-width: 800px) and (-ms-high-contrast: none) {
    border-bottom: 0px;
  }
`
