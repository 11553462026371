import styled from 'styled-components'

export default styled.select`
  border: 1px solid ${props => props.theme.header.search.form.input.borderColor};
  color: #333;
  font-size: 12px;
  height: 45px;
  margin-top: 1px;
  outline: 0;
  position: absolute;
  right: 94px;
  &:focus {
    border-color: ${props => props.theme.header.search.form.input.borderColorFocus};
  }
`
