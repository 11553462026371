import React from 'react'
import { StyledClickableIcon } from '../StyledClickableIcon'

export const LoginIcon = () => {
  return (
    <StyledClickableIcon
      width="14"
      height="14"
      viewBox="0 0 810 809.999993"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="e2fa16c78e">
          <path
            d="M 36.519531 341 L 502 341 L 502 419 L 36.519531 419 Z M 36.519531 341 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="4bb3051def">
          <path
            d="M 230 30.898438 L 773.769531 30.898438 L 773.769531 729 L 230 729 Z M 230 30.898438 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#e2fa16c78e)">
        <path
          fill="#4a5b91"
          d="M 36.558594 341.164062 L 501.957031 341.164062 L 501.957031 418.730469 L 36.558594 418.730469 Z M 36.558594 341.164062 "
          fillOpacity="1"
          fillRule="evenodd"
        />
      </g>
      <path
        fill="#4a5b91"
        d="M 393.644531 216.78125 L 529.382812 352.523438 C 544.527344 367.667969 544.527344 392.226562 529.382812 407.371094 L 393.644531 543.113281 L 338.796875 488.265625 L 447.113281 379.949219 L 338.796875 271.628906 Z M 393.644531 216.78125 "
        fillOpacity="1"
        fillRule="evenodd"
      />
      <g clipPath="url(#4bb3051def)">
        <path
          fill="#4a5b91"
          d="M 230.472656 69.683594 C 230.472656 48.261719 247.839844 30.898438 269.257812 30.898438 L 734.65625 30.898438 C 756.074219 30.898438 773.4375 48.261719 773.4375 69.683594 L 773.4375 690.214844 C 773.4375 711.632812 756.074219 728.996094 734.65625 728.996094 L 269.257812 728.996094 C 247.839844 728.996094 230.472656 711.632812 230.472656 690.214844 L 230.472656 573.863281 L 308.039062 573.863281 L 308.039062 651.429688 L 695.871094 651.429688 L 695.871094 108.464844 L 308.039062 108.464844 L 308.039062 186.03125 L 230.472656 186.03125 Z M 230.472656 69.683594 "
          fillOpacity="1"
          fillRule="evenodd"
        />
      </g>
    </StyledClickableIcon>
  )
}
