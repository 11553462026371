import React from 'react'
import RowWidgetContainer from '../../containers/RowWidgetContainer'
import { Articles } from './Articles'

const Bydleni = props => {
  const {
    isOpening,
    isOpen,
    handleToggleContent,
    widget,
    activeDynamicLayout
  } = props

  return (
    <RowWidgetContainer
      widget={widget}
      isOpening={isOpening}
      isOpen={isOpen}
      handleToggleContent={handleToggleContent}
      activeDynamicLayout={activeDynamicLayout}
    >
      <Articles widget={widget} layout={activeDynamicLayout}/>
    </RowWidgetContainer>
  )
}

export default Bydleni
