import React, { Component } from 'react'
import styled from 'styled-components'
import Spinner from './Spinner'

const StyledLoader = styled.div`
  color: #fff;
  background: rgba(0, 0, 0, 0.2);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  display: flex;
  display: -ms-flexbox;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  align-items: center;
  justify-content: center;
`

export default class Loader extends Component {
  render () {
    return (
      <StyledLoader>
        <Spinner/>
      </StyledLoader>
    )
  }
}
