import React from 'react'
import { EmailConsumer } from '../../../../context/Email'
import { isResponseFull } from '../EmailUtils'
import { LoggedInEmail } from './LoggedInEmail'
import { LoggedOutEmail } from './LoggedOutEmail'

export default (props) => {
  const {
    widget,
    googleAnalytics,
    embedded
  } = props

  return (
    <EmailConsumer googleAnalytics={googleAnalytics}>
      {({ inbox, userInfo, isLoading }) =>
        <div id={widget.name}>
          {isResponseFull(inbox)
            ? <LoggedInEmail {...props} inbox={inbox} userInfo={userInfo} embedded={embedded}/>
            : <LoggedOutEmail {...props} isLoading={isLoading}/>
          }
        </div>
      }
    </EmailConsumer>
  )
}
