import React, { Fragment, PureComponent } from 'react'
import { DEVICES, SOURCE_STRINGS } from '../../../constants'
import NewsFeedInfo from './StyledNewsFeedInfo'
import NewsFeedItem from './NewsFeedItem'
import { sendArticleClick } from '../../utils/ga4Events'
import { Modal } from './Modal'
import { ModalDetail } from './ModalDetail'

class NewsFeedItemArticle extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      isModalOpen: false
    }

    this.handleAnchorClick = this.handleAnchorClick.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal () {
    this.setState({ isModalOpen: true })
  }

  closeModal () {
    this.setState({ isModalOpen: false })
  }

  handleAnchorClick (event, location, modal) {
    event.preventDefault()

    if (modal) {
      this.openModal()
      return
    }

    const {
      box: {
        guid: id,
        title: name,
        link,
        originalLink = link
      },
      position,
      itemVariant
    } = this.props

    sendArticleClick({
      id,
      position: position + 1,
      boxName: 'newsFeed',
      url: originalLink,
      title: name,
      variant: itemVariant
    })

    const { layout } = this.props
    if (layout === DEVICES.DESKTOP) {
      window.open(location)
      return false
    } else {
      document.location.href = location
    }
  }

  render () {
    const {
      box,
      box: {
        source,
        category
      },
      layout
    } = this.props

    const { isModalOpen } = this.state

    return (
      <Fragment>
        {isModalOpen &&
          <Modal onClose={this.closeModal}>
            <ModalDetail article={box} layout={layout}/>
          </Modal>
        }
        <NewsFeedItem handleAnchorClick={this.handleAnchorClick} {...box} layout={layout}>
          <NewsFeedInfo source={source}>
            {SOURCE_STRINGS[source]}
            {
              category &&
            ` | ${category}`
            }
          </NewsFeedInfo>
        </NewsFeedItem>
      </Fragment>
    )
  }
}

export default NewsFeedItemArticle
