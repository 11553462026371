import { WEEKDAYS, NAMEDAYS, HOLIDAYS } from './calendarItems'
import { EXCHANGE_BASE_URL, EXCHANGE_FORMAT_REGEXP, EXCHANGE_IMAGES_PATH, EXCHANGE_FORM } from './exchange'
import {
  SECTIONS,
  SECTION_NAMES,
  SERVICE_URL_PATTERN
} from './search'
import { DEVICES, LAYOUT_SECTIONS, WIDGETS } from './layout'
import { SERVICES_LINKS } from './services'
import { DRBNA_REGIONS } from './drbna'
import { FOOTER_LINKS_SECTIONS, FOOTER_LINKS_SITES } from './footerLinks'
import { SIGNS, SIGN_INTERVALS, SIGN_NAMES, SIGNS_LIST } from './horoscopes'
import { SOURCE_STRINGS, SOURCE_IMAGES } from './newsFeed'
import { DEFAULT_VISIBILITY, VARIANTS, TIP_OF_THE_DAY_ICONS } from './tipOfTheDay'
import { TOGGLE_WIDGET_NAMES } from './toggleWidget'

export const SIZES = {
  BREAKPOINT_XS: 576,
  BREAKPOINT_SM: 768,
  BREAKPOINT_MD: 992
}

export const SASHEC_DEVICES = {
  d: SIZES.BREAKPOINT_MD,
  m: 0
}

export const API_WIDGETDATA_URI = '/data'

export const AMPLION_URL_REGEXP = 'amplion.centrum.cz'

export const GA_TRACKERS = {
  PAGEVIEW: 'pageview',
  ECOMMERCE: 'ecommerce',
  EVENTS: 'events'
}

export const GA_ENHANCED_ECOMMERCE = {
  ECOMMERCE_CATEGORY: 'Ecommerce',
  ECOMMERCE_PLUGIN: 'ec',
  IMPRESSION_PAYLOAD: {
    category: 'Ecommerce',
    action: 'Impression',
    nonInteraction: true
  },
  PURCHASE_PAYLOAD: {
    price: '1.00',
    quantity: 1
  }
}

export const GA_SEARCH_EVENT_CATEGORY = 'Search tab'

export const ARTICLE_FEATURED_TYPES = {
  COMMON: [
    'online',
    'video',
    'live'
  ],
  IMAGE: [
    'video',
    'live'
  ]
}

export const STATIC_PRANNOTATION_CONFIG = {
  type: 'position',
  async: true,
  supertag: [
    'prannotation'
  ]
}

export {
  WEEKDAYS,
  NAMEDAYS,
  HOLIDAYS,
  EXCHANGE_BASE_URL,
  EXCHANGE_FORM,
  EXCHANGE_FORMAT_REGEXP,
  EXCHANGE_IMAGES_PATH,
  SECTIONS,
  SECTION_NAMES,
  SERVICE_URL_PATTERN,
  DEVICES,
  LAYOUT_SECTIONS,
  WIDGETS,
  SERVICES_LINKS,
  DRBNA_REGIONS,
  FOOTER_LINKS_SECTIONS,
  FOOTER_LINKS_SITES,
  SIGNS,
  SIGN_INTERVALS,
  SIGNS_LIST,
  SIGN_NAMES,
  SOURCE_STRINGS,
  SOURCE_IMAGES,
  DEFAULT_VISIBILITY,
  VARIANTS,
  TIP_OF_THE_DAY_ICONS,
  TOGGLE_WIDGET_NAMES
}
