import React from 'react'
import styled from 'styled-components'
import { StyledEmailWrapper } from '../EmailSummary/StyledContainers'
import WidgetContainer from '../../../containers/WidgetContainer'
import { DEVICES } from '../../../../constants'
import Loader from '../../../utils/Loader'
import { login } from '../../../../utils/login'

const StyledIcon = styled.img`
  width: 32px;
  height: 32px;
  margin: auto;
`

const StyledTextWrapper = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`

const StyledText = styled.span`
  font-weight: 500;
  line-height: 22px;
  color: ${props => props.theme.email.identita.textColor}
`

const StyledButton = styled.button`
  border-radius: 8px;
  padding: 4px 16px;
  background-color: ${props => props.theme.email.identita.buttonColor};
  color: ${props => props.theme.email.identita.buttonTextColor};
  border-width: 0 0 2px 0;
  border-color: ${props => props.theme.email.identita.textColor};
  font-weight: 600;
  font-size: 15px;
  letter-spacing: .5px;
  
  &:hover {
    text-decoration: underline;
    background-color: ${props => props.theme.email.identita.buttonHoverColor};
  }
`

const StyledRegisterButton = styled.button`
  font-weight: 600;
  font-size: 12px;
  color: ${props => props.theme.email.full.link};
  letter-spacing: .2px;
  border: none;
  background: none;
  
  &:hover {
    color: ${props => props.theme.email.full.link};
  }
`

export const LoggedOutEmail = (props) => {
  const {
    activeDynamicLayout,
    ecoIdentity: {
      clientId,
      login: {
        location: loginLocation
      },
      registration: {
        location: registrationDomain
      }
    },
    widget,
    isLoading
  } = props

  const handleRegister = () => {
    login({ loginLocation: registrationDomain, clientId })
  }

  const handleLogin = () => {
    login({ loginLocation, clientId })
  }

  return (
    <WidgetContainer widget={widget} showBottomLine={false} hideTitle={activeDynamicLayout === DEVICES.MOBILE}>
      {isLoading && <Loader/>}
      <StyledEmailWrapper>
        <StyledTextWrapper>
          <StyledIcon src={`${STATICS_PATH}images/email/mail.svg`}/>
          <StyledText>Přihlašte se ke svému účtu</StyledText>
        </StyledTextWrapper>
        <StyledButton onClick={handleLogin}>Přihlásit se</StyledButton>
        <StyledRegisterButton onClick={handleRegister}>Založit nový účet</StyledRegisterButton>
      </StyledEmailWrapper>
    </WidgetContainer>
  )
}
