// @flow
import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { VerticalCol, VerticalRow } from '../../utils/Grid'
import HousingBox from '../../boxes/HousingBox'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import type { HousingWidgetProps } from '../Types'
import RowWidgetContainer from '../../containers/RowWidgetContainer'
import { sendArticleClick } from '../../utils/ga4Events'

const BoxesContainer = styled(VerticalRow)`
  justify-content: left;
`
const BoxContainer = styled(VerticalCol)`
  margin-right: 46px;
`

export default class Housing extends PureComponent<HousingWidgetProps> {
  render () {
    const {
      props: {
        activeDynamicLayout,
        isOpening,
        isOpen,
        handleToggleContent,
        widget,
        googleAnalytics,
        widget: {
          boxes,
          imageSize = '4_3',
          utmMedium
        },
        itemVariant
      }
    } = this

    if (!boxes.length) {
      return null
    }

    const handleAnchorClick = ({ box, position }) => () =>
      sendArticleClick({
        position,
        url: box.link,
        id: box.guid,
        title: box.title,
        boxName: widget.name,
        variant: itemVariant
      })

    return (
      <RowWidgetContainer
        widget={widget}
        isOpening={isOpening}
        activeDynamicLayout={activeDynamicLayout}
        isOpen={isOpen}
        googleAnalytics={googleAnalytics}
        handleToggleContent={handleToggleContent}
      >
        <BoxesContainer>
          {
            boxes.map((box, index) => {
              const {
                annotation,
                guid,
                images,
                link,
                price,
                title
              } = box

              const image = images[imageSize]

              const utmLink = utmLinkGenerator.createLink(link, {
                utmMedium,
                utmTerm: `position-${boxes.indexOf(box)}`
              })

              return (
                <BoxContainer key={guid}>
                  <HousingBox
                    annotation={annotation}
                    image={image}
                    imageClass={`image-${imageSize}`}
                    link={utmLink}
                    price={price}
                    title={title}
                    handleAnchorClick={handleAnchorClick({ box, position: index + 1 })}
                    layout={activeDynamicLayout}
                  />
                </BoxContainer>
              )
            })
          }
        </BoxesContainer>
      </RowWidgetContainer>
    )
  }
}
