import styled from 'styled-components'

export default styled.span`
  font-size: 14px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 8px;
`
