import styled from 'styled-components'

export default styled.div`
  background: ${props => props.theme.header.email.indicatorColor};
  border: 1px solid ${props => props.theme.header.email.indicatorColor};
  border-radius: 10px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  line-height: 10px;
  min-width: 18px;
  padding: 3px;
  position: absolute;
  right: ${props => props.full ? '-20px' : '-10px'};
  text-align: center;
  top: ${props => props.full ? '-5px' : '-10px'};
  &.grey {
    background: #aaa;
    border-color: #aaa;
  }
`
