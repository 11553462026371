import styled from 'styled-components'
import { SIZES } from '../../../constants'

export default styled.span`
  font-size: 16px;
  line-height: 20px;
  display: block;
  height: auto;
  overflow: hidden;
  font-weight: 600;
  margin-bottom: 8px;

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    height: 102px;
    text-overflow: unset;
    white-space: unset;
    font-weight: 300;
  }
`
