/* @flow */
import React, { Component } from 'react'
import styled from 'styled-components'
import Link from '../../utils/Link'
import { DEVICES, DRBNA_REGIONS } from '../../../constants'

const StyledRegionPickerTrigger = styled(Link)`
  background: url(${props => props.theme.jobs.arrowUrl}) no-repeat;
  background-position: ${props => props.isMobile ? '0 -22px' : '0 -24px'};
  color: ${props => props.theme.jobs.color};
  cursor: pointer;
  font-size: ${props => props.isMobile ? '11px' : '13px'};
  line-height: ${props => props.isMobile ? '31px' : '24px'};
  padding-left: 14px;
  text-decoration: underline;
  margin-right: ${props => props.isMobile ? '20px' : '0'};
  
  &.open {
    background-position: ${props => props.isMobile ? '0 2px' : '0 0'};
  }
  &:hover {
    background-position: ${props => props.isMobile ? '0 -22px' : '0 -72px'};
    color: ${props => props.isMobile ? props.theme.jobs.color : props.theme.jobs.colorHover};
  }
  &.open:hover {
    background-position: ${props => props.isMobile ? '0 2px' : '0 -48px'};
  }
`

type RegionPickerTriggerProps = {|
  activeRegion: Object,
  isOpen: boolean,
  onClick: () => void,
  activeDynamicLayout?: string,
|}

export default class RegionPickerTrigger extends Component<RegionPickerTriggerProps> {
  render () {
    const {
      activeRegion,
      isOpen,
      onClick,
      activeDynamicLayout
    } = this.props

    const isMobile = activeDynamicLayout === DEVICES.MOBILE

    return (
      <StyledRegionPickerTrigger
        className={isOpen ? 'open' : ''}
        href='#'
        onClick={event => {
          event.preventDefault()
          onClick()
        }}
        isMobile={isMobile}
      >
        {DRBNA_REGIONS[activeRegion]}
      </StyledRegionPickerTrigger>
    )
  }
}
