import styled from 'styled-components'
import Row from 'reactstrap/lib/Row'
import { SIZES } from '../../constants'

export default styled(Row)`
  background: #fff;
  flex-wrap: nowrap;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 10;
  display: -ms-flexbox;
  -ms-flex-align: stretch;
  -ms-flex-direction: row;
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    &.fixed {
      margin-top: 93px;
    }
  }
`
