import React, { useEffect, useState } from 'react'
import { EmailConsumer } from '../../../../context/Email'
import { LoggedOffHeaderLogin } from './LoggedOffHeaderLogin'
import { LoggedInHeaderLogin } from './LoggedInHeaderLogin'
import { isResponseFull } from '../../Email/EmailUtils'
import { LoadingBubble } from '../LoadingBubble'
import { observeRtbLeaderboardHeight } from './observeRtbLeaderboardHeight'

export const HeaderLogin = ({
  emailWidget,
  userMenuWidget,
  ecoIdentity,
  loginModalElement,
  userMenuElement,
  leaderboardHeight,
  headerHeight,
  activeDynamicLayout,
  variant
}) => {
  const [rtbLeaderboardHeight, setRtbLeaderboardHeight] = useState(0)

  useEffect(() => {
    const cleanupMutationObserver = observeRtbLeaderboardHeight((height) => {
      setRtbLeaderboardHeight(height)
    })

    return () => {
      cleanupMutationObserver()
    }
  }, [])

  return (
    <EmailConsumer>
      {({ inbox, userInfo, isLoading }) => {
        if (isLoading) {
          return <LoadingBubble />
        }

        const isLoggedIn = isResponseFull(inbox)
        if (isLoggedIn) {
          return (
            <LoggedInHeaderLogin
              emailWidget={emailWidget}
              userMenuWidget={userMenuWidget}
              ecoIdentity={ecoIdentity}
              inbox={inbox}
              userInfo={userInfo}
              leaderboardHeight={leaderboardHeight}
              rtbLeaderboardHeight={rtbLeaderboardHeight}
              userMenuElement={userMenuElement}
              headerHeight={headerHeight}
              layout={activeDynamicLayout}
            />
          )
        }

        return (
          <LoggedOffHeaderLogin
            inbox={inbox}
            ecoIdentity={ecoIdentity}
            leaderboardHeight={leaderboardHeight}
            rtbLeaderboardHeight={rtbLeaderboardHeight}
            loginModalElement={loginModalElement}
            headerHeight={headerHeight}
            variant={variant}
            activeDynamicLayout={activeDynamicLayout}
          />
        )
      }}
    </EmailConsumer>
  )
}
