import React from 'react'
import { ARTICLE_FEATURED_TYPES } from '../../constants'
import ArticleOverlay from '../utils/ArticleOverlay'
import Link from '../utils/Link'
import { type ListBoxProps } from './Types'
import { getVideoLabel } from '../utils/VideoLabel'
import { getLinkTarget } from '../utils/getLinkTarget'

export default (props: ListBoxProps) => {
  const {
    box,
    className,
    featuredSize,
    featuredType,
    link,
    onMouseOver,
    onMouseOut,
    title,
    duration,
    showLabel = false,
    handleAnchorClick,
    position = 0,
    layout
  } = props

  const isFeatured = ARTICLE_FEATURED_TYPES.COMMON.includes(featuredType)

  return (
    <li className={className} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <Link
        href={link}
        onClick={(event) => {
          Boolean(handleAnchorClick) && handleAnchorClick(event, link, box, position, layout)
        }}
        target={getLinkTarget(layout)}
        rel='noopener'
      >
        <span className={`title${isFeatured ? ' label' : ''}`}>
          { title }
        </span>
        {
          showLabel && isFeatured && <ArticleOverlay className={`${featuredSize} ${featuredType}`}>
            {
              getVideoLabel(featuredType, duration)
            }
          </ArticleOverlay>
        }
      </Link>
    </li>
  )
}
