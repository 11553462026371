import { HeaderLoginButton } from './HeaderLoginButton/HeaderLoginButton'
import styled from 'styled-components'
import React, { Component, createRef, Fragment } from 'react'
import { createPortal } from 'react-dom'
import { MailIcon } from '../../../icons/Mail/MailIcon'
import { getUnreadData } from '../../Email/EmailUtils'
import {
  StyledUnreadMessagesCount
} from '../../../ui/UnreadMessagesCount/StyledUnreadMessagesCount'
import {
  StyledEnvelopeContainer
} from '../../../ui/Envelope/StyledEnvelopeContainer'
import { LoginModal } from './LoginModal/LoginModal'

export class LoggedOffHeaderLogin extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isModalOpen: false,
      loginModalRoot: null,
      modalType: null
    }

    this.loginButtonRef = createRef()
    this.mailRef = createRef()
    this.modalRef = createRef()

    this.closeMenuWhenOutsideClick = this.closeMenuWhenOutsideClick.bind(this)
    this.handleModalVisibility = this.handleModalVisibility.bind(this)
  }

  componentDidMount () {
    document.addEventListener('click', this.closeMenuWhenOutsideClick)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.closeMenuWhenOutsideClick)
  }

  closeMenuWhenOutsideClick (event) {
    if (!this.modalRef.current || !this.mailRef.current || !this.loginButtonRef.current) {
      return
    }

    if (!this.modalRef.current.contains(event.target) &&
        !this.mailRef.current.contains(event.target) &&
        !this.loginButtonRef.current.contains(event.target)) {
      this.setState({
        isModalOpen: false,
        modalType: null
      })
    }
  }

  handleModalVisibility = () => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      modalType: null
    }))
  }

  render () {
    const {
      props: {
        inbox,
        ecoIdentity,
        leaderboardHeight,
        rtbLeaderboardHeight,
        loginModalElement,
        headerHeight,
        variant,
        activeDynamicLayout
      },
      state: {
        isModalOpen,
        modalType
      }
    } = this

    const unreadMessagesCount = inbox?.summary?.unread || 0
    const { unreadMessagesCountString, unreadFormatType } = getUnreadData(unreadMessagesCount)
    const shouldShowUnreadCount = inbox?.summary

    return (
      <Fragment>
        <StyledContainer>
          <StyledEnvelopeContainer>
            <MailIcon
              ref={this.mailRef}
              type='medium'
              onClick={() => this.handleModalVisibility('mailIcon')}
            />
            {shouldShowUnreadCount &&
              <StyledUnreadMessagesCount $unreadFormatType={unreadFormatType}>
                {unreadMessagesCountString}
              </StyledUnreadMessagesCount>
            }
          </StyledEnvelopeContainer>
          <StyledHeaderLoginButtonContainer $variant={variant}>
            <HeaderLoginButton
              ecoIdentity={ecoIdentity}
              ref={this.loginButtonRef}
            >
              Přihlásit
            </HeaderLoginButton>
          </StyledHeaderLoginButtonContainer>
        </StyledContainer>
        {isModalOpen && loginModalElement &&
          createPortal(
            <LoginModal
              ref={this.modalRef}
              ecoIdentity={ecoIdentity}
              leaderboardHeight={leaderboardHeight}
              rtbLeaderboardHeight={rtbLeaderboardHeight}
              headerHeight={headerHeight}
              variant={variant}
              activeDynamicLayout={activeDynamicLayout}
              modalType={modalType}
            />,
            loginModalElement
          )
        }
      </Fragment>
    )
  }
}

const StyledHeaderLoginButtonContainer = styled.div`
  position: initial;
  padding-right: 0;
`

const StyledContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: end;
  gap: 20px;
`
