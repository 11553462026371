import styled from 'styled-components'
import ArticleBox from '../../../boxes/ArticleBox'

export default styled(ArticleBox)`
  display: flex;
  display: -ms-flexbox;
  flex: 1;
  padding-bottom: 15px;
  position: relative;

  & .image-column {
    display: flex;
    display: -ms-flexbox;
    padding: 0;
    width: 160px;
  }

  & img {
    height: 120px;
    width: 160px;
  }

  & .title-column {
    display: flex;
    padding: 0;
  }

  & .title-column span {
    background: none;
    color: #333;
    font-size: 14px;
    height: auto;
    padding: 0 0 0 10px;
    width: 100%;
  }

  &:hover {
    text-decoration: underline;
  }

  &:hover .title-column span {
    color: inherit;
  }
`
