import styled from 'styled-components'

export const StyledUnreadMessagesCount = styled.span`
  position: absolute;
  top: -11px;
  right: ${({ $unreadFormatType }) => $unreadFormatType === 'overMaxShown' ? '-21px' : '-13px'};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ $unreadFormatType }) => $unreadFormatType === 'overMaxShown' ? '32px' : '24px'};
  height: 24px;
  padding: 4px;
  border-radius: ${({ $unreadFormatType }) => $unreadFormatType === 'overMaxShown' ? '100px' : '100%'};
  background: ${({ theme, $unreadFormatType }) => $unreadFormatType === 'zero'
    ? theme.headerLogin.unreadMessagesCount.zero.backgroundColor
    : theme.headerLogin.unreadMessagesCount.backgroundColor};
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
  color: ${({ theme }) => theme.headerLogin.unreadMessagesCount.color};
`
