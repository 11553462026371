import React, { PureComponent } from 'react'

export default class MobileWeather extends PureComponent {
  componentDidMount () {
    const script = document.createElement('script')
    script.src = 'https://pocasi-moravio.centrum.cz/public/tiny_forecast_mobile.bundle.js'
    this.container.appendChild(script)
  }

  render () {
    return (
      <div
        id='widget_tiny_forecast_mobile_container'
        ref={container => { this.container = container }}
      />
    )
  }
}
