import React, { PureComponent } from 'react'
import SpirMonWrapperWithoutImpression from '../Advert/SpirMonWrapperWithoutImpression'
import NewsFeedItemAd from './NewsFeedItemAd'
import Prannotation from '../../utils/Prannotation'

const Ad = props => {
  const {
    commentBottom,
    commentTop,
    id: guid,
    image: {
      default: {
        url: image
      }
    },
    title,
    url: link,
    wrapper
  } = props

  const box = {
    guid,
    image,
    link,
    title
  }

  return (
    <SpirMonWrapperWithoutImpression commentTop={commentTop} commentBottom={commentBottom} wrapper={wrapper}>
      <NewsFeedItemAd box={box}/>
    </SpirMonWrapperWithoutImpression>
  )
}

class NewsFeedItemPrannotation extends PureComponent {
  render () {
    const {
      props: {
        config,
        id
      }
    } = this

    return <Prannotation AdComponent={Ad} id={id} sashec={config} />
  }
}

export default NewsFeedItemPrannotation
