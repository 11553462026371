import React from 'react'
import Box from './Box'
import OverlayImage from './images/OverlayImage'
import SimpleTitle from './titles/SimpleTitle'
import { type ArticleBoxProps } from './Types'

const ArticleBox = (props: ArticleBoxProps) => {
  const {
    box,
    className,
    covered,
    duration,
    featuredSize,
    featuredType,
    image,
    imageClass,
    isImage,
    link,
    title,
    handleAnchorClick,
    index,
    position = index + 1,
    layout
  } = props

  const overlayImage = <OverlayImage
    covered={covered}
    duration={duration}
    imageClass={imageClass}
    overlaySize={featuredSize}
    overlayType={featuredType}
    src={image}
  />

  return (
    <Box
      className={className}
      image={isImage ? overlayImage : null}
      title={<SimpleTitle title={title}/>}
      link={link}
      handleAnchorClick={handleAnchorClick}
      box={box}
      position={position}
      layout={layout}
    />
  )
}

ArticleBox.defaultProps = {
  isImage: false
}

export default ArticleBox
