import styled from 'styled-components'
import { SIZES } from '../../../constants'

export default styled.div`
  background: #fff;
  border: 1px solid #2f6894;
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  height: 44px;
  line-height: 44px;
  width: 100%;
  padding: 0 10px;
  text-align: center;
  
  & .arrow {
    border: solid #2f6894;
    border-width: 0 1px 1px 0;
    display: inline-block;
    margin: 0 0 3px 10px;
    padding: 3px;
    transform: rotate(45deg);
  }
  
  &.open .arrow {
    margin: 0 0 0 10px;
    transform: rotate(225deg);
  }
  
  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    background: transparent;
  }
`
