import React, { Component } from 'react'
import Row from 'reactstrap/lib/Row'
import { SIZES } from '../../constants'
import styled from 'styled-components'

export const SCROLL_FIXED_OFFSET = 31

const Wrapper = styled.div`
  margin-bottom: 10px;

  @media (min-width: ${SIZES.BREAKPOINT_XS}px) {
    height: 55px;
    margin-bottom: 25px;
  }

  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    height: 86px;
  }
`

const ChildrenWrapper = styled.div`
  width: 100%;

  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    &.fixed {
      border-bottom: 1px solid #ccc;
      box-shadow: 0 3px 5px -2px #666;
      top: 0;
      left: 0;
      position: fixed;
      width: 100%;
      z-index: 1000;
      background: #fff;
    }
  }
`

const ChildrenContainer = styled(Row)`
  display: flex;
  flex-wrap: nowrap;
  display: -ms-flexbox;
  -ms-flex-direction: row;
  -ms-flex-wrap: nowrap;
  margin: auto;
  max-width: 1080px;

  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    &.fixed {
      padding: 0px 8px 15px 8px;
    }

    &.fixed .col {
      width: auto;
      min-width: 150px !important;
    }
  }
`

const StyledPortalRootContainer = styled.div`
  display: flex;
  justify-content: end;
`

export default class Header extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isFixed: false,
      shouldShowEmailLink: false,
      shouldShowPromoLinkHeader: true
    }
    this.headerRef = null
    this.wrapperRef = null
    this.emailWidget = null
    this.handleScroll = this.handleScroll.bind(this)
  }

  shouldComponentUpdate () {
    this.emailWidget = document.getElementById('email')
    return true
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
    this.updateHeight()
  }

  componentDidUpdate () {
    this.updateHeight()
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  updateHeight () {
    if (this.headerRef) {
      this.props.onHeightChange(this.headerRef.offsetHeight)
    }
  }

  handleScroll () {
    this.handleScrollHeader()
    this.handleScrollEmailLink()
  }

  handleScrollHeader () {
    const {
      state: {
        isFixed: oldFixed
      },
      wrapperRef
    } = this

    if (wrapperRef && wrapperRef.offsetParent) {
      const isFixed = window.pageYOffset >= wrapperRef.offsetParent.offsetTop + SCROLL_FIXED_OFFSET
      if (oldFixed !== isFixed) {
        this.setState(() => ({
          isFixed,
          shouldShowPromoLinkHeader: !isFixed
        }))
      }
    }
  }

  handleScrollEmailLink () {
    const {
      state: {
        shouldShowEmailLink
      },
      headerRef,
      emailWidget
    } = this

    if (emailWidget && headerRef) {
      const newShouldShowEmailLink = emailWidget.getBoundingClientRect().bottom <= headerRef.offsetTop

      if (shouldShowEmailLink !== newShouldShowEmailLink) {
        this.setState(() => ({ shouldShowEmailLink: newShouldShowEmailLink }))
      }
    }
  }

  render () {
    const {
      props: {
        children,
        loginModalRef,
        userMenuRef
      },
      state: {
        isFixed,
        shouldShowEmailLink,
        shouldShowPromoLinkHeader
      }
    } = this
    const isFixedClassName = isFixed ? 'fixed' : ''

    return (
      <Wrapper innerRef={ref => { this.wrapperRef = ref }}>
        <ChildrenWrapper id='header' className={isFixedClassName} innerRef={ref => { this.headerRef = ref }}>
          <ChildrenContainer className={isFixedClassName}>
            {
              Array.isArray(children) &&
              children.map(child => child && React.cloneElement(child, { isFixed, shouldShowEmailLink, shouldShowPromoLinkHeader }))
            }
          </ChildrenContainer>
        </ChildrenWrapper>
        <StyledPortalRootContainer innerRef={loginModalRef} />
        <StyledPortalRootContainer innerRef={userMenuRef} />
      </Wrapper>
    )
  }
}
