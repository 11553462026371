import React, { Component } from 'react'
import styled from 'styled-components'
import request from '../../utils/Request'

const WidgetWrapper = styled.dd`
  margin-bottom: 0;
`

/**
 * @param InnerComponent
 * @return {{new(): SpirMonWrapper, prototype: SpirMonWrapper}}
 */
export default function (InnerComponent) {
  /**
   * SpirMonWrapper
   */
  return class SpirMonWrapper extends Component {
    componentDidMount () {
      /*
      const requestConfig = {
        url: `//r.i0.cz/l.gif?s=admon&v=1&e=view&m=${impressionId}&j=${new Date().getTime()}`
      }

      this.spirStart.outerHTML = `<!-- spir.czMonS[${admonId}] -->`
      this.spirEnd.outerHTML = '<!-- spir.czMonE -->'
*/
      request().catch(() => {
      })
    }

    /**
     * Render
     * @returns {XML} markup
     */
    render () {
      return (
        <WidgetWrapper>
          <span ref={spir => {
            this.spirStart = spir
          }}/>
          <InnerComponent {...this.props} />
          <span ref={spir => {
            this.spirEnd = spir
          }}/>
        </WidgetWrapper>
      )
    }
  }
}
