// @flow
import React, { PureComponent } from 'react'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import WidgetContainer from '../../containers/WidgetContainer'
import OverlayImage from '../../boxes/images/OverlayImage'
import ListBox from '../../boxes/VideoListBox'
import { type VideoWidgetProps } from './Types'
import FirstBox from './StyledFirstBox'
import ContainerRow from './StyledContainerRow'
import LeftColumn from './StyledLeftColumn'
import RightColumn from './StyledRightColumn'
import VideoImage from './StyledVideoImage'
import VideoImageWrapper from './StyledVideoImageWrapper'
import { sendArticleClick } from '../../utils/ga4Events'
import { getLinkTarget } from '../../utils/getLinkTarget'

export default class Video extends PureComponent<VideoWidgetProps> {
  render () {
    const {
      isOpening,
      widget,
      googleAnalytics,
      activeDynamicLayout,
      widget: {
        boxes = [],
        utmMedium
      },
      itemVariant
    } = this.props

    if (!boxes.length) return null

    const videoBoxes = [...boxes]
    let featuredBox = videoBoxes.shift()
    featuredBox = {
      ...featuredBox,
      ...{ image: featuredBox.images['4_3L'] }
    }

    const featuredUtmLink = utmLinkGenerator.createLink(featuredBox.link, {
      utmMedium,
      utmTerm: `position-0`,
      utmCampaign: featuredBox.source
    })

    const handleAnchorClick = ({ box, position }) => () =>
      sendArticleClick({
        position,
        boxName: 'video',
        id: box.guid,
        url: box.link,
        title: box.title,
        variant: itemVariant
      })

    return (
      <WidgetContainer
        googleAnalytics={googleAnalytics}
        widget={widget}
        isOpening={isOpening}
        activeDynamicLayout={activeDynamicLayout}
      >
        <ContainerRow>
          <LeftColumn>
            <FirstBox
              target={getLinkTarget(activeDynamicLayout)}
              rel='noopener'
              onClick={handleAnchorClick({ position: 1, box: featuredBox })}
              href={featuredUtmLink}
            >
              <OverlayImage
                imageComponent={VideoImage}
                imageWrapperComponent={VideoImageWrapper}
                overlayType={featuredBox.featuredType}
                src={featuredBox.image}
                duration={featuredBox.duration}
              />
              <div>
                {featuredBox.title}
              </div>
            </FirstBox>
          </LeftColumn>
          <RightColumn>
            {
              videoBoxes.map((box, index) => {
                const {
                  featuredType,
                  guid,
                  link,
                  source,
                  title,
                  duration,
                  images: {
                    '16_9': image
                  }
                } = box

                const utmLink = utmLinkGenerator.createLink(link, {
                  utmMedium,
                  utmTerm: `position-${index + 1}`,
                  utmCampaign: source
                })

                const listBox = { guid, title, link }
                return (
                  <ListBox
                    featuredType={featuredType}
                    key={guid}
                    link={utmLink}
                    title={title}
                    image={image}
                    duration={duration}
                    handleAnchorClick={handleAnchorClick({ box: listBox, position: index + 2 })}
                    layout={activeDynamicLayout}
                  />
                )
              })
            }
          </RightColumn>
        </ContainerRow>
      </WidgetContainer>
    )
  }
}
