import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  flex: 1 1 0%;
  height: 64px;
  margin: 0 8px 12px 8px;
  text-align: center;
  
  &:first-child {
    margin-left: 0;
  }
  
  &:last-child {
    margin-right: 0;
  }

  &.active {
    border: none;
    border-radius: 2px 2px 0 0;
    background: ${props => props.theme.mobileTabs.background};
    height: 76px;
    margin-bottom: 0;
    padding: 1px 1px 12px 1px;
  }
`
