import { CENTER_CONTENT_CONTAINER_ID } from '../../../containers/Main'

/**
 * Observes the 'margin-top' style of the main content container and invokes the `onChange` callback whenever it changes.
 * Returns a function that, when called, disconnects the observer and clears any pending observation attempts, useful for cleanup.
 * @param {Function} onChange - Callback that receives the new margin-top value as an integer whenever it changes.
 * @returns {Function} Function to stop observation and clear pending attempts.
 */
export const observeMainContentContainerMarginTopChange = (onChange) => {
  let mutationObserver = null
  let intervalId = null

  const extractMarginTop = (element) => parseInt(window.getComputedStyle(element).marginTop)

  const notifyMarginTopChange = (element) => onChange(extractMarginTop(element))

  const createMutationObserverForElement = (element) => {
    mutationObserver = new MutationObserver(() => notifyMarginTopChange(element))
    mutationObserver.observe(element, { attributes: true, attributeFilter: ['style'] })
    notifyMarginTopChange(element)
  }

  const attemptToFindElementAndObserve = () => {
    const element = document.getElementById(CENTER_CONTENT_CONTAINER_ID)
    if (element) {
      clearInterval(intervalId)
      createMutationObserverForElement(element)
    }
  }

  intervalId = setInterval(attemptToFindElementAndObserve, 500)
  attemptToFindElementAndObserve()

  return () => {
    mutationObserver?.disconnect()
    clearInterval(intervalId)
  }
}
