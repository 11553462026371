import styled from 'styled-components'
import { SIZES } from '../../../constants'

export default styled.div`
  min-height: 110px;
  position: relative;
  margin-bottom: 25px;
  overflow: hidden;

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    height: 114px;
  }
`
