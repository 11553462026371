import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Close = styled.span`
  background: url(${UNVERSIONED_STATICS_PATH}images/tipoftheday/close-button.png) 0 0;
  cursor: pointer;
  height: 15px;
  margin-left: auto;
  width: 15px;
  
  &:hover {
    background-position: -15px 0;
  }
`

class CloseButton extends Component {
  handleCloseButtonClick () {
    this.props.closeMethod()
  }

  render () {
    return (
      <Close onClick={this.handleCloseButtonClick.bind(this)}/>
    )
  }
}

CloseButton.propTypes = {
  closeMethod: PropTypes.func.isRequired
}

export default CloseButton
