import React from 'react'
import ArticleOverlay from '../../utils/ArticleOverlay'
import ImageWrapper from './styled/ImageWrapper'
import CoveredImage from './CoveredImage'
import Image from './SimpleImage'
import { type OverlayImageProps } from '../Types'
import { getVideoLabel } from '../../utils/VideoLabel'
import { getVideoFeaturedTypes } from '../../utils/getVideoFeaturedTypes'

export default (props: OverlayImageProps) => {
  const {
    covered,
    duration,
    imageClass,
    imageComponent: ImageComponent = Image,
    imageWrapperComponent: ImageWrapperComponent = ImageWrapper,
    overlaySize,
    overlayType,
    src
  } = props

  return (
    <ImageWrapperComponent>
      {
        getVideoFeaturedTypes().includes(overlayType) &&
        <ArticleOverlay className={`${overlaySize || ''} ${overlayType}`}>
          {
            getVideoLabel(overlayType, duration)
          }
        </ArticleOverlay>
      }
      {
        covered ? <CoveredImage src={src}/> : <ImageComponent className={imageClass} src={src}/>
      }
    </ImageWrapperComponent>
  )
}
