const fontColor = '#333'
const mainColor = '#2f6894'
const highlightColor = '#eb6600'
const darkHighlightColor = '#d05d03'
const buttonColor = '#fff'
const buttonBackgroundColor = '#00467f'
const buttonBorderColor = '#00325c'
const shadowColor = '#00000040'
const inboxBorder = '#dcdcdc'

const buttonStyles = {
  color: buttonColor,
  background: buttonBackgroundColor,
  border: buttonBorderColor,
  hover: {
    background: highlightColor,
    border: darkHighlightColor
  }
}

export default {
  header: {
    logo: {
      bottom: 10,
      height: 30
    },
    holidayLogo: {
      top: 25
    },
    search: {
      sections: {
        color: '#949494',
        activeColor: highlightColor
      },
      form: {
        autocomplete: {
          color: highlightColor
        },
        input: {
          borderColor: mainColor,
          borderColorFocus: highlightColor,
          color: fontColor
        }
      }
    },
    email: {
      indicatorColor: highlightColor,
      linkColor: mainColor
    }
  },
  headerLogin: {
    shadowColor,
    dividerColor: inboxBorder,
    icon: {
      color: buttonBackgroundColor,
      hover: {
        color: highlightColor
      }
    },
    unreadMessagesCount: {
      color: buttonColor,
      backgroundColor: highlightColor,
      zero: {
        backgroundColor: '#aaa'
      }
    },
    newLabel: {
      color: highlightColor
    },
    loginButton: {
      color: buttonBackgroundColor,
      backgroundColor: buttonColor,
      hover: {
        color: highlightColor
      }
    },
    loginModal: {
      backgroundColor: buttonColor,
      title: {
        color: fontColor
      },
      message: {
        color: fontColor
      },
      loginButton: {
        color: buttonColor,
        backgroundColor: buttonBackgroundColor,
        borderColor: buttonBorderColor,
        hover: {
          backgroundColor: highlightColor,
          borderColor: darkHighlightColor
        }
      }
    },
    userMenu: {
      backgroundColor: buttonColor,
      item: {
        color: buttonBackgroundColor,
        hover: {
          color: buttonBackgroundColor,
          backgroundColor: highlightColor
        }
      },
      basicInfo: {
        color: fontColor
      }
    },
    avatar: {
      borderColor: buttonBackgroundColor,
      color: buttonBackgroundColor
    }
  },
  footer: {
    color: fontColor,
    colorLink: fontColor
  },
  headline: {
    color: mainColor,
    colorHover: highlightColor
  },
  moreLink: {
    color: mainColor,
    colorHover: highlightColor
  },
  link: {
    colorHover: highlightColor
  },
  button: buttonStyles
}
