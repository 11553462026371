export const hexParse = hexStr => {
  const hexStrLength = hexStr.length

  let words = []
  for (let i = 0; i < hexStrLength; i += 2) {
    words[i >>> 3] |= parseInt(hexStr.substr(i, 2), 16) << (24 - (i % 8) * 4)
  }

  let sigBytes = hexStrLength / 2

  words = words || []

  if (sigBytes === undefined) {
    sigBytes = words.length * 4
  }

  return {
    words,
    sigBytes,
    clamp: function () {
      words[sigBytes >>> 2] &= 0xffffffff << (32 - (sigBytes % 4) * 8)
      words.length = Math.ceil(sigBytes / 4)
    }
  }
}

export const base64Stringify = wordArray => {
  // Shortcuts
  var words = wordArray.words
  var sigBytes = wordArray.sigBytes
  var map = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='

  // Clamp excess bits
  wordArray.clamp()

  // Convert
  var base64Chars = []
  for (var i = 0; i < sigBytes; i += 3) {
    var byte1 = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff
    var byte2 = (words[(i + 1) >>> 2] >>> (24 - ((i + 1) % 4) * 8)) & 0xff
    var byte3 = (words[(i + 2) >>> 2] >>> (24 - ((i + 2) % 4) * 8)) & 0xff

    var triplet = (byte1 << 16) | (byte2 << 8) | byte3

    for (var j = 0; (j < 4) && (i + j * 0.75 < sigBytes); j++) {
      base64Chars.push(map.charAt((triplet >>> (6 * (3 - j))) & 0x3f))
    }
  }

  // Add padding
  var paddingChar = map.charAt(64)
  if (paddingChar) {
    while (base64Chars.length % 4) {
      base64Chars.push(paddingChar)
    }
  }

  return base64Chars.join('')
}
