import React, { PureComponent } from 'react'
import * as Sentry from '@sentry/browser'
import styled from 'styled-components'
import { sendWidgetError, sendWidgetImpression } from './ga4Events'

const StyledHint = styled.div`
  color: #999;
  background: rgba(0, 0, 0, 0.1);
  align-items: center;
  text-align: center;
  padding: 12px;
  border-radius: 3px;
  margin: 5px 0;
`

const StyledBoundaryWrapper = styled.div`
    display: contents;
`
class ErrorBoundaryComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      error: undefined
    }
    this.widget = React.createRef()
  }

  componentDidCatch (error) {
    const {
      widgetName
    } = this.props

    this.setState({ error })

    Sentry.captureException(error, {
      tags: {
        widget: widgetName
      }
    })
  }

  handleComponentError = () => {
    const { state: { error }, props: { widgetName } } = this
    if (error) {
      sendWidgetError({
        name: widgetName,
        message: error.message
      })
    }
  }

  handleScroll = () => {
    const { props: { widgetPosition, children: { props: { widget } } } } = this
    if (!widget || !this.widget.current) {
      return
    }

    const currentWidget = this.widget.current
    const widgetTop = currentWidget.getBoundingClientRect().top / 2
    if (widget && widgetTop > 0 && !currentWidget.gaSent) {
      sendWidgetImpression({
        name: widget.name,
        position: widgetPosition
      })
      this.widget.current.gaSent = true
    }
  }

  componentDidMount () {
    this.handleComponentError()
    if (this.widget && this.widget.current) {
      this.widget.current.gaSent = false
    }
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate () {
    this.handleComponentError()
    this.handleScroll()
  }

  render () {
    if (this.state.error) {
      return (
        <StyledHint>
          Chyba při načítání
        </StyledHint>
      )
    }

    return <StyledBoundaryWrapper innerRef={this.widget}>
      { React.cloneElement(this.props.children, this.props) }
    </StyledBoundaryWrapper>
  }
}

export default ErrorBoundaryComponent
