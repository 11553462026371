import React, { Component } from 'react'
import MobileArticleImageBox from '../../../boxes/StyledMobileArticleImageBox'
import { ImageRow } from '../../../utils/Grid'
import ImageBoxWrapper from '../ImageBoxWrapper'
import ContainerRow from '../StyledContainerRow'
import MobileFirstImageBox from './StyledMobileFirstImageBox'
import MobileBoxesGenerator from '../../../utils/MobileBoxesGenerator'
import { sendArticleClick } from '../../../utils/ga4Events'

export default class Dynamiclead extends Component {
  render () {
    const {
      activeDynamicLayout,
      widget: {
        ad,
        boxes,
        imageSize,
        name,
        utmContent,
        utmMedium,
        utmSource
      },
      itemVariant
    } = this.props

    if (!boxes || !boxes.length) {
      return null
    }

    const smallBoxes = boxes.map(box => Object.assign({}, box, {
      featuredSize: 'small'
    }))
    const firstBox = Object.assign({}, smallBoxes.shift(), {
      featuredSize: 'big'
    })

    const utmParams = {
      utmContent,
      utmMedium,
      utmSource
    }

    const handleClick = ({ box, position }) => () =>
      sendArticleClick({
        position,
        url: box.link,
        title: box.title,
        id: box.guid,
        boxName: name,
        variant: itemVariant
      })

    const renderImageBox = (box, index) => <ImageBoxWrapper
      box={box}
      imageBoxComponent={MobileArticleImageBox}
      imageBoxComponentProps={{ handleAnchorClick: handleClick({ box, position: index + 2 }) }}
      imageSize={imageSize}
      index={index + 1}
      key={`smallMobileDynamicLeadBox${index + 1}`}
      utmParams={utmParams}
    />

    return (
      <React.Fragment>
        <div id={'carodaSlot1'}></div>
        <ContainerRow id={name}>
          <ImageBoxWrapper
            box={firstBox}
            imageBoxComponent={MobileFirstImageBox}
            imageBoxComponentProps={{ handleAnchorClick: handleClick({ url: firstBox.link, position: 1 }) }}
            imageSize='4_3L'
            index={0}
            utmParams={utmParams}
          />
          <ImageRow>
            <MobileBoxesGenerator
              ad={ad}
              boxes={smallBoxes}
              device={activeDynamicLayout}
              name={name}
              renderImageBox={renderImageBox}
            />
          </ImageRow>
        </ContainerRow>
      </React.Fragment>
    )
  }
}
