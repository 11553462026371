import React, { Fragment, useRef } from 'react'
import styled from 'styled-components'
import { CraPlayer } from './CraPlayer/CraPlayer'

const PEREX_PREVIEW_MAX_CHARACTERS = 200
const CLASS_HIDDEN = 'hidden'

export const ModalDetail = ({ article, layout }) => {
  const {
    title,
    perex,
    video,
    publicationDate
  } = article

  const showMoreRef = useRef(null)
  const hiddenPerexRef = useRef(null)

  const onMoreClick = (event) => {
    event.preventDefault()
    event.stopPropagation()

    showMoreRef.current.classList.add(CLASS_HIDDEN)
    hiddenPerexRef.current.classList.remove(CLASS_HIDDEN)
  }

  const visiblePerex = perex.substring(0, PEREX_PREVIEW_MAX_CHARACTERS)
  const hiddenPerex = perex.length > PEREX_PREVIEW_MAX_CHARACTERS
    ? perex.substring(PEREX_PREVIEW_MAX_CHARACTERS, perex.length)
    : null

  return (
    <StyledWrapper>
      <StyledTitle>{title}</StyledTitle>
      <StyledInfoWrapper>
        <StyledPublicationDate>{publicationDate}</StyledPublicationDate>
        <StyledImage src={`${UNVERSIONED_STATICS_PATH}images/newsfeed/datarun-modal-logo.png`} alt='logo'/>
      </StyledInfoWrapper>
      <CraPlayer video={video} layout={layout} />
      <StyledText>
        <span>{visiblePerex}</span>
        {hiddenPerex && (
          <Fragment>
            <span ref={showMoreRef}>{'...'}<StyledMore onClick={onMoreClick}>(více)</StyledMore></span>
            <span ref={hiddenPerexRef} className={CLASS_HIDDEN}>{hiddenPerex}</span>
          </Fragment>
        )}
      </StyledText>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  margin-right: 26px;
`

const StyledTitle = styled.h3`
  font-size: 25px;
  line-height: 1.3;
`

const StyledInfoWrapper = styled.div`
  margin-bottom: 17px;
`

const StyledPublicationDate = styled.span`
  font-size: 15px;
`

const StyledImage = styled.img`
  margin-left: 22px;
  padding-bottom: 6px;
  width: 55px;
`

const StyledText = styled.p`
  margin-top: 15px;
  font-size: 17px;
  margin-bottom: 0;
  
  .hidden {
    display: none;
  }
`

const StyledMore = styled.button`
  border: none;
  background: none;
  font-weight: 500;
`
