import styled from 'styled-components'
import { VerticalCol } from '../../utils/Grid'
import { SIZES } from '../../../constants'

export default styled(VerticalCol)`
  overflow: hidden;

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    flex: 0 0 40%;

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`
