/* @flow */
import * as React from 'react'
import Col from 'reactstrap/lib/Col'
import EmailLinkFull from './EmailLinkFull'
import EmailLinkSummary from './EmailLinkSummary'
import { isResponseFull } from '../../Email/EmailUtils'
import { EmailConsumer } from '../../../../context/Email'
import { type WidgetType } from './Types'

type Props = {|
  widget: WidgetType,
  shouldShowEmailLink: boolean,
  forceShowEmailLink: boolean
|}

class EmailLink extends React.PureComponent<Props> {
  static defaultProps = {
    shouldShowEmailLink: false,
    forceShowEmailLink: false
  }

  render () {
    const {
      forceShowEmailLink,
      shouldShowEmailLink,
      widget
    } = this.props

    if (!shouldShowEmailLink && !forceShowEmailLink) {
      return null
    }

    return (
      <Col id={widget.name}>
        <EmailConsumer>
          {
            ({ handleLogoutClick, inbox, isLoadingInbox }) => isResponseFull(inbox) ? (
              <EmailLinkFull
                widget={widget}
                inbox={inbox}
                handleLogoutClick={handleLogoutClick}
                isLoading={isLoadingInbox}
              />
            ) : (
              <EmailLinkSummary widget={widget} inbox={inbox}/>
            )
          }
        </EmailConsumer>
      </Col>
    )
  }
}

export default EmailLink
