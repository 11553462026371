export const DRBNA_REGIONS = {
  'ceskaRepublika': 'Česká republika',
  'brno': 'Brno',
  'ceskeBudejovice': 'České Budějovice',
  'praha': 'Praha',
  'jihlava': 'Jihlava',
  'plzen': 'Plzeň',
  'liberec': 'Liberec',
  'karlovyVary': 'Karlovy Vary',
  'hradecKralove': 'Hradec Králové'
}
