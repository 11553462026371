import { LoginIcon } from '../components/icons/Login/LoginIcon'
import { StyledMobileUserMenuLink } from './MobileUserMenu'
import React from 'react'
import styled from 'styled-components'

export const MobileUserMenuLoggedOff = ({
  loginText,
  onLogin
}) => {
  return (
    <StyledMobileUserMenuLink onClick={onLogin}>
      <LoginIcon />
      {loginText}
    </StyledMobileUserMenuLink>)
}

export const StyledMobileUserMenuLoggedOffContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;
`
