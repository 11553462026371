import React, { Component } from 'react'
import PropTypes from 'prop-types'
import shortid from 'shortid'
import Cookie from '../components/utils/Cookie'
import utmLinkGenerator from '../components/utils/UTMLinkGenerator'
import ErrorBoundaryComponent from '../components/utils/ErrorBoundaryComponent'
import LeaderBoard from '../components/widgets/Advert/LeaderBoard'
import Logo from '../components/widgets/Header/Logo'
import Search from '../components/widgets/Header/Search'
import EmailLink from '../components/widgets/Header/EmailLink'
import SashecAdvertPositionWrapper
  from '../components/widgets/Advert/SashecAdvertPositionWrapper'
import StickyHalfpage from '../components/widgets/Advert/StickyHalfpage'
import containers from '../containers'
import { detectDevice, isValidDevice } from '../components/utils/Devices'
import {
  detectSashecDevice,
  sashecQueuePush
} from '../components/utils/SashecHelpers'
import { DEVICES, LAYOUT_SECTIONS, WIDGETS } from '../constants'
import {
  HeaderLogin
} from '../components/widgets/Header/HeaderLogin/HeaderLogin'

class LayoutHeadless extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeDynamicLayout: isValidDevice(props.dynamicLayoutCookie) ? props.dynamicLayoutCookie : detectDevice(),
      containers,
      cookies: new Cookie(),
      itemVariant: props.widgets.config.itemVariant,
      leaderboardHeight: 0,
      headerHeight: 0,
      loginModalElement: null,
      userMenuElement: null
    }

    this.pageViewTarget = 'https://mer.stdout.cz/l.gif'
    this.dynamicWidgetsLayout = this.getDynamicWidgetsLayout()
    this.updateDynamicLayoutCookie(props.dynamicLayoutCookie)

    utmLinkGenerator.addUtmParams({
      utmSource: props.widgets.layout.utmSource,
      utmContent: props.widgets.layout.utmContent
    })
    this.handleResize = this.handleResize.bind(this)
    this.onLeaderBoardHeightChange = this.onLeaderBoardHeightChange.bind(this)
  }

  componentWillMount () {
    if (!this.props.clientSide) {
      return false
    }

    const {
      meta: {
        sashec: {
          site,
          template,
          area,
          render
        },
        sashecAntiAdblock
      }
    } = this.props.widgets.layout
    const sashecDevice = detectSashecDevice()
    let targets = {
      device: sashecDevice,
      template
    }
    if (this.isAdblockEnabled()) {
      Object.assign(targets, sashecAntiAdblock.targets)
    }
    if (this.props.widgets.layout.meta.sashec[this.props.widgets.config.environment]) {
      Object.assign(targets, this.props.widgets.layout.meta.sashec[this.props.widgets.config.environment])
    }

    const sashecPayload = [
      'group',
      {
        site,
        targets,
        area,
        render
      }
    ]
    sashecQueuePush(sashecPayload)
    sashecQueuePush([...sashecPayload, 'priority'])
    this.state.containers.sashec.setState({ payload: sashecPayload })
  }

  componentDidMount () {
    if (this.props.clientSide) {
      window.addEventListener('resize', this.handleResize)
      const image = document.createElement('img')
      image.src = this.composePixelUri()
      document.body.appendChild(image)
    }

    this.setTrackingCookieIfNotExist()
  }

  componentWillUnmount () {
    if (this.props.clientSide) {
      window.removeEventListener('resize', this.handleResize)
    }
  }

  /**
   * Update elements references
   * @param loginModalRef - reference to login modal
   * @param userMenuRef - reference to user menu
   */
  updateElements (loginModalRef, userMenuRef) {
    const loginModalElement = loginModalRef.current
    if (loginModalElement && this.state.loginModalElement !== loginModalElement) {
      this.setState({ loginModalElement })
    }

    const userMenuElement = userMenuRef.current
    if (userMenuElement && this.state.userMenuElement !== userMenuElement) {
      this.setState({ userMenuElement })
    }
  }

  onLeaderBoardHeightChange (height) {
    if (this.state.leaderboardHeight !== height) {
      this.setState({
        leaderboardHeight: height
      })
    }
  }

  onHeaderHeightChange = (height) => {
    if (this.state.headerHeight !== height) {
      this.setState({ headerHeight: height })
    }
  }

  composePixelUri () {
    const {
      meta: {
        sashec: {
          area
        },
        pramon: {
          hash
        }
      }
    } = this.props.widgets.layout

    const queryParams = {
      s: 'pramon',
      v: '1',
      e: 'view',
      t: hash,
      nm: 'visit',
      mt: new Date().getTime().toString(),
      hs: `hp:${area}`,
      hr: Math.floor(Math.random() * 1e9).toString(36)
    }

    return `${this.pageViewTarget}?${new URLSearchParams(queryParams).toString()}`
  }

  isAdblockEnabled () {
    return this.state.cookies.isSet(this.props.widgets.config.cookies.antiAdblock)
  }

  handleResize () {
    const currentDevice = detectDevice()
    if (this.state.activeDynamicLayout !== currentDevice) {
      this.updateDynamicLayoutCookie(currentDevice)
      this.setState({
        activeDynamicLayout: currentDevice
      })
    }
  }

  getGADimensionCookie () {
    const gaDimensionCookieName = this.props.widgets.config.cookies.gaCustomDimension

    return this.state.cookies.isSet(gaDimensionCookieName) ? this.state.cookies.cookieJar[gaDimensionCookieName] : ''
  }

  updateDynamicLayoutCookie (layout) {
    if (typeof document !== 'undefined') {
      this.setCookie(this.props.widgets.config.cookies.dynamicLayout, layout)
    }
  }

  setTrackingCookieIfNotExist () {
    const cookieName = this.props.widgets.config.cookies.tracking
    if (!this.state.cookies.isSet(cookieName)) {
      const domain = window.location.hostname.split('.').slice(-2).join('.')
      this.setCookie(cookieName, shortid.generate(), domain && `.${domain}`)
    }
  }

  setCookie (key, value, domain) {
    const date = new Date()
    date.setTime(date.getTime() + 365 * 24 * 60 * 60 * 1000)
    document.cookie = `${key}=${value}; expires=${date.toGMTString()}${domain && `; domain=${domain}`}`
  }

  getDynamicWidgets () {
    const {
      props: {
        widgets,
        widgets: {
          config: {
            proxyOptions,
            searchSecret,
            ecoIdentity,
            variant
          }
        }
      },
      state: {
        loginModalElement,
        userMenuElement,
        headerHeight,
        leaderboardHeight,
        activeDynamicLayout
      }
    } = this

    return {
      [WIDGETS.COMMON.LEADERBOARD]: () => <LeaderBoard widget={widgets.leaderboard} onHeightChange={this.onLeaderBoardHeightChange}/>,
      [WIDGETS.COMMON.STRIP]: () => <LeaderBoard widget={widgets.strip}/>,
      [WIDGETS.COMMON.LOGO]: () => <Logo widget={widgets.logo}/>,
      [WIDGETS.COMMON.SEARCH]: () => <Search widget={widgets.search} proxyOptions={proxyOptions} secret={searchSecret} variant={variant}/>,
      [WIDGETS.COMMON.EMAIL_LINK]: () => <EmailLink widget={widgets.emaillink}/>,
      [WIDGETS.HOMEPAGE.HEADER_LOGIN]: () => <HeaderLogin emailWidget={widgets.email} userMenuWidget={widgets.usermenu} ecoIdentity={ecoIdentity} leaderboardHeight={leaderboardHeight} loginModalElement={loginModalElement} userMenuElement={userMenuElement} headerHeight={headerHeight} variant={variant} activeDynamicLayout={activeDynamicLayout}/>,
      [WIDGETS.COMMON.RIGHT_BANNER]: () => <SashecAdvertPositionWrapper widget={widgets.rightbanner}/>,
      [WIDGETS.COMMON.HALF_PAGE]: () => <StickyHalfpage widget={widgets.halfpage}/>,
      [WIDGETS.COMMON.SMART_ADVERT_1]: () => <SashecAdvertPositionWrapper widget={widgets.advertsmart1}/>,
      [WIDGETS.COMMON.SMART_ADVERT_2]: () => <SashecAdvertPositionWrapper widget={widgets.advertsmart2}/>,
      [WIDGETS.COMMON.SMART_ADVERT_3]: () => <SashecAdvertPositionWrapper widget={widgets.advertsmart3}/>,
      [WIDGETS.COMMON.SMART_ADVERT_4]: () => <SashecAdvertPositionWrapper widget={widgets.advertsmart4}/>
    }
  }

  getDynamicWidgetsLayout () {
    return {
      [DEVICES.DESKTOP]: this.getDesktopWidgetsLayout(),
      [DEVICES.TABLET]: this.getTabletWidgetsLayout(),
      [DEVICES.MOBILE]: this.getMobileWidgetsLayout()
    }
  }

  getDesktopWidgetsLayout () {
    return {}
  }

  getTabletWidgetsLayout () {
    return {}
  }

  getMobileWidgetsLayout () {
    return {}
  }

  renderDynamicWidgetsLayout (position) {
    const activeDynamicLayout = this.state.activeDynamicLayout
    const dynamicWidgets = this.getDynamicWidgets()

    return this.dynamicWidgetsLayout[activeDynamicLayout][position].map((widgetId, i) => {
      const widgetComponent = dynamicWidgets[widgetId]()

      if (this.props.serverSideWidgets && !this.props.serverSideWidgets[widgetId.toLowerCase()]) {
        return null
      }

      const widgetPosition = `${i + 1} (${position})`
      return (
        <ErrorBoundaryComponent widgetPosition={widgetPosition} key={widgetId} widgetName={widgetId}>
          {
            React.cloneElement(
              widgetComponent,
              this.state
            )
          }
        </ErrorBoundaryComponent>
      )
    })
  }

  renderTop () {
    return this.renderDynamicWidgetsLayout(LAYOUT_SECTIONS.TOP)
  }

  renderHeader () {
    return this.renderDynamicWidgetsLayout(LAYOUT_SECTIONS.HEADER)
  }

  renderOverContent () {
    return this.renderDynamicWidgetsLayout(LAYOUT_SECTIONS.OVER_CONTENT)
  }

  renderLeftColumn () {
    return this.renderDynamicWidgetsLayout(LAYOUT_SECTIONS.LEFT_COLUMN)
  }

  renderRightColumn () {
    return this.renderDynamicWidgetsLayout(LAYOUT_SECTIONS.RIGHT_COLUMN)
  }

  renderBottom () {
    return this.renderDynamicWidgetsLayout(LAYOUT_SECTIONS.BOTTOM)
  }

  renderOutsideContent () {
    return this.renderDynamicWidgetsLayout(LAYOUT_SECTIONS.OUTSIDE_CONTENT)
  }

  render () {
    return this.props.children
  }
}

LayoutHeadless.propTypes = {
  widgets: PropTypes.object.isRequired,
  layout: PropTypes.string.isRequired,
  clientSide: PropTypes.bool,
  dynamicLayoutCookie: PropTypes.string
}

LayoutHeadless.defaultProps = {
  dynamicLayoutCookie: detectDevice()
}

export default LayoutHeadless
