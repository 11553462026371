import styled from 'styled-components'

export default styled.div`
  cursor: pointer;
  padding: 0 8px;
  line-height: 24px;
  &.active {
    background: #f0f0f0;
    color: ${props => props.theme.header.search.form.autocomplete.color};
  }
`
