import React from 'react'
import NewsFeedItemContainer from './StyledNewsFeedItemContainer'
import NewsFeedLink from './StyledNewsFeedLink'
import NewsFeedImage from './StyledNewsFeedImage'
import NewsFeedTitle from './StyledNewsFeedTitle'
import NewsFeedPerex from './StyledNewsFeedPerex'
import { DEVICES } from '../../../constants'
import { trimToFullWordsString } from '../../../../lib/utils/string'
import { NewsFeedVideoImage } from './NewsFeedImageWithDuration'

const ITEM_TITLE_MAX_LENGTH = 150

const NewsFeedItem = (props) => {
  const {
    guid,
    link,
    title,
    perex,
    image,
    children,
    handleAnchorClick = () => {},
    layout,
    modal,
    duration,
    featuredType
  } = props

  return (
    <NewsFeedItemContainer key={guid}>
      <NewsFeedLink
        href={link}
        onClick={event => {
          handleAnchorClick(event, link, modal)
        }}
      >
        {modal && duration
          ? <NewsFeedVideoImage src={image} alt={title} duration={duration} featuredType={featuredType} />
          : <NewsFeedImage src={image} alt={title}/>}
        <NewsFeedTitle>
          {layout === DEVICES.DESKTOP ? trimToFullWordsString(title, ITEM_TITLE_MAX_LENGTH) : title}
        </NewsFeedTitle>
        {
          layout === DEVICES.DESKTOP && <NewsFeedPerex>
            {perex}
          </NewsFeedPerex>
        }
        {
          children
        }
      </NewsFeedLink>
    </NewsFeedItemContainer>
  )
}

export default NewsFeedItem
