import styled from 'styled-components'
import { SIZES } from '../../../constants'
import Link from '../../utils/Link'

export default styled(Link)`
  color: #333;
  display: flex;
  flex-basis: 128px;
  flex-direction: column;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  height: 100%;
  flex-flow: wrap;
  align-content: stretch;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
  &:hover .title {
    text-decoration: underline;
  }

  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    flex-direction: row;
    -ms-flex-direction: row;
    flex-flow: nowrap;
  }

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    flex-direction: column;
    -ms-flex-direction: column;
    flex-flow: wrap;
    height: 100%;
    align-content: flex-start;
    justify-content: center;
    margin-right: 5px;
    overflow: hidden;

    div {
      max-width: 100%;
      white-space: normal;
    }
  }
`
