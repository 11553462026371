import styled from 'styled-components'
import Link from '../../utils/Link'

export default styled(Link)`
  color: #333;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
