import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import URL from 'url-parse'
import { API_WIDGETDATA_URI } from '../../../constants'
import { ALREADY_USED_ARTICLES_WIDGETS } from '../../../constants/newsFeed'
import WidgetContainer from '../../containers/WidgetContainer'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import NewsFeedItemArticle from './NewsFeedItemArticle'
import LoadMoreButton from './LoadMoreButton'
import request from '../../utils/Request'
import { sashecReloadPosition } from '../../utils/SashecHelpers'
import StyledNewsFeedContainer from './StyledNewsFeedContainer'
import NewsFeedItemPrannotation from './NewsFeedItemPrannotation'

const MAX_NUMBER_OF_XHR_LOADS = 2

export default class NewsFeed extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      boxes: [],
      boxComponents: [],
      numberOfLoads: 0,
      rendering: false,
      isLoading: false
    }

    this.handleLoadMoreButton = this.handleLoadMoreButton.bind(this)
    this.createComponentsFromBoxes = this.createComponentsFromBoxes.bind(this)
    this.createComponentsFromBoxesWithAds = this.createComponentsFromBoxesWithAds.bind(this)
  }

  componentWillMount () {
    this.initialLoad()
  }

  getBoxesBySource (boxes) {
    const boxesBySource = {}

    boxes.forEach(box => {
      const {
        source
      } = box

      if (typeof boxesBySource[source] === 'undefined') {
        boxesBySource[source] = []
      }

      boxesBySource[source].push(box)
    })

    return boxesBySource
  }

  getUniquelySortedBoxes (boxesBySource) {
    const distributedBoxes = Object.values(boxesBySource)
    const shuffledBoxes = []

    while (distributedBoxes.length > 0) {
      distributedBoxes.forEach((sourceBoxes, index) => {
        shuffledBoxes.push(sourceBoxes.shift())

        if (sourceBoxes.length === 0) {
          distributedBoxes.splice(index, 1)
        }
      })
    }

    return shuffledBoxes
  }

  mapBoxesToComponents (boxes) {
    const {
      widget: {
        imageSize,
        utmMedium,
        abTestCampaign,
        shouldOpenInNewTab
      },
      activeDynamicLayout,
      itemVariant
    } = this.props

    return boxes.map((box, index) => {
      const utmParams = {
        utmCampaign: abTestCampaign || box.source,
        utmMedium,
        utmTerm: `position-${index}`
      }

      const boxWithImageAndUtm = Object.assign({}, box, {
        image: box.images[imageSize],
        gaCategory: new URL(box.link).hostname,
        link: utmLinkGenerator.createLink(box.link, utmParams),
        originalLink: box.link
      })

      return <NewsFeedItemArticle
        box={boxWithImageAndUtm}
        key={box.guid}
        position={index}
        shouldOpenInNewTab={shouldOpenInNewTab}
        layout={activeDynamicLayout}
        itemVariant={itemVariant}
        modal={box.modal}
      />
    })
  }

  insertAdBoxes (newBoxComponents) {
    const { props: {
      widget: {
        ads
      },
      containers,
      activeDynamicLayout
    }, state: {
      boxComponents: existingComponents
    }
    } = this

    const positions = ads[activeDynamicLayout] || ads.default

    const existingAdIds = new Set()
    existingComponents.forEach((component) => {
      if (component.type === NewsFeedItemPrannotation) {
        const {
          id
        } = component.props
        existingAdIds.add(id)
      }
    })

    positions.forEach((ad, index) => {
      const {
        idTemplate,
        config,
        config: {
          position
        }
      } = ad

      const id = `${idTemplate}${index + 1}`

      const allItemsCount = newBoxComponents.length + existingComponents.length

      if (!existingAdIds.has(id) && position <= allItemsCount) {
        const component = <NewsFeedItemPrannotation key={id} id={id} config={config} containers={containers}/>

        const adPosForNewComponents = position - existingComponents.length - 1
        newBoxComponents.splice(adPosForNewComponents, 0, component)
      }
    })
  }

  getAlreadyUsedArticleGuids () {
    const {
      props: {
        widgets
      },
      state: {
        boxes
      }
    } = this
    const usedArticles = boxes.map(box => box.guid)

    Object.values(widgets).forEach(({ name, boxes }) => {
      if (ALREADY_USED_ARTICLES_WIDGETS.indexOf(name) > -1 && boxes) {
        boxes.forEach(({ guid }) => {
          usedArticles.push(guid)
        })
      }
    })

    return usedArticles
  }

  sendGetArticlesRequest (usedArticles) {
    return new Promise((resolve, reject) => {
      const {
        widget: {
          boxesConfigXHR: boxesConfig,
          imageSize,
          imageCount,
          name,
          shuffleBoxes
        }
      } = this.props

      const requestConfig = {
        url: API_WIDGETDATA_URI,
        method: 'post',
        data: {
          usedArticles,
          widgetConfig: {
            [name]: {
              boxesConfig,
              imageSize,
              imageCount,
              name,
              shuffleBoxes
            }
          }
        }
      }

      request(requestConfig).then(response => {
        const {
          [name]: {
            boxes
          }
        } = response.data

        return resolve(boxes)
      }).catch(reject)
    })
  }

  createComponentsFromBoxes (boxes) {
    const newBoxesBySource = this.getBoxesBySource(boxes)
    const newSortedBoxes = this.getUniquelySortedBoxes(newBoxesBySource)
    return this.mapBoxesToComponents(newSortedBoxes)
  }

  createComponentsFromBoxesWithAds (boxes, ads) {
    const components = this.createComponentsFromBoxes(boxes)

    if (ads) {
      this.insertAdBoxes(components)
    }

    return components
  }

  reloadHalfPage () {
    const {
      widget: {
        ads: {
          halfPageLoadMore: {
            pos,
            targetId
          }
        }
      }
    } = this.props

    sashecReloadPosition(targetId, pos)

    if (typeof (Event) === 'function') {
      window.dispatchEvent(new Event('scroll'))
    }
  }

  loadArticles (createComponentsFunction) {
    return new Promise(resolve => {
      const {
        props: {
          widget: {
            ads
          }
        }
      } = this
      this.setState({
        isLoading: true
      })

      const usedArticles = this.getAlreadyUsedArticleGuids()

      this.sendGetArticlesRequest(usedArticles).then(boxes => {
        const components = createComponentsFunction(boxes, ads)

        this.setState((state) => ({
          isLoading: false,
          boxes: state.boxes.concat(boxes),
          boxComponents: state.boxComponents.concat(components),
          numberOfLoads: state.numberOfLoads + 1
        }))

        return resolve()
      }).catch(() => this.setState({ isLoading: false }))
    })
  }

  initialLoad () {
    this.loadArticles(this.createComponentsFromBoxesWithAds)
  }

  handleLoadMoreButton () {
    this.loadArticles(this.createComponentsFromBoxesWithAds).then(() => {
      this.reloadHalfPage()
    })
  }

  render () {
    const {
      props: {
        widget,
        googleAnalytics
      },
      state: {
        numberOfLoads,
        boxComponents,
        isLoading
      }
    } = this

    const shouldShowLoadMoreButton = numberOfLoads < MAX_NUMBER_OF_XHR_LOADS

    return (
      <StyledNewsFeedContainer id='newsfeed-container'>
        <WidgetContainer googleAnalytics={googleAnalytics} widget={widget} showBottomLine={false}>
          {boxComponents}
          {shouldShowLoadMoreButton &&
            <LoadMoreButton clickHandler={this.handleLoadMoreButton} isLoading={isLoading}/>
          }
        </WidgetContainer>
      </StyledNewsFeedContainer>
    )
  }
}

NewsFeed.propTypes = {
  newsFeedBoxes: PropTypes.array,
  widget: PropTypes.object.isRequired,
  googleAnalytics: PropTypes.object
}
