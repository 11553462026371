import React, { Component } from 'react'
import styled from 'styled-components'
import { SIZES } from '../../constants'

const LeftCol = styled.div`
  min-width: 0;
  width: 100%;

  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    flex: 1 1 730px;
    margin-right: 17px;
    max-width: 730px;
    display: flex;
    flex-direction: column;
  }
`

export default class LeftColumn extends Component {
  render () {
    return (
      <LeftCol>
        { this.props.children }
      </LeftCol>
    )
  }
}
