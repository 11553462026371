import React, { Component } from 'react'
import WidgetContainer from '../../containers/WidgetContainer'
import ImagesSection from '../../containers/ImagesSection'
import LinksSection from '../../containers/LinksSection'
import ImageBoxWrapper from './ImageBoxWrapper'
import ArticleImageBox from '../../boxes/StyledArticleImageBox'
import { sendArticleClick } from '../../utils/ga4Events'

export default class Finance extends Component {
  render () {
    const {
      activeDynamicLayout,
      isOpening,
      widget,
      googleAnalytics,
      widget: {
        ad,
        boxes = [],
        imageSize = '4_3',
        name,
        utmContent,
        utmMedium,
        utmSource
      },
      itemVariant
    } = this.props

    if (!boxes.length) {
      return null
    }

    const textBoxes = [...boxes]
    const headBoxes = textBoxes.splice(0, 2)

    const utmParams = {
      utmContent,
      utmMedium,
      utmSource
    }

    const handleAnchorClick = ({ box, position }) => () =>
      sendArticleClick({
        position,
        id: box.guid,
        url: box.link,
        boxName: widget.name,
        title: box.title,
        variant: itemVariant
      })

    const handleSectionClick = (_e, _location, box, position) =>
      sendArticleClick({
        position,
        url: box.link,
        id: box.guid,
        title: box.title,
        boxName: widget.name,
        variant: itemVariant
      })

    const renderImageBox = (box, index) => <ImageBoxWrapper
      activeDynamicLayout={activeDynamicLayout}
      box={box}
      imageBoxComponent={ArticleImageBox}
      imageSize={imageSize}
      index={index}
      key={`financeBox${index}`}
      utmParams={utmParams}
      handleAnchorClick={handleAnchorClick({ box, position: index + 1 })}
      layout={activeDynamicLayout}
    />

    return (
      <WidgetContainer
        googleAnalytics={googleAnalytics}
        widget={widget}
        isOpening={isOpening}
        activeDynamicLayout={activeDynamicLayout}
      >
        {
          headBoxes.length > 0 &&
          <ImagesSection boxes={headBoxes} renderBox={renderImageBox}/>
        }
        {
          textBoxes.length > 0 &&
          <LinksSection
            ad={ad}
            boxes={textBoxes}
            indexShift={headBoxes.length}
            device={activeDynamicLayout}
            name={name}
            utmParams={utmParams}
            handleAnchorClick={handleSectionClick}
          />
        }
      </WidgetContainer>
    )
  }
}
