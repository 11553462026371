import styled from 'styled-components'

export default styled.div`
  flex: 0 0 128px;
  display: -ms-flexbox;
  -ms-flexbox: 0 0 128px;
  -ms-flex-negative: 1;
  height: 96px;
  margin-right: 10px;
  width: 128px;
`
