import UrlPattern from 'url-pattern'

/**
 * External Service Helper
 */
export default class ExternalService {
  /**
   *
   * @param {Object} proxyOptions
   */
  constructor (proxyOptions) {
    this.proxyOptions = proxyOptions
  }

  getStringifiedServiceUrl (urlPattern, options = {}) {
    const serviceUrlPattern = new UrlPattern(urlPattern)

    return serviceUrlPattern.stringify(options)
  }

  /**
   *
   * @param {string} urlPattern
   * @param {Object} options
   * @return {string}
   */
  getServiceUrl (urlPattern, options = {}) {
    const {
      enabled,
      route
    } = this.proxyOptions

    const stringifiedServiceUrlPattern = this.getStringifiedServiceUrl(urlPattern, options)

    return enabled
      ? `${route}/${encodeURIComponent(stringifiedServiceUrlPattern)}`
      : stringifiedServiceUrlPattern
  }
}
