export const SERVICE_URL_PATTERN = 'https\\://radce.centrum.cz?q=:query&ie=utf-8&oe=utf-8&of=1&cnt=12&db=DEFAULT'

export const SECTION_NAMES = {
  INTERNET: 'internet',
  NAJISTO: 'najisto',
  RECIPES: 'recepty',
  IMAGES: 'obrazky',
  VIDEO: 'video',
  DICTIONARIES: 'slovniky'
}

export const SECTIONS = {
  [SECTION_NAMES.INTERNET]: {
    name: 'internet',
    url: 'https://search.centrum.cz/',
    text: 'Internet',
    inputName: 'q',
    inputPlaceholder: 'Hledat na internetu...',
    params: {
      t: {
        name: 't',
        value: 'a'
      },
      utf: {
        name: 'utf',
        value: 'y'
      },
      utm_content: {
        name: 'utm_content',
        value: 'internet'
      },
      channel_id: {
        name: 'channel_id',
        value: 'hpc-top-box,hpc-top-box-sticky'
      },
      kibitz: {
        name: 'kibitz',
        value: '0'
      },
      'kibitz-db': {
        name: 'kibitz-db',
        value: ''
      }
    }
  },
  [SECTION_NAMES.NAJISTO]: {
    name: 'najisto',
    url: 'https://najisto.centrum.cz/',
    text: 'Firmy',
    inputName: 'what',
    inputPlaceholder: 'Hledat firmu...',
    params: {
      utm_content: {
        name: 'utm_content',
        value: 'firmy'
      }
    }
  },
  [SECTION_NAMES.RECIPES]: {
    name: 'recepty',
    url: 'https://www.vareni.cz/recepty/hledat',
    text: 'Recepty',
    inputName: 'q',
    inputPlaceholder: 'Hledat recept...',
    params: {
      utm_content: {
        name: 'utm_content',
        value: 'recepty'
      }
    }
  },
  [SECTION_NAMES.DICTIONARIES]: {
    name: 'slovniky',
    url: 'http://slovniky.centrum.cz/',
    text: 'Slovníky',
    inputPlaceholder: 'Přeložit slovo...',
    inputName: 'q',
    params: {
      utm_content: {
        name: 'utm_content',
        value: 'slovniky'
      }
    },
    dictionaries: [
      {
        value: '2',
        text: 'česky → anglicky'
      },
      {
        value: '4',
        text: 'česky → německy'
      },
      {
        value: '8',
        text: 'česky → francouzsky'
      },
      {
        value: '10',
        text: 'česky → španělsky'
      },
      {
        value: '6',
        text: 'česky → italsky'
      },
      {
        value: '12',
        text: 'česky → rusky'
      },
      {
        value: '1',
        text: 'anglicky → česky'
      },
      {
        value: '3',
        text: 'německy → česky'
      },
      {
        value: '7',
        text: 'francouzsky → česky'
      },
      {
        value: '9',
        text: 'španělsky → česky'
      },
      {
        value: '5',
        text: 'italsky → česky'
      },
      {
        value: '11',
        text: 'rusky → česky'
      }
    ]
  }
}
