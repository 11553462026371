// @flow
import React from 'react'
import PersistedComponent from '../../PersistedComponent'
import Leaflets from '../'
import type { LeafletsWidgetProps } from '../../Types'

export default class MobileLeaflets extends PersistedComponent<LeafletsWidgetProps> {
  constructor (props: LeafletsWidgetProps) {
    super(props)

    this.persistedKeys.push('isOpen')

    this.state = Object.assign({ isOpen: true }, this.state)
  }

  handleToggleContent = () => {
    const {
      isOpen
    } = this.state

    this.setState({
      isOpen: !isOpen
    })
  }

  render () {
    const {
      props: {
        activeDynamicLayout,
        isOpening,
        widget
      },
      state: {
        isOpen
      }
    } = this

    return (
      <Leaflets
        widget={widget}
        activeDynamicLayout={activeDynamicLayout}
        isOpening={isOpening}
        isOpen={isOpen}
        handleToggleContent={this.handleToggleContent}/>
    )
  }
}
