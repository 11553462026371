// @flow
import React, { PureComponent } from 'react'
import WidgetContainer from '../../containers/WidgetContainer'
import request from '../../utils/Request'
import { API_WIDGETDATA_URI } from '../../../constants'
import Start from './StyledStart'
import Icon from './StyledIcon'
import List from './StyledList'
import Progress from './StyledProgress'
import ProgramLink from './StyledProgramLink'
import MoreLink from '../../utils/MoreLink'
import MoreLinkWrapper from '../../containers/StyledMoreLinkWrapper'
import { TextContainer, ProgressContainer } from './StyledContainers'
import { type TvProgramWidgetProps, type TvProgramWidgetState, type TvProgramLink } from './Types'
import { sendWidgetClick } from '../../utils/ga4Events'
import { getLinkTarget } from '../../utils/getLinkTarget'

declare var STATICS_PATH: string

const RELOAD_INTERVAL_MS = 120000

export default class TvProgram extends PureComponent<TvProgramWidgetProps, TvProgramWidgetState> {
  constructor (props: TvProgramWidgetProps) {
    super(props)

    const {
      widget: {
        boxes = []
      }
    } = props

    this.state = {
      boxes
    }
  }

  refreshingInterval: * = null

  componentDidMount () {
    this.refreshingInterval = setInterval(() => {
      const {
        widget: {
          name,
          boxesConfig
        }
      } = this.props
      const requestConfig = {
        url: API_WIDGETDATA_URI,
        method: 'post',
        data: {
          widgetConfig: {
            [name]: {
              boxesConfig
            }
          }
        }
      }

      request(requestConfig).then(response => {
        this.setState({
          boxes: response.data[name].boxes
        })
      }).catch(() => {})
    }, RELOAD_INTERVAL_MS)
  }

  componentWillUnmount () {
    clearInterval(this.refreshingInterval)
  }

  handleProgramLinkClick = ({ channelName, programName }: TvProgramLink) => () =>
    sendWidgetClick({
      name: this.props.widget.name,
      click: {
        type: 'link',
        value: `${channelName} - ${programName}`
      }
    })

  handleTvProgramClick = () =>
    sendWidgetClick({
      name: this.props.widget.name,
      click: {
        type: 'link',
        value: 'open'
      }
    })

  render () {
    const {
      embedded = false,
      widget,
      googleAnalytics,
      activeDynamicLayout,
      widget: {
        meta: {
          headline: {
            location: headlineLocation = ''
          } = {}
        } = {}
      }
    } = this.props

    const {
      boxes
    } = this.state

    if (!boxes.length) {
      return null
    }

    const linkTarget = getLinkTarget(activeDynamicLayout)

    const widgetContent = (
      <List>
        { boxes.map((box, index) => {
          const {
            channel: {
              icon,
              name
            },
            program
          } = box

          if (!program) {
            return null
          }

          const {
            start,
            title,
            progress,
            location
          } = program

          return (
            <li key={box.guid}>
              <TextContainer>
                <Icon src={`${STATICS_PATH}${icon}`} />
                <Start>
                  { start }
                </Start>
                <ProgramLink
                  onClick={this.handleProgramLinkClick({
                    channelName: name,
                    programName: title
                  })}
                  href={location}
                  target={linkTarget}
                  rel='noopener'
                >
                  { title }
                </ProgramLink>
              </TextContainer>
              <ProgressContainer>
                <Progress width={progress} />
              </ProgressContainer>
            </li>
          )
        })}
      </List>
    )

    if (embedded) {
      const widgetWithMoreLink = Object.assign({}, widget, {
        meta: {
          more: {
            text: 'Celý program',
            location: headlineLocation
          }
        }
      })

      return (
        <React.Fragment>
          { widgetContent }
          <MoreLinkWrapper>
            <MoreLink widget={widgetWithMoreLink}/>
          </MoreLinkWrapper>
        </React.Fragment>
      )
    } else {
      return (
        <WidgetContainer handleHeadlineClick={this.handleTvProgramClick} googleAnalytics={googleAnalytics} widget={widget}>
          { widgetContent }
        </WidgetContainer>
      )
    }
  }
}
