import { PureComponent } from 'react'
import Settings from '../utils/Settings'

class PersistedComponent extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {}

    this.persistedKeys = []
  }

  componentWillMount () {
    const settings = this.getPersistedSettings()
    this.setState(settings)
  }

  getPersistedSettings () {
    const {
      props: {
        widget: {
          name
        }
      }
    } = this

    return Settings.getKey(name, {})
  }

  componentDidUpdate () {
    this.setPersistedSettings()
  }

  setPersistedSettings () {
    const {
      props: {
        widget: {
          name
        }
      },
      persistedKeys,
      state
    } = this

    const persistedState = {}

    persistedKeys.forEach(stateKey => {
      persistedState[stateKey] = state[stateKey]
    })

    Settings.setKey(name, persistedState)
  }
}

export default PersistedComponent
