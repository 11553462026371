import React from 'react'
import styled from 'styled-components'

const NewLabelText = styled.div`
  background: ${props => props.theme.regional.newLabelBackground};
  border: 1px solid ${props => props.theme.regional.newLabelBorder};
  border-radius: 4px;
  bottom: 3.5px;
  color: ${props => props.theme.regional.color};
  font-size: 10px;
  font-weight: bold;
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  font-family: Arial,lucida,helvetica,sans-serif;
  margin-top: -5px;
  z-index: 20;
`

export const NewLabelMobile = () => <NewLabelText>nové</NewLabelText>
