import styled from 'styled-components'
import { SIZES } from '../../../constants'

export const SIZE = 48

export const StyledAvatarContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  line-height: 24;
  width: 30px;
  height: 30px;
  color: ${({ theme }) => theme.headerLogin.avatar.color};
  border: 1px solid ${({ theme }) => theme.headerLogin.avatar.borderColor};
  gap: 2px;
  
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    width: ${SIZE}px;
    height: ${SIZE}px;
    gap: 0;
    margin-top: 0;
    font-size: 18px;
    cursor: pointer;
    color: ${({ theme }) => theme.headerLogin.avatar.color};
    border: 1px solid ${({ theme }) => theme.headerLogin.avatar.borderColor};

    &:hover {
      border: 1px solid ${({ theme }) => theme.headerLogin.icon.hover.color};
      color: ${({ theme }) => theme.headerLogin.icon.hover.color};

      path {
        fill: ${({ theme }) => theme.headerLogin.icon.hover.color};
      }

      > * {
        cursor: pointer;
      }
    }
  }
`
