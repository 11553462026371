class Container {
  constructor () {
    this.state = {}
    this.subscribers = {}
  }

  subscribe (key, subscriber) {
    subscriber.setState(this.state)
    this.subscribers[key] = subscriber
  }

  unsubscribe (key) {
    delete this.subscribers[key]
  }

  setState (state) {
    this.state = Object.assign({}, this.state, state)
    Object.values(this.subscribers).forEach(subscriber => subscriber.setState(this.state))
  }
}

export default Container
