import styled from 'styled-components'
import { SIZES, SOURCE_IMAGES } from '../../../constants'

export default styled.span`
  font-size: 14px;
  float: none;
  margin-top: 0;
  margin-left: 160px;
  display: block;
  width: 100%;
  border-bottom: 1px solid #eee;

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    font-size: 12px;
    float: left;
    margin-top: -10px;
    margin-left: 0;
  }

  &::before {
    content: " ";
    display: inline-block;
    width: 14px;
    height: 14px;
    background: url(${UNVERSIONED_STATICS_PATH}images/newsfeed/${props => SOURCE_IMAGES[props.source]}) center / 14px no-repeat;
    margin-right: 7px;
    margin-bottom: -2px;
  }
`
