import styled from 'styled-components'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import { SIZES } from '../../constants'

export const RegularCol = styled(Col)`
  display: -ms-flexbox;
  flex: 1 1 0%;
  padding: 0;
`

export const VerticalCol = styled(RegularCol)`
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    flex: 1 1 128px;
    max-width: 128px;
  }
  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    flex: 1 1 100%;
  
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`

export const RegularRow = styled(Row)`
  display: -ms-flexbox;
  margin: 0;

  @media screen and (max-width: 800px) and (-ms-high-contrast: active), screen and (max-width: 800px) and (-ms-high-contrast: none) {
    display: block !important;
  }
`

export const ImageRow = styled(RegularRow)`
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    margin-bottom: 15px;
  }
`

export const VerticalRow = styled(RegularRow)`
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    justify-content: space-between;
  }
`
