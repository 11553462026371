import styled from 'styled-components'
import Col from 'reactstrap/lib/Col'
import { SIZES } from '../../../constants'

export default styled(Col)`
  padding: 0;
  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    display: -ms-flexbox;
    flex: 1 1;
    -ms-flexbox: 1 1;
    flex-direction: row;
    -ms-flex-direction: row;
  }
  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    margin-bottom: 15px;
  }
  @media (min-width: ${SIZES.BREAKPOINT_XS}px) {
    display: flex;
    display: -ms-flexbox;
    flex-direction: column;
    -ms-flex-direction: column;
  }
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    display: -ms-flexbox;
    flex: 10 1 0;
    -ms-flexbox: 10 1 0;
    max-width: 386px;
    min-width: 0;
  }
  @media screen and (max-width: 800px) and (-ms-high-contrast: active), screen and (max-width: 800px) and (-ms-high-contrast: none) {
    display: inline-block;
  }
`
