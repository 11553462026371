const RTB_LEADERBOARD_CLASS_NAMES = 'inserted_rtb px2_leaderboard'

export const observeRtbLeaderboardHeight = (onChange) => {
  let mutationObserver = null
  let intervalId = null

  const extractHeight = (element) => parseInt(element.offsetHeight)

  const notifyHeightChange = (element) => onChange(extractHeight(element))

  const createMutationObserverForElement = (element) => {
    mutationObserver = new MutationObserver(() => notifyHeightChange(element))
    mutationObserver.observe(element, { attributes: true, attributeFilter: ['style'] })
    notifyHeightChange(element)
  }

  const attemptToFindElementAndObserve = () => {
    const element = document.getElementsByClassName(RTB_LEADERBOARD_CLASS_NAMES)
    if (element.length >= 1) {
      clearInterval(intervalId)
      createMutationObserverForElement(element[0])
    }
  }

  intervalId = setInterval(attemptToFindElementAndObserve, 500)
  attemptToFindElementAndObserve()

  return () => {
    mutationObserver?.disconnect()
    clearInterval(intervalId)
  }
}
