import styled from 'styled-components'

export default styled.button`
  background: ${props => props.theme.exchange.button.background};
  border: 1px solid ${props => props.theme.exchange.button.border};
  border-radius: 3px;
  color: ${props => props.theme.exchange.button.color};
  cursor: pointer;
  font-size: 15px;
  font-weight: bold;
  height: 44px;
  margin-top: 10px;
  text-transform: uppercase;
  transition: none;
  width: 100%;
  &:hover {
    background: ${props => props.theme.exchange.button.hover.background};
    border-color: ${props => props.theme.exchange.button.hover.border};
  }
`
