import styled from 'styled-components'
import Row from 'reactstrap/lib/Row'
import { SIZES } from '../../../constants'

export default styled(Row)`
  margin: 0 0 20px 0;
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    display: flex;
    display: -ms-flexbox;
    align-items: stretch;
    -ms-flex-align: stretch;
  }
  @media screen and (max-width: 800px) and (-ms-high-contrast: active), screen and (max-width: 800px) and (-ms-high-contrast: none) {
    display: block;
  }
  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    flex-direction: column;
    -ms-flex-direction: column;
    margin: 0;
  }
`
