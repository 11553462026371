const moment = require('moment')

const ensureHoursFormat = (durationString) => {
  return moment(durationString, ['HH:mm:ss', 'mm:ss', 'ss']).format('HH:mm:ss')
}

const formatDuration = (durationString) => {
  durationString = ensureHoursFormat(durationString)
  const duration = moment.duration(durationString)

  if (duration.asHours() < 1) {
    return moment.utc(duration.asMilliseconds()).format('m:ss')
  } else {
    return moment.utc(duration.asMilliseconds()).format('H:mm:ss')
  }
}

module.exports = {
  formatDuration
}
