import React from 'react'
import styled from 'styled-components'
import { getVideoFeaturedTypes } from '../../utils/getVideoFeaturedTypes'
import ArticleOverlay from '../../utils/ArticleOverlay'
import { getVideoLabel } from '../../utils/VideoLabel'
import NewsFeedImage, { imageStyles } from './StyledNewsFeedImage'

export const NewsFeedVideoImage = ({ src, alt, duration, featuredType }) => {
  return (
    <StyledContainer>
      <NewsFeedImage src={src} alt={alt} />
      {getVideoFeaturedTypes().includes(featuredType) &&
        <StyledArticleOverlay className='video'>
          { getVideoLabel(featuredType, duration) }
        </StyledArticleOverlay>
      }
    </StyledContainer>
  )
}

const StyledArticleOverlay = styled(ArticleOverlay)`
  &.video {
    left: 0;
    bottom: 0;
  }
`

const StyledContainer = styled.div`
  position: relative;
  ${imageStyles};
  margin-top: 0;
`
