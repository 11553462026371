import React, { PureComponent, Fragment } from 'react'
import styled from 'styled-components'
import { SIZES } from '../../../constants'
import AdSenseValidation from '../AdSenseValidation'

const FooterContent = styled.div`
  background: #fff;
  color: ${props => props.theme.footer.color};
  font-size: 13px;
  margin: 0 auto;
  padding: 30px 8px 15px;
  position: relative;
  width: 100%;
  z-index: 42;
  
  & ul {
    list-style-type: none;
  }
  & li {
    margin-bottom: 6px;
  }
  & a {
    color: ${props => props.theme.footer.colorLink};
    cursor: pointer;
  }
  
  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    padding-top: 0;
  }
`

export default class Footer extends PureComponent {
  render () {
    return (
      <Fragment>
        <FooterContent id={'footer'}>
          { this.props.children }
        </FooterContent>
        <AdSenseValidation/>
      </Fragment>
    )
  }
}
