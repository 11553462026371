import React from 'react'
import PersistedComponent from '../../PersistedComponent'
import Bydleni from '../'

export default class MobileBydleni extends PersistedComponent {
  constructor (props) {
    super(props)
    this.persistedKeys.push('isOpen')
    this.state = Object.assign({ isOpen: true }, this.state)
  }

  handleToggleContent = () => {
    const {
      isOpen
    } = this.state

    this.setState({
      isOpen: !isOpen
    })
  }

  render () {
    const {
      props: {
        activeDynamicLayout,
        isOpening,
        widget
      },
      state: {
        isOpen
      }
    } = this

    const widgetWithMobileName = {
      ...widget,
      name: 'mobileBydleni'
    }

    return (
      <Bydleni
        widget={widgetWithMobileName}
        activeDynamicLayout={activeDynamicLayout}
        isOpening={isOpening}
        isOpen={isOpen}
        handleToggleContent={this.handleToggleContent}/>
    )
  }
}
