// @flow
import qs from 'querystringify'

type UtmParams = {
  utmSource?: string,
  utmMedium?: string,
  utmContent?: string,
  utmTerm?: string,
  utmCampaign?: string
}

class UTMLinkGenerator {
  utmParams: UtmParams

  constructor (utmParams: UtmParams = {}) {
    this.utmParams = utmParams
  }

  /**
   * @param utmParams
   */
  addUtmParams (utmParams: UtmParams = {}) {
    Object.assign(this.utmParams, utmParams)
  }

  /**
   * @param utmParams
   */
  convertUtmToSnake (utmParams: UtmParams = {}): Object {
    const mergedUtmParams = Object.assign({}, this.utmParams, utmParams)
    const {
      utmSource,
      utmMedium,
      utmContent,
      utmTerm,
      utmCampaign
    } = mergedUtmParams

    const utmQueryParams = {}

    if (utmSource) {
      utmQueryParams['utm_source'] = utmSource
    }

    if (utmMedium) {
      utmQueryParams['utm_medium'] = utmMedium
    }

    if (utmContent) {
      utmQueryParams['utm_content'] = utmContent
    }

    if (utmTerm) {
      utmQueryParams['utm_term'] = utmTerm
    }

    if (utmCampaign) {
      utmQueryParams['utm_campaign'] = utmCampaign
    }

    return utmQueryParams
  }

  /**
   * @param link
   * @return {{host: string, query: {}}}
   */
  parseLink (link: string): {host: string, query: Object} {
    const [host, query = ''] = link.split('?')

    return {
      host,
      query: qs.parse(query)
    }
  }

  /**
   * @param host
   * @param query
   * @return {string}
   */
  composeLink (host: string, query: string): string {
    return host + (query ? '?' + query : '')
  }

  /**
   * @param link
   * @param utmParams
   * @param customQuery
   * @return {string}
   */
  createLink (link: string, utmParams: UtmParams = {}, customQuery: Object = {}): string {
    const {
      host,
      query
    } = this.parseLink(link)

    const mergedQuery = qs.stringify(Object.assign(
      query,
      this.convertUtmToSnake(utmParams),
      customQuery
    ))

    return this.composeLink(host, mergedQuery)
  }

  /**
   * @param link
   * @param utmParams
   * @param customQuery
   * @return {string}
   */
  createLinkWithOriginalQuery (link: string, utmParams: UtmParams = {}, customQuery: Object = {}): string {
    const {
      host,
      query
    } = this.parseLink(link)

    const mergedQuery = qs.stringify(Object.assign(
      {},
      this.convertUtmToSnake(utmParams),
      query,
      customQuery
    ))

    return this.composeLink(host, mergedQuery)
  }
}

export default new UTMLinkGenerator()
