import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Spinner from '../../utils/Spinner'

const ButtonSpinner = styled(Spinner)`
  border: 4px solid ${props => props.theme.newsfeed.button.color};
  border-top: 4px solid ${props => props.theme.newsfeed.button.background};
  display: inline-block;
  height: 15px;
  margin: -2px 0 -2px 8px;
  width: 15px;
`

const MoreArrow = styled.span`
  border: solid ${props => props.theme.newsfeed.button.color};
  border-width: 0 2px 2px 0;
  content: ' ';
  display: inline-block;
  margin-left: 15px;
  padding: 3px;
  position: relative;
  top: -3px;
  transform: rotate(45deg);
`

const MoreButton = styled.button`
  background: ${props => props.theme.newsfeed.button.background};
  border: 1px solid ${props => props.theme.newsfeed.button.border};
  border-radius: 3px;
  color: ${props => props.theme.newsfeed.button.color};
  cursor: pointer;
  display: block;
  font-size: 15px;
  font-weight: bold;
  height: 44px;
  margin: 29px auto 0;
  text-transform: uppercase;
  width: 161px;
  
  &:hover, &:active {
    background: ${props => props.theme.newsfeed.button.hover.background};
    border-color: ${props => props.theme.newsfeed.button.hover.border};
  }
  
  &:focus {
    outline: 0;
  }
  
  &:hover ${ButtonSpinner}, &:active ${ButtonSpinner} {
    border-top: 4px solid ${props => props.theme.newsfeed.button.hover.background};
  }
`

class LoadMoreButton extends Component {
  render () {
    const {
      clickHandler,
      isLoading
    } = this.props

    return (
      <MoreButton onClick={clickHandler} disabled={isLoading}>
        Načíst další
        {
          isLoading &&
          <ButtonSpinner/>
        }
        {
          !isLoading &&
          <MoreArrow/>
        }
      </MoreButton>
    )
  }
}

LoadMoreButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default LoadMoreButton
