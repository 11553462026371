// @flow
import * as React from 'react'
import { unreadIndicator } from '../../Email/EmailUtils'
import utmLinkGenerator from '../../../utils/UTMLinkGenerator'
import Loader from '../../../utils/Loader'
import EmailLinkWrapper from './StyledEmailLinkWrapper'
import EmailAddress from './StyledEmailAddress'
import UnreadIndicator from '../../Email/StyledUnreadIndicator'
import TextLink from './StyledTextLink'
import { type InboxType } from '../../Email/Types'
import { type WidgetType } from './Types'

type Props = {|
  inbox: InboxType,
  widget: WidgetType,
  isLoading: boolean,
  handleLogoutClick: Function
|}

export default (props: Props) => {
  const {
    inbox: {
      sender = {},
      foldersummary = {}
    },
    widget: {
      meta: {
        logout,
        inbox: {
          text,
          location = ''
        }
      },
      utmMedium
    },
    isLoading,
    handleLogoutClick
  } = props
  const inboxUtmLink = utmLinkGenerator.createLink(location, { utmMedium })

  return (
    <EmailLinkWrapper>
      {isLoading && <Loader/>}
      <EmailAddress>
        { sender.address }
      </EmailAddress>
      <UnreadIndicator className={foldersummary.unread === 0 ? 'grey' : ''} full>
        {unreadIndicator(foldersummary.unread)}
      </UnreadIndicator>
      <TextLink href={ inboxUtmLink }>
        { text }
      </TextLink>
      <TextLink
        href={ logout.location }
        onClick={event => {
          event.preventDefault()
          handleLogoutClick(event)
        }}>
        { logout.text }
      </TextLink>
    </EmailLinkWrapper>
  )
}
