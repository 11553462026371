import React, { PureComponent, createContext } from 'react'
import { sashecQueuePush } from '../components/utils/SashecHelpers'

const PrannotationContext = createContext([])

const PrannotationConsumer = PrannotationContext.Consumer

class PrannotationProvider extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      positions: []
    }
  }

  componentDidMount () {
    const payload = ['custom', () => {
      window._ecohec.renderPrAnnotation = object => {
        this.setState(state => ({ positions: [...state.positions, object] }))
      }
    }]
    sashecQueuePush(payload)
  }

  render () {
    const {
      props: {
        children
      },
      state: {
        positions
      }
    } = this

    return (
      <PrannotationContext.Provider value={positions}>
        {children}
      </PrannotationContext.Provider>
    )
  }
}

export {
  PrannotationProvider,
  PrannotationConsumer
}
