import ArticleBox from './ArticleBox'
import styled from 'styled-components'
import { SIZES } from '../../constants'

export default styled(ArticleBox)`
  color: #333;
  display: flex;
  display: -ms-flexbox;
  flex: 1 1 0%;
  max-width: 100%;
  overflow: hidden;
  position: relative;

  & .image-column {
    height: auto;
    position: relative;
    & span.video {
      height: 19px;
      font-size: 10px;
      line-height: 17px;
      padding: 0 7px 0 17px;

      &::before {
      background-size: 8px 10px;
      height: 10px;
      width: 8px;
      left: 5px;
      top: 4px;
      }
    }
  }
  & .title-column {
    font-size: 15px;
    flex: 1 1 0;
    -ms-flexbox: 1 1 0;
    -ms-flex-negative: 1;
    line-height: 18px;
    margin-left: 10px;
  }
  &.vertical .title-column {
    font-size: 13px;
    line-height: 18px;
    margin-left: 0;
    margin-top: 5px;
  }

  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    margin-bottom: 10px;
    &.vertical .title-column {
      margin-left: 15px;
    }
  }

  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    &.vertical {
      flex-direction: column;
      -ms-flex-direction: column;
    }
  }
`
