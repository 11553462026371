import styled from 'styled-components'
import { SIZES } from '../../../../constants'

export default styled.div`
  float: left;
  width: calc(100% - 95px);
  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    width: calc(100% - 40px);
  }
`
