import React, { PureComponent } from 'react'

export default class ExternalScripts extends PureComponent {
  componentDidMount () {
    const {
      widget: {
        sources
      }
    } = this.props

    sources.forEach(src => {
      const script = document.createElement('script')
      script.src = src
      this.container.appendChild(script)
    })
  }

  render () {
    if (!this.props.widget) return null

    const {
      widget: {
        name,
        sources
      }
    } = this.props

    return (
      <div id={name} ref={container => { this.container = container }}>
        { typeof window === 'undefined' &&
          sources.map((src, index) => (<script src={src} key={index}/>))
        }
      </div>
    )
  }
}
