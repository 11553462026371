import styled from 'styled-components'

export default styled.div`
  width: ${props => props.width}%;
  height: 2px;
  background: #00457d;
  position: relative;

  &::after {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    background: #00457d;
    border-radius: 3px;
    position: absolute;
    top: -2px;
    right: 0;
  }
`
