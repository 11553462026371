import React from 'react'
import styled from 'styled-components'
import TinyWeather from './Weather/TinyWeather'
import TinyCalendar from './Calendar/TinyCalendar'

const Wrapper = styled.div`
  font-size: 12px;
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  display: -ms-flexbox;
  & .widget-tiny-actual__link {
    font-size: 12px;
  }
`

function DailyInfoMobile () {
  return (
    <Wrapper>
      <TinyCalendar/>
    </Wrapper>
  )
}

function DailyInfoDesktop (props) {
  const {
    activeDynamicLayout,
    widgets: {
      weather
    }
  } = props

  return (
    <Wrapper>
      <TinyWeather widget={weather}/>
      <TinyCalendar layout={activeDynamicLayout}/>
    </Wrapper>
  )
}

export {
  DailyInfoMobile,
  DailyInfoDesktop
}
