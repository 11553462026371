import styled from 'styled-components'
import ArticleBox from './ArticleBox'

export default styled(ArticleBox)`
  border-top: 1px solid #d6d6d6;
  color: #333;
  display: flex;
  display: -ms-flexbox;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  padding: 10px 0;
  width: 100%;

  &:first-child {
    border-top: 0;
    padding-top: 0;
  }

  & .image-column {
    height: 62px;
    width: 82px;
    position: relative;
  }

  & .image-column img {
    height: 62px;
    width: 82px;
  }

  & .title-column {
    font-size: 14px;
    flex: 1 1 0%;
    -ms-flexbox: 1 1 0%;
    -ms-flex-negative: 1;
    line-height: 18px;
    margin-left: 10px;
    padding-right: 10px;
  }
`
