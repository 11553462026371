import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { SIZES } from '../../../constants'

const WeatherContainer = styled.div`
  border-right: 1px solid #ccc;
  padding-right: 12px;
  margin-right: 12px;

  & > .widget-tiny-actual, .widget-tiny-actual-atlas, .widget-tiny-actual-volny {
    display: inline-block !important;
  }
  
  @media (max-width: ${SIZES.BREAKPOINT_XS}px){
    display: none;
  }
  
`

export default class TinyWeather extends PureComponent {
  render () {
    const {
      widget: {
        meta: {
          container: {
            id
          }
        }
      }
    } = this.props

    return (
      <WeatherContainer id={`widget_tiny_actual${id}_container`}/>
    )
  }
}
