import React from 'react'
import VerticalBox from './VerticalBox'
import SimpleImage from './images/SimpleImage'
import SimpleTitle from './titles/SimpleTitle'
import type { GamesBoxProps } from './Types'

export default (props: GamesBoxProps) => {
  const {
    image,
    imageClass,
    link,
    title,
    handleAnchorClick,
    position,
    layout
  } = props

  return (
    <VerticalBox
      image={<SimpleImage className={imageClass} src={image}/>}
      title={<SimpleTitle title={title}/>}
      link={link}
      handleAnchorClick={handleAnchorClick}
      position={position}
      layout={layout}
    />
  )
}
