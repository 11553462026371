/* @flow */
import React, { type Element } from 'react'
import type { BoxType as Box } from '../Types'
import { ImageRow } from '../utils/Grid'

type ImagesSectionProps = {|
  boxes: Array<Box>,
  renderBox: (box: Box, index: number) => Element<*>
|}

export default (props: ImagesSectionProps) => {
  const {
    boxes,
    renderBox
  } = props

  return (
    <ImageRow>
      {
        boxes.map(renderBox)
      }
    </ImageRow>
  )
}
