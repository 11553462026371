import styled from 'styled-components'
import Link from '../../../utils/Link'

const MessageLink = styled(Link)`
  display: block;
  padding: 10px 8px;
  overflow: hidden;
  border: 1px solid ${props => props.theme.email.full.border};
  border-top: 0;
  background: #fff;
  
  &:nth-child(2) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    border-top: 1px solid ${props => props.theme.email.full.border};
  }
  
  &:last-child {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  &:hover {
    text-decoration: none;
  }
`

const SenderContainer = styled.div`
  color: #333;
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`

const Sender = styled.div`
  font-weight: ${props => props.read ? 'normal' : 'bold'};
  white-space: nowrap;
  padding-right: 10px;
  background: ${props => props.attachment > 0 ? `url('${STATICS_PATH}images/email/clip-icon.png') top right no-repeat` : ''};
  ${MessageLink}:hover & {
    color: #444;
  }
`

const MessageDate = styled.div`
  font-size: 12px;
  color: #949494;
  ${MessageLink}:hover & {
    color: #333;
  }
`

const Subject = styled.div`
  color: #333;
  font-weight: ${props => props.read ? 'normal' : 'bold'};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px;
`

export { MessageDate, MessageLink, Sender, SenderContainer, Subject }
