import React from 'react'
import PersistedComponent from '../PersistedComponent'
import { RegularCol, RegularRow } from '../../utils/Grid'

export default class PromoVideo extends PersistedComponent {
  constructor (props) {
    super(props)

    this.persistedKeys = ['lastDisplayed', 'promoVideoCounter']

    this.state = Object.assign({
      lastDisplayed: 0,
      promoVideoCounter: 0
    }, this.state)

    this.incrementCounter = this.incrementCounter.bind(this)
    this.updateWindowPromoVideoCounter = this.updateWindowPromoVideoCounter.bind(this)
  }

  componentDidMount () {
    if (this.shouldReset()) {
      this.setState({
        lastDisplayed: Date.now(),
        promoVideoCounter: 0
      }, this.updateWindowPromoVideoCounter)
    }

    if (window) {
      this.updateWindowPromoVideoCounter()
      window.incrementPromoVideoCounter = this.incrementCounter
    }
  }

  shouldReset () {
    return Date.now() - this.state.lastDisplayed > (15 * 60 * 1000)
  }

  incrementCounter () {
    this.setState(
      oldState => ({ promoVideoCounter: oldState.promoVideoCounter + 1 }),
      this.updateWindowPromoVideoCounter
    )
  }

  updateWindowPromoVideoCounter () {
    window.promoVideoCounter = this.state.promoVideoCounter
  }

  render () {
    return (
      <RegularRow>
        <RegularCol>
          <div>
            <div id={this.props.widget.name}/>
          </div>
        </RegularCol>
      </RegularRow>
    )
  }
}
