/* @flow */
import * as React from 'react'
import { unreadIndicator } from '../../Email/EmailUtils'
import EmailLink from './StyledEmailLink'
import UnreadIndicator from '../../Email/StyledUnreadIndicator'
import { type InboxType } from '../../Email/Types'
import { type WidgetType } from './Types'

type Props = {|
  inbox: InboxType,
  widget: WidgetType
|}

export default (props: Props) => {
  const {
    inbox: {
      summary
    } = {},
    widget: {
      meta: {
        link: {
          location
        }
      }
    }
  } = props

  return (
    <EmailLink href={location}>
      E-Mail
      {
        summary &&
        <UnreadIndicator className={summary.unread === 0 ? 'grey' : ''}>
          { unreadIndicator(summary.unread) }
        </UnreadIndicator>
      }
    </EmailLink>
  )
}
