import React, { forwardRef, useState } from 'react'
import { UserAvatar } from './UserAvatar'
import { InitialsAvatar } from './InitialsAvatar'
import { DefaultAvatar } from './DefaultAvatar'
import { SIZE } from '../../../../ui/Avatar/StyledAvatar'

export const Avatar = forwardRef(({
  userInfo,
  ecoIdentity,
  onClick
}, ref) => {
  const [userAvatarError, setUserAvatarError] = useState(false)

  if (!userInfo) {
    return <div style={{ width: `${SIZE}px` }}/>
  }

  const { firstName = '', lastName = '', avatar = '' } = userInfo

  if (!userAvatarError && avatar !== '') {
    return (
      <UserAvatar
        avatar={avatar}
        ecoIdentity={ecoIdentity}
        onClick={onClick}
        onError={(_) => {
          setUserAvatarError(true)
        }}
        ref={ref}
      />
    )
  }

  if (firstName !== '' || lastName !== '') {
    return (
      <InitialsAvatar
        firstName={firstName}
        lastName={lastName}
        onClick={onClick}
        ref={ref}
      />
    )
  }

  return <DefaultAvatar onClick={onClick} ref={ref} />
})
