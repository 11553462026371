import React, { Component } from 'react'
import WidgetContainer from '../../containers/WidgetContainer'
import LeafletsContainer from './LeafletsContainer'
import type { LeafletsWidgetProps } from '../Types'
import { NewLabelDesktop } from '../../utils/NewLabelDesktop'
import { NewLabelMobile } from '../../utils/NewLabelMobile'
import { DEVICES } from '../../../constants'
import { sendWidgetClick } from '../../utils/ga4Events'

export default class Leaflets extends Component<LeafletsWidgetProps> {
  handleHeadlineClick = () =>
    sendWidgetClick({
      name: this.props.widget.name,
      click: {
        type: 'link',
        value: 'open'
      }
    })

  handleAnchorClick (event, location, name) {
    event.preventDefault()
    sendWidgetClick({
      name: this.props.widget.name,
      click: {
        type: 'link',
        value: `${name} - ${location}`
      }
    })

    const {
      activeDynamicLayout
    } = this.props

    if (activeDynamicLayout === DEVICES.DESKTOP) {
      window.open(location)
      return false
    } else {
      document.location.href = location
    }
  }

  render () {
    const {
      activeDynamicLayout,
      isOpening,
      isOpen,
      handleToggleContent,
      widget,
      googleAnalytics,
      widget: {
        imageSize,
        utmMedium,
        utmSource,
        boxes,
        isNew
      }
    } = this.props

    const newLabel = activeDynamicLayout === DEVICES.MOBILE ? <NewLabelMobile/> : <NewLabelDesktop/>
    const headlineContent = isNew ? newLabel : null

    const utmParams = {
      utmSource,
      utmMedium
    }

    if (!boxes || !boxes.length) return null

    return (
      <WidgetContainer
        widget={widget}
        isOpening={isOpening}
        activeDynamicLayout={activeDynamicLayout}
        isOpen={isOpen}
        handleToggleContent={handleToggleContent}
        headlineContent={headlineContent}
        googleAnalytics={googleAnalytics}
        handleHeadlineClick={this.handleHeadlineClick}
      >
        <LeafletsContainer
          boxes={boxes}
          imageSize={imageSize}
          utmParams={utmParams}
          handleAnchorClick={this.handleAnchorClick.bind(this)}
          activeDynamicLayout={activeDynamicLayout}
        />
      </WidgetContainer>
    )
  }
}
