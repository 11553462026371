/* @flow */
import React from 'react'
import PersistedComponent from '../PersistedComponent'
import MenuItem from './MenuItem'
import WidgetWrapper from './StyledWidgetWrapper'
import { WIDGETS } from '../../../constants'
import Cookie from '../../utils/Cookie'
import { type MobileTabsState, type MobileTabsProps } from './Types'
import TabsWrapper from './StyledTabsWrapper'

export default class MobileTabs extends PersistedComponent<MobileTabsProps, MobileTabsState> {
  constructor (props: MobileTabsProps) {
    super(props)

    this.persistedKeys = [ 'isFirstVisit' ]

    this.state = {
      selectedWidget: ''
    }

    this.handleWidgetSelection = this.handleWidgetSelection.bind(this)
  }

  handleWidgetSelection: (widget: string) => void
  handleWidgetSelection (widget: string): void {
    if (widget !== this.state.selectedWidget) {
      this.setState({
        selectedWidget: widget
      })
    } else {
      this.setState({
        selectedWidget: ''
      })
    }
  }

  componentDidMount () {
    const {
      isFirstVisit
    } = this.state
    const cookieHandler = new Cookie()
    const wasLogged = cookieHandler.isSet('uprofile')

    if (typeof isFirstVisit === 'undefined' || wasLogged) {
      this.setState({
        selectedWidget: WIDGETS.HOMEPAGE.EMAIL,
        isFirstVisit: false
      })
    }
  }

  render () {
    const {
      props: {
        children: {
          props: {
            children
          }
        },
        widget: {
          name,
          widgets: menuWidgets
        },
        ecoIdentity,
        variant
      },
      state: {
        selectedWidget
      }
    } = this

    const selectedWidgetElement = children.find(child => child.props.widget.name === selectedWidget.toLowerCase())
    const SelectedWidget = selectedWidgetElement ? React.cloneElement(selectedWidgetElement, { embedded: true }) : null

    return (
      <div id={name}>
        <TabsWrapper>
          {
            menuWidgets
              .map(widget => <MenuItem
                handleWidgetSelection={this.handleWidgetSelection}
                isSelected={widget === selectedWidget}
                key={`menu-item-${widget}`}
                widgetName={widget}
                ecoIdentity={ecoIdentity}
                variant={variant}
              />)
          }
        </TabsWrapper>
        {
          selectedWidget &&
          <WidgetWrapper>
            { SelectedWidget }
          </WidgetWrapper>
        }
      </div>
    )
  }
}
