import axios from 'axios'

const defaultConfig = {
  method: 'get',
  timeout: 2000
}

const mergeConfigs = (config) => Object.assign({}, defaultConfig, config)

function request (config) {
  const resultConfig = mergeConfigs(config)

  return axios(resultConfig)
}

export default request
