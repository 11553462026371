import React from 'react'
import PersistedComponent from '../../PersistedComponent'
import MobileArticleImageBox from '../../../boxes/StyledMobileArticleImageBox'
import WidgetContainer from '../../../containers/WidgetContainer'
import { ImageRow } from '../../../utils/Grid'
import ImageBoxWrapper from '../ImageBoxWrapper'
import MobileFirstImageBox from './StyledMobileFirstImageBox'
import MobileBoxesGenerator from '../../../utils/MobileBoxesGenerator'
import { type VideoWidgetProps } from '../Types'
import { sendArticleClick } from '../../../utils/ga4Events'

export default class MobileVideo extends PersistedComponent {
  constructor (props: VideoWidgetProps) {
    super(props)

    this.persistedKeys.push('isOpen')

    this.state = Object.assign({ isOpen: true }, this.state)
  }

  handleToggleContent () {
    const {
      isOpen
    } = this.state

    this.setState({
      isOpen: !isOpen
    })
  }

  render () {
    const {
      props: {
        activeDynamicLayout,
        isOpening,
        widget,
        googleAnalytics,
        widget: {
          ad,
          boxes = [],
          imageSize,
          name,
          utmContent,
          utmMedium,
          utmSource
        },
        itemVariant
      },
      state: {
        isOpen
      }
    } = this

    if (!boxes || !boxes.length) {
      return null
    }

    const [
      firstBox,
      ...smallBoxes
    ] = boxes

    const utmParams = {
      utmContent,
      utmMedium,
      utmSource
    }

    const handleAnchorClick = ({ box, position }) => () =>
      sendArticleClick({
        position,
        url: box.link,
        id: box.guid,
        title: box.title,
        boxName: 'video',
        variant: itemVariant
      })

    const renderSmallBox = (box, index) => <ImageBoxWrapper
      box={box}
      imageBoxComponent={MobileArticleImageBox}
      imageSize={imageSize}
      index={index + 1}
      position={index + 1}
      key={`smallMobileVideoBox${index + 1}`}
      utmParams={utmParams}
      handleAnchorClick={handleAnchorClick({ box, position: index + 2 })}
    />

    return (
      <WidgetContainer
        activeDynamicLayout={activeDynamicLayout}
        handleToggleContent={this.handleToggleContent.bind(this)}
        isOpen={isOpen}
        isOpening={isOpening}
        widget={widget}
        googleAnalytics={googleAnalytics}
      >
        <ImageBoxWrapper
          box={firstBox}
          imageBoxComponent={MobileFirstImageBox}
          imageSize='16_9L'
          index={0}
          utmParams={utmParams}
          handleAnchorClick={handleAnchorClick({ box: firstBox, position: 1 })}
        />
        <ImageRow>
          <MobileBoxesGenerator
            ad={ad}
            boxes={smallBoxes}
            device={activeDynamicLayout}
            name={name}
            renderImageBox={renderSmallBox}
          />
        </ImageRow>
      </WidgetContainer>
    )
  }
}
