/* @flow */
import { type InboxType } from './Types'

const METHOD_FULL = 'full'
const MAX_SHOW_UNREAD = 99

export function getUnreadData (count: number) {
  const unreadMessagesCountString = unreadIndicator(count)
  const unreadFormatType = getUnreadFormatType(count)

  return {
    unreadMessagesCountString,
    unreadFormatType
  }
}

function getUnreadFormatType (count: number): 'zero' | 'normal' | 'overMaxShown' {
  if (count === 0) {
    return 'zero'
  }

  if (count <= MAX_SHOW_UNREAD) {
    return 'normal'
  }

  return 'overMaxShown'
}

/**
 * @param count
 * @return {string}
 */
export function unreadIndicator (count: number): string {
  return count > MAX_SHOW_UNREAD ? `${MAX_SHOW_UNREAD}+` : String(count)
}

/**
 * @param inbox
 * @return {boolean}
 */
export function isResponseFull (inbox: InboxType): boolean {
  const {
    reply: {
      method
    } = {}
  } = inbox

  return (method === METHOD_FULL)
}

/**
 * @param count
 * @return {string}
 */
export function unreadMessagesPlural (count: number = 0): string {
  let message = 'nepřečtené zprávy'

  if (count === 1) {
    message = 'nepřečtenou zprávu'
  } else if (count >= 5 || count === 0) {
    message = 'nepřečtených zpráv'
  }

  return message
}
