import styled from 'styled-components'
import ListBox from './ListBox'
import { SIZES } from '../../constants'

export default styled(ListBox)`
  line-height: 18px;
  padding-left: 13px;
  position: relative;

  &::before {
    background: #333;
    border-radius: 6px;
    content: " ";
    display: block;
    height: 4px;
    left: 0;
    position: absolute;
    top: 7px;
    width: 4px;
  }

  & a {
    color: #1359c3;
    display: block;
    font-size: 14px;
  }

  & .title {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  & .title.label {
    max-width: calc(100% - 65px);

    @media (max-width: 394px){
      max-width: calc(100% - 70px);
    }
  }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    display: block;
  }
  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    margin-bottom: 4px;

    & a, & a .title {
      overflow: unset;
      text-overflow: unset;
      white-space: normal;
    }
  }
`
