/* @flow */
import React, { Component } from 'react'
import WidgetContainer from '../../containers/WidgetContainer'
import ExchangeRates from './ExchangeRates'
import ExchangeForm from './ExchangeForm'
import ExchangeCol from './StyledExchangeCol'
import { RegularRow } from '../../utils/Grid'
import ExchangeWrapper from './StyledExchangeWrapper'
import SpirmonWrapper from '../Advert/SpirMonWrapper'
import {
  EXCHANGE_FORM,
  EXCHANGE_FORMAT_REGEXP,
  DEVICES
} from '../../../constants'
import { getRedirectOptions } from '../../utils/Admon'
import {
  type ExchangeWidgetProps,
  type ExchangeWidgetState,
  type CalculateExchangeRateReturn,
  type FormatExchangeRateReturn
} from './Types'

class Exchange extends Component<ExchangeWidgetProps, ExchangeWidgetState> {
  constructor (props) {
    super(props)

    const {
      widget: {
        boxes
      }
    } = props

    const currencies = {}

    boxes.forEach(({ guid: currency, exchangeRate: rate }) => {
      currencies[currency] = {
        rate,
        currencyAmount: '1.00',
        czkAmount: `${rate}`
      }
    })

    this.state = {
      currencies,
      exchangeForm: {
        activeCurrency: Object.keys(currencies)[0],
        activeOperation: EXCHANGE_FORM.OPERATIONS[0].value,
        amount: '1'
      }
    }

    this.handleCurrencyChange = this.handleCurrencyChange.bind(this)
    this.handleExchangeFormChange = this.handleExchangeFormChange.bind(this)
  }

  calculateExchangeRate (stringValue: string, rate: number, direction: string): CalculateExchangeRateReturn {
    let czkAmount = 0
    let currencyAmount = 0
    const numericValue = parseFloat(stringValue.replace(',', '.'))

    if (direction === EXCHANGE_FORM.OPERATIONS[0].value) {
      czkAmount = !(stringValue === '' || stringValue === '---') ? rate * numericValue : '---'
      currencyAmount = stringValue
    } else if (direction === EXCHANGE_FORM.OPERATIONS[1].value) {
      czkAmount = stringValue
      currencyAmount = !(stringValue === '' || stringValue === '---') ? numericValue / rate : '---'
    }

    return {
      currencyAmount,
      czkAmount
    }
  }

  formatExchangeRate ({ currencyAmount, czkAmount }: CalculateExchangeRateReturn, hasComma: boolean): FormatExchangeRateReturn {
    return {
      currencyAmount: typeof currencyAmount !== 'string' ? this.floatToString(currencyAmount, hasComma) : currencyAmount,
      czkAmount: typeof czkAmount !== 'string' ? this.floatToString(czkAmount, hasComma) : czkAmount
    }
  }

  floatToString (numericValue: number, hasComma: boolean): string {
    let stringValue = numericValue.toFixed(2)

    if (hasComma) {
      stringValue = stringValue.replace('.', ',')
    }

    return stringValue
  }

  handleCurrencyChange: (currency: string, amount: string, direction: string) => void | boolean
  handleCurrencyChange (currency: string, amount: string, direction: string): void | boolean {
    const {
      currencies
    } = this.state

    if (!(new RegExp(EXCHANGE_FORMAT_REGEXP).test(amount) || amount === '' || amount === '---')) {
      return false
    }

    const hasComma = amount.indexOf(',') > -1

    const newAmounts = this.calculateExchangeRate(amount, currencies[currency].rate, direction)
    const formattedAmounts = this.formatExchangeRate(newAmounts, hasComma)
    const newCurrencies = Object.assign({}, currencies, {
      [currency]: Object.assign({}, currencies[currency], formattedAmounts)
    })

    const exchangeForm = {
      activeCurrency: currency,
      activeOperation: direction,
      amount: formattedAmounts.currencyAmount
    }

    this.setState({
      currencies: newCurrencies,
      exchangeForm
    })
  }

  handleExchangeFormChange: (newExchangeFormState: {}) => void
  handleExchangeFormChange (newExchangeFormState: {}): void {
    this.setState({
      exchangeForm: Object.assign({}, this.state.exchangeForm, newExchangeFormState)
    })
  }

  render () {
    const {
      widget,
      widget: {
        meta: {
          admon,
          goIdDeviceList
        } = {}
      },
      activeDynamicLayout,
      googleAnalytics
    } = this.props

    const {
      currencies,
      exchangeForm
    } = this.state

    const redirectOptions = getRedirectOptions(admon, activeDynamicLayout)
    const goId = activeDynamicLayout === DEVICES.DESKTOP ? goIdDeviceList.desktop : goIdDeviceList.mobile

    return (
      <WidgetContainer googleAnalytics={googleAnalytics} widget={widget} activeDynamicLayout={activeDynamicLayout}>
        <RegularRow>
          <ExchangeCol>
            <ExchangeWrapper>
              <ExchangeRates googleAnalytics={googleAnalytics} currencies={currencies} handleCurrencyChange={this.handleCurrencyChange}/>
              <ExchangeForm
                {...exchangeForm}
                goId={goId}
                redirectOptions={redirectOptions}
                handleExchangeFormChange={this.handleExchangeFormChange}
              />
            </ExchangeWrapper>
          </ExchangeCol>
        </RegularRow>
      </WidgetContainer>
    )
  }
}

export default SpirmonWrapper(Exchange)
