export const handleClick = () => {
  if (typeof window !== 'undefined') {
    const expirationDate = new Date()

    expirationDate.setMonth(expirationDate.getMonth() + 6)
    document.cookie = `hpxBeta=true; expires=${expirationDate.toUTCString()}; path=/; domain=.centrum.cz`

    window.location.href = 'https://beta.centrum.cz/'
  }
}
