export const SERVICES_LINKS = [
  {
    text: 'TV program',
    location: 'http://tvprogram.centrum.cz/'
  },
  {
    text: 'Reality',
    location: 'https://realitymix.cz/'
  },
  {
    text: 'Firmy',
    location: 'http://najisto.centrum.cz/'
  },
  {
    text: 'Hry',
    location: 'http://gamescafe.centrum.cz/'
  },
  {
    text: 'Plánovač setkání',
    location: 'https://pujdu.centrum.cz/'
  },
  {
    text: 'Slovníky',
    location: 'http://slovniky.centrum.cz/'
  },
  {
    text: 'Recepty',
    location: 'https://www.vareni.cz/'
  }
]
