import React from 'react'

const InfoIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="white" stroke-dasharray="2 2"/>
    <path d="M10.4659 18V9.27273H12.8864V18H10.4659ZM11.6818 8.14773C11.322 8.14773 11.0133 8.02841 10.7557 7.78977C10.5019 7.54735 10.375 7.25758 10.375 6.92045C10.375 6.58712 10.5019 6.30114 10.7557 6.0625C11.0133 5.82008 11.322 5.69886 11.6818 5.69886C12.0417 5.69886 12.3485 5.82008 12.6023 6.0625C12.8598 6.30114 12.9886 6.58712 12.9886 6.92045C12.9886 7.25758 12.8598 7.54735 12.6023 7.78977C12.3485 8.02841 12.0417 8.14773 11.6818 8.14773Z" fill="white"/>
  </svg>

)

export default InfoIcon
