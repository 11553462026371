// @flow
import React from 'react'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import { type VideoImageBoxWrapperProps } from './Types'

export default (props: VideoImageBoxWrapperProps) => {
  const {
    box: {
      duration,
      featuredSize,
      featuredType,
      guid,
      images,
      link,
      source,
      title
    },
    imageBoxComponent: ImageBoxComponent,
    index,
    imageSize,
    utmParams,
    handleAnchorClick
  } = props

  const image = images[imageSize]

  const utmLinkParams = Object.assign({}, utmParams, {
    utmTerm: `position-${index}`,
    utmCampaign: source
  })

  const utmLink = utmLinkGenerator.createLink(link, utmLinkParams)

  return (
    <ImageBoxComponent
      key={guid}
      duration={duration}
      featuredSize={featuredSize}
      featuredType={featuredType}
      image={image}
      imageClass={`image-${imageSize}`}
      isImage={true}
      link={utmLink}
      title={title}
      handleAnchorClick={handleAnchorClick}
    />
  )
}
