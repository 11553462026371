import styled from 'styled-components'
import { SIZES } from '../../../constants'

export default styled.div`
  flex: 1 1 0%;
  min-height: 188px;
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    max-width: 300px;
  }
`
