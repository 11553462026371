import styled from 'styled-components'
import { SIZES } from '../../../constants'

export default styled.ul`
    padding-left: 0;
    @media only screen and (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
      text-align: center;
      margin: 0 auto;
    }
`
