import { STATE } from '../../../common/queryParams'

const CLIENT_ID_KEY = 'client_id'
const RETURN_URL_KEY = 'redirect_uri'
const POPUP = 'popup'

const urlProtocolHostPortRegex = /^.*?:\/\/[^/]+(:\d+)?/

export const extractOrigin = (location) => {
  const match = location.match(urlProtocolHostPortRegex)

  if (!match) {
    return ''
  }

  return match[0]
}

const buildQueryParams = (clientId, returnUrl) => {
  const queryParams = {
    [CLIENT_ID_KEY]: clientId,
    [RETURN_URL_KEY]: returnUrl,
    [STATE]: window.location.href,
    [POPUP]: 1
  }

  return String(new URLSearchParams(queryParams))
}

export const buildLoginPageURL = ({ clientId, loginLocation }) => {
  const currentOrigin = extractOrigin(window.location.href)
  const returnUrl = `${currentOrigin}/loginDone/`
  return `${loginLocation}?${buildQueryParams(clientId, returnUrl)}`
}

export const buildFallbackLoginPageUrl = ({ clientId, loginLocation }) => {
  const returnUrl = window.location.href
  return `${loginLocation}?${buildQueryParams(clientId, returnUrl)}`
}
