import styled from 'styled-components'
import { SIZES } from '../../../../constants'

const Form = styled.form`
  background: ${props => props.theme.email.summary.background};
  display: flex;
  flex-direction: column;
  display: -ms-flexbox;
  -ms-flex-direction: column;
  padding: ${props => props.embedded ? 0 : '10px'};
  position: relative;
  
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    margin-bottom: 10px;
  }
`

const FormRow = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
`

const LoginRow = styled(FormRow)`
  margin-bottom: 20px;
`

const FormCol = styled.div`
  display: flex;
  display: -ms-flexbox;
  flex: 1 1 auto;
  -ms-flexbox: 1 1 auto;
`

const InputCol = styled(FormCol)`
  flex-wrap: wrap;
  flex: 1 1 0%;
  -ms-flexbox: 1 1 0%;
  margin-right: 5px;
`

const ButtonCol = styled(FormCol)`
  flex: 1 1 90px;
  -ms-flexbox: 1 1 90px;
  max-width: 90px;
`

const StyledEmailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #f2f6f7;
  padding: 12px 31px;
  border-radius: 8px;
  gap: 12px;
`

export { ButtonCol, Form, FormRow, FormCol, InputCol, LoginRow, StyledEmailWrapper }
