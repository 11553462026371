import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { EmailConsumer } from '../../../context/Email'
import UnreadIndicator from '../Email/StyledUnreadIndicator'
import { unreadIndicator } from '../Email/EmailUtils'

const EmailIconWrapper = styled.div`
  position: relative;
`

const StyledEmailIcon = styled.span`
  background: url(${UNVERSIONED_STATICS_PATH}images/mobiletabs/${props => props.theme.name}/email.svg);
  display: block;
  height: 24px;
  margin: 8px auto;
  width: 40px;
`

const IconUnreadIndicator = styled(UnreadIndicator)`
  font-weight: lighter;
  letter-spacing: .8px;
  right: calc(50% - 30px);
  top: -5px;
`

export default class EmailIcon extends PureComponent {
  render () {
    return (
      <EmailConsumer>
        {
          ({ inbox: { summary = {} } }) => {
            return (
              <EmailIconWrapper>
                {
                  Object.keys(summary).length > 0 &&
                  <IconUnreadIndicator className={summary.unread === 0 ? 'grey' : ''}>
                    { unreadIndicator(summary.unread) }
                  </IconUnreadIndicator>
                }
                <StyledEmailIcon/>
              </EmailIconWrapper>
            )
          }
        }
      </EmailConsumer>
    )
  }
}
