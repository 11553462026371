export const WEEKDAYS = [
  'Neděle',
  'Pondělí',
  'Úterý',
  'Středa',
  'Čtvrtek',
  'Pátek',
  'Sobota'
]

export const NAMEDAYS = {
  '02-01': 'Karina',
  '03-01': 'Radmila',
  '04-01': 'Diana',
  '05-01': 'Dalimil',
  '07-01': 'Vilma',
  '08-01': 'Čestmír',
  '09-01': 'Vladan',
  '10-01': 'Břetislav',
  '11-01': 'Bohdana',
  '12-01': 'Pravoslav',
  '13-01': 'Edita',
  '14-01': 'Radovan',
  '15-01': 'Alice',
  '16-01': 'Ctirad',
  '17-01': 'Drahoslav',
  '18-01': 'Vladislav',
  '19-01': 'Doubravka',
  '20-01': 'Ilona',
  '21-01': 'Běla',
  '22-01': 'Slavomír',
  '23-01': 'Zdeněk',
  '24-01': 'Milena',
  '25-01': 'Miloš',
  '26-01': 'Zora',
  '27-01': 'Ingrid',
  '28-01': 'Otýlie',
  '29-01': 'Zdislava',
  '30-01': 'Robin',
  '31-01': 'Marika',
  '01-02': 'Hynek',
  '02-02': 'Nela',
  '03-02': 'Blažej',
  '04-02': 'Jarmila',
  '05-02': 'Dobromila',
  '06-02': 'Vanda',
  '07-02': 'Veronika',
  '08-02': 'Milada',
  '09-02': 'Apolena',
  '10-02': 'Mojmír',
  '11-02': 'Božena',
  '12-02': 'Slavěna',
  '13-02': 'Věnceslav',
  '14-02': 'Valentýn',
  '15-02': 'Jiřina',
  '16-02': 'Ljuba',
  '17-02': 'Miloslava',
  '18-02': 'Gizela',
  '19-02': 'Patrik',
  '20-02': 'Oldřich',
  '21-02': 'Lenka',
  '22-02': 'Petr',
  '23-02': 'Svatopluk',
  '24-02': 'Matěj',
  '25-02': 'Liliana',
  '26-02': 'Dorota',
  '27-02': 'Alexandr',
  '28-02': 'Lumír',
  '29-02': 'Horymír',
  '01-03': 'Bedřich',
  '02-03': 'Anežka',
  '03-03': 'Kamil',
  '04-03': 'Stela',
  '05-03': 'Kazimír',
  '06-03': 'Miroslav',
  '07-03': 'Tomáš',
  '08-03': 'Gabriela',
  '09-03': 'Františka',
  '10-03': 'Viktorie',
  '11-03': 'Anděla',
  '12-03': 'Řehoř',
  '13-03': 'Růžena',
  '14-03': 'Rút',
  '15-03': 'Ida',
  '16-03': 'Herbert',
  '17-03': 'Vlastimil',
  '18-03': 'Eduard',
  '19-03': 'Josef',
  '20-03': 'Světlana',
  '21-03': 'Radek',
  '22-03': 'Leona',
  '23-03': 'Ivona',
  '24-03': 'Gabriel',
  '25-03': 'Marián',
  '26-03': 'Emanuel',
  '27-03': 'Dita',
  '28-03': 'Soňa',
  '29-03': 'Taťána',
  '30-03': 'Arnošt',
  '31-03': 'Kvido',
  '01-04': 'Hugo',
  '02-04': 'Erika',
  '03-04': 'Richard',
  '04-04': 'Ivana',
  '05-04': 'Miroslava',
  '06-04': 'Vendula',
  '07-04': 'Hermína',
  '08-04': 'Ema',
  '09-04': 'Dušan',
  '10-04': 'Darja',
  '11-04': 'Izabela',
  '12-04': 'Julius',
  '13-04': 'Aleš',
  '14-04': 'Vincenc',
  '15-04': 'Anastázie',
  '16-04': 'Irena',
  '17-04': 'Rudolf',
  '18-04': 'Valérie',
  '19-04': 'Rostislav',
  '20-04': 'Marcela',
  '21-04': 'Alexandra',
  '22-04': 'Evženie',
  '23-04': 'Vojtěch',
  '24-04': 'Jiří',
  '25-04': 'Marek',
  '26-04': 'Oto',
  '27-04': 'Jaroslav',
  '28-04': 'Vlastislav',
  '29-04': 'Robert',
  '30-04': 'Blahoslav',
  '02-05': 'Zikmund',
  '03-05': 'Alexej',
  '04-05': 'Květoslav',
  '05-05': 'Klaudie',
  '06-05': 'Radoslav',
  '07-05': 'Stanislav',
  '09-05': 'Ctibor',
  '10-05': 'Blažena',
  '11-05': 'Svatava',
  '12-05': 'Pankrác',
  '13-05': 'Servác',
  '14-05': 'Bonifác',
  '15-05': 'Žofie',
  '16-05': 'Přemysl',
  '17-05': 'Aneta',
  '18-05': 'Nataša',
  '19-05': 'Ivo',
  '20-05': 'Zbyšek',
  '21-05': 'Monika',
  '22-05': 'Emil',
  '23-05': 'Vladimír',
  '24-05': 'Jana',
  '25-05': 'Viola',
  '26-05': 'Filip',
  '27-05': 'Valdemar',
  '28-05': 'Vilém',
  '29-05': 'Maxmilián',
  '30-05': 'Ferdinand',
  '31-05': 'Kamila',
  '01-06': 'Laura',
  '02-06': 'Jarmil',
  '03-06': 'Tamara',
  '04-06': 'Dalibor',
  '05-06': 'Dobroslav',
  '06-06': 'Norbert',
  '07-06': 'Slavoj',
  '08-06': 'Medard',
  '09-06': 'Stanislava',
  '10-06': 'Gita',
  '11-06': 'Bruno',
  '12-06': 'Antonie',
  '13-06': 'Antonín',
  '14-06': 'Roland',
  '15-06': 'Vít',
  '16-06': 'Zbyněk',
  '17-06': 'Adolf',
  '18-06': 'Milan',
  '19-06': 'Leoš',
  '20-06': 'Květa',
  '21-06': 'Alois',
  '22-06': 'Pavla',
  '23-06': 'Zdeňka',
  '24-06': 'Jan',
  '25-06': 'Ivan',
  '26-06': 'Adriana',
  '27-06': 'Ladislav',
  '28-06': 'Lubomír',
  '29-06': 'Petr',
  '30-06': 'Šárka',
  '01-07': 'Jaroslava',
  '02-07': 'Patricie',
  '03-07': 'Radomír',
  '04-07': 'Prokop',
  '07-07': 'Bohuslava',
  '08-07': 'Nora',
  '09-07': 'Drahoslava',
  '10-07': 'Libuše',
  '11-07': 'Olga',
  '12-07': 'Bořek',
  '13-07': 'Markéta',
  '14-07': 'Karolína',
  '15-07': 'Jindřich',
  '16-07': 'Luboš',
  '17-07': 'Martina',
  '18-07': 'Drahomíra',
  '19-07': 'Čeněk',
  '20-07': 'Ilja',
  '21-07': 'Vítězslav',
  '22-07': 'Magdaléna',
  '23-07': 'Libor',
  '24-07': 'Kristýna',
  '25-07': 'Jakub',
  '26-07': 'Anna',
  '27-07': 'Věroslav',
  '28-07': 'Viktor',
  '29-07': 'Marta',
  '30-07': 'Bořivoj',
  '31-07': 'Ignác',
  '01-08': 'Oskar',
  '02-08': 'Gustav',
  '03-08': 'Miluše',
  '04-08': 'Dominik',
  '05-08': 'Kristián',
  '06-08': 'Oldřiška',
  '07-08': 'Lada',
  '08-08': 'Soběslav',
  '09-08': 'Roman',
  '10-08': 'Vavřinec',
  '11-08': 'Zuzana',
  '12-08': 'Klára',
  '13-08': 'Alena',
  '14-08': 'Alan',
  '15-08': 'Hana',
  '16-08': 'Jáchym',
  '17-08': 'Petra',
  '18-08': 'Helena',
  '19-08': 'Ludvík',
  '20-08': 'Bernard',
  '21-08': 'Johana',
  '22-08': 'Bohuslav',
  '23-08': 'Sandra',
  '24-08': 'Bartoloměj',
  '25-08': 'Radim',
  '26-08': 'Luděk',
  '27-08': 'Otakar',
  '28-08': 'Augustýn',
  '29-08': 'Evelína',
  '30-08': 'Vladěna',
  '31-08': 'Pavlína',
  '01-09': 'Linda',
  '02-09': 'Adéla',
  '03-09': 'Bronislav',
  '04-09': 'Jindřiška',
  '05-09': 'Boris',
  '06-09': 'Boleslav',
  '07-09': 'Regína',
  '08-09': 'Mariana',
  '09-09': 'Daniela',
  '10-09': 'Irma',
  '11-09': 'Denisa',
  '12-09': 'Marie',
  '13-09': 'Lubor',
  '14-09': 'Radka',
  '15-09': 'Jolana',
  '16-09': 'Ludmila',
  '17-09': 'Naděžda',
  '18-09': 'Kryštof',
  '19-09': 'Zita',
  '20-09': 'Oleg',
  '21-09': 'Matouš',
  '22-09': 'Darina',
  '23-09': 'Berta',
  '24-09': 'Jaromír',
  '25-09': 'Zlata',
  '26-09': 'Andrea',
  '27-09': 'Jonáš',
  '28-09': 'Václav',
  '29-09': 'Michal',
  '30-09': 'Jeroným',
  '01-10': 'Igor',
  '02-10': 'Olívie',
  '03-10': 'Bohumil',
  '04-10': 'František',
  '05-10': 'Eliška',
  '06-10': 'Hanuš',
  '07-10': 'Justýna',
  '08-10': 'Věra',
  '09-10': 'Štefan',
  '10-10': 'Marina',
  '11-10': 'Andrej',
  '12-10': 'Marcel',
  '13-10': 'Renáta',
  '14-10': 'Agáta',
  '15-10': 'Tereza',
  '16-10': 'Havel',
  '17-10': 'Hedvika',
  '18-10': 'Lukáš',
  '19-10': 'Michaela',
  '20-10': 'Vendelín',
  '21-10': 'Brigita',
  '22-10': 'Sabina',
  '23-10': 'Teodor',
  '24-10': 'Nina',
  '25-10': 'Beáta',
  '26-10': 'Erik',
  '27-10': 'Zoe',
  '29-10': 'Silvie',
  '30-10': 'Tadeáš',
  '31-10': 'Štěpánka',
  '01-11': 'Felix',
  '03-11': 'Hubert',
  '04-11': 'Karel',
  '05-11': 'Miriam',
  '06-11': 'Liběna',
  '07-11': 'Saskie',
  '08-11': 'Bohumír',
  '09-11': 'Bohdan',
  '10-11': 'Evžen',
  '11-11': 'Martin',
  '12-11': 'Benedikt',
  '13-11': 'Tibor',
  '14-11': 'Sáva',
  '15-11': 'Leopold',
  '16-11': 'Otmar',
  '18-11': 'Romana',
  '19-11': 'Alžběta',
  '20-11': 'Nikola',
  '21-11': 'Albert',
  '22-11': 'Cecílie',
  '23-11': 'Klement',
  '24-11': 'Emílie',
  '25-11': 'Kateřina',
  '26-11': 'Artur',
  '27-11': 'Xenie',
  '28-11': 'René',
  '29-11': 'Zina',
  '30-11': 'Ondřej',
  '01-12': 'Iva',
  '02-12': 'Blanka',
  '03-12': 'Svatoslav',
  '04-12': 'Barbora',
  '05-12': 'Jitka',
  '06-12': 'Mikuláš',
  '07-12': 'Benjamín',
  '08-12': 'Květoslava',
  '09-12': 'Vratislav',
  '10-12': 'Julie',
  '11-12': 'Dana',
  '12-12': 'Simona',
  '13-12': 'Lucie',
  '14-12': 'Lýdie',
  '15-12': 'Radana',
  '16-12': 'Albína',
  '17-12': 'Daniel',
  '18-12': 'Miloslav',
  '19-12': 'Ester',
  '20-12': 'Dagmar',
  '21-12': 'Natálie',
  '22-12': 'Šimon',
  '23-12': 'Vlasta',
  '24-12': 'Adam, Eva',
  '26-12': 'Štěpán',
  '27-12': 'Žaneta',
  '28-12': 'Bohumila',
  '29-12': 'Judita',
  '30-12': 'David',
  '31-12': 'Silvestr'
}

export const HOLIDAYS = {
  '01-01': 'je Nový rok',
  '06-01': 'jsou Tři Králové',
  '02-02': 'jsou Hromnice',
  '01-05': 'je Svátek práce',
  '08-05': 'je Den osvobození od fašismu',
  '05-07': 'je Den slovanských věrozvěstů',
  '06-07': 'je Svátek Mistra Jana Husa',
  '28-09': 'je Svátek sv. Václava',
  '28-10': 'je Den vzniku samostatného čs. státu',
  '02-11': 'je Památka zesnulých',
  '17-11': 'je Den boje za svobodu a demokracii',
  '24-12': 'je Štědrý den',
  '25-12': 'je 1. svátek vánoční',
  '26-12': 'je 2. svátek vánoční'
}
