import React, { Component } from 'react'
import styled from 'styled-components'
import { SIZES } from '../../constants'

const RightCol = styled.div`
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    flex: 1 1 300px;
    margin-left: 17px;
    max-width: 300px;
    min-width: 0;
  }
`

export default class RightColumn extends Component {
  render () {
    return (
      <RightCol>
        { this.props.children }
      </RightCol>
    )
  }
}
