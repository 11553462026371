import React, { PureComponent } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  height: 32px;
  margin: 4px auto;
  width: 32px;
  
  & p {
    display: block;
    font-size: 10px;
    line-height: 18px;
    margin: 4px 0 0 0 !important;
    text-spacing: .08px;
  }
`

export default class MobileWeather extends PureComponent {
  componentDidMount () {
    const script = document.createElement('script')
    script.src = 'https://pocasi-moravio.centrum.cz/public/tiny_forecast_mobile.bundle.js'
    this.container.appendChild(script)
  }

  render () {
    return (
      <Container
        id='widget_tiny_actual_mobile_container'
        innerRef={container => { this.container = container }}
      />
    )
  }
}
