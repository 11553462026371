import styled from 'styled-components'
import Col from 'reactstrap/lib/Col'
import { SIZES } from '../../../constants'

export default styled(Col)`
  padding: 0 0 0 10px;
  -ms-flex-direction: column;
  -ms-flex-negative: 1;
  @media (max-width: ${SIZES.BREAKPOINT_XS}px) {
    display: -ms-flexbox;
    flex: 1 1 0%;
    -ms-flexbox: 1 1 0%;
    padding: 0;
  }
  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    margin-bottom: 15px;
  }
  @media screen and (max-width: 800px) and (-ms-high-contrast: active), screen and (max-width: 800px) and (-ms-high-contrast: none) {
    display: inline-block;
  }
`
