import styled from 'styled-components'
import ArticleBox from '../../boxes/ArticleBox'

const getTitleBackgroundColor = props => {
  return props.featuredType && props.featuredType === 'online'
    ? '#c9372c'
    : props.theme.dynamicLead.firstBox.backgroundColor
}

const getTitleBackgroundColorHover = props => {
  return props.featuredType && props.featuredType === 'online'
    ? '#f44336'
    : props.isAbTestVariant
      ? props.theme.dynamicLead.firstBox.backgroundColor
      : props.theme.dynamicLead.firstBox.backgroundColorHover
}

const getTitleColor = props => {
  return props.featuredType && props.featuredType === 'online'
    ? '#fff'
    : props.theme.dynamicLead.firstBox.color
}

export default styled(ArticleBox)`
  display: flex;
  display: -ms-flexbox;
  flex: 1;
  -ms-flexbox: 1;
  position: relative;
  flex-direction: column;
  -ms-flex-direction: column;

  & .image-column {
    display: flex;
    display: -ms-flexbox;
    flex: auto;
    -ms-flexbox: auto;
  }

  & .image-column img {
    max-height: 294px;
    width: 100%;
  }

  & .title-column span {
    background-color: ${props => getTitleBackgroundColor(props)};
    color: ${props => getTitleColor(props)};
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 17px;
    height: 76px;
    line-height: 20px;
    padding: 7px 15px;
  }

  &:hover {
    text-decoration: none;
  }

  &:hover .title-column span {
    background-color: ${props => getTitleBackgroundColorHover(props)};
  }

  & span.video, span.live {
    height: 19px;
    left: 14px;
    bottom: 14px;
    font-size: 10px;
    line-height: 17px;
    padding: 0 7px 0 17px;

    &::before {
    background-size: 8px 10px;
    height: 10px;
    width: 8px;
    left: 5px;
    top: 4px;
    }
  }
`
