import React from 'react'
import { type SimpleTitleProps } from '../Types'

export default (props: SimpleTitleProps) => {
  const {
    className,
    title
  } = props

  return (
    <span className={className}>
      { title }
    </span>
  )
}
