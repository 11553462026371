export const DEFAULT_VISIBILITY = 5

export const VARIANTS = {
  NONE: 'none',
  SMALL: 'small',
  BIG: 'big'
}

export const TIP_OF_THE_DAY_ICONS = {
  tipDefault: 'default.png',
  tipWoman: 'woman.png',
  tipVideo: 'video.png',
  tipRio: 'rio.png',
  tipEuro: 'euro.png',
  tipHockey: 'hockey.png',
  tipIhned: 'ihned.png',
  tipNewsroom: 'newsroom.png'
}
