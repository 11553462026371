import PersistedComponent from '../PersistedComponent'
import { API_WIDGETDATA_URI, DEVICES } from '../../../constants'
import request from '../../utils/Request'
import type { BaseWidgetProps } from '../../Types'
import { sendArticleClick } from '../../utils/ga4Events'

export default class RegionalWidget extends PersistedComponent<BaseWidgetProps> {
  constructor (props: BaseWidgetProps) {
    super(props)

    this.persistedKeys.push('activeRegion')
    this.persistedKeys.push('shouldShow')

    this.state = {
      isRegionPickerOpen: false,
      selectedRegionNews: [],
      shouldShow: true,
      activeRegion: 'ceskaRepublika',
      isLoading: false
    }

    this.handleClickOutside = this.handleClickOutside.bind(this)
    this.removeWidget = this.removeWidget.bind(this)
    this.handleAnchorClick = this.handleAnchorClick.bind(this)
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
    if (this.state.activeRegion !== 'ceskaRepublika') {
      this.loadRegionData(this.state.activeRegion)
    }
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  handleClickOutside: (event: MouseEvent) => void
  handleClickOutside (event: MouseEvent) {
    if (!this.regionsRef.contains(event.target) && !this.regionPickerRef.contains(event.target)) {
      this.setState({
        isRegionPickerOpen: false
      })
    }
  }

  removeWidget: () => void
  removeWidget () {
    this.setState({
      shouldShow: false
    })
  }

  loadRegionData (region: string = 'ceskaRepublika'): void {
    this.setState({
      isLoading: true
    })

    this.sendRequest(region).then(selectedRegionNews => {
      this.setState({
        selectedRegionNews,
        isLoading: false,
        activeRegion: region
      })
    }).catch(() => {
      this.setState({
        isLoading: false
      })
    })
  }

  sendRequest (region: string): Promise<Array<Object>> {
    const {
      widget: {
        name,
        imageCount,
        imageSize,
        boxesConfig
      }
    } = this.props

    const requestConfig = {
      url: `${API_WIDGETDATA_URI}?region=${region}`,
      method: 'post',
      data: {
        widgetConfig: {
          [name]: {
            name,
            imageCount,
            imageSize,
            boxesConfig
          }
        }
      }
    }

    return request(requestConfig).then(response => {
      return response.data[name].boxes || []
    })
  }

  handleRegionChange (newRegion: string): void {
    this.loadRegionData(newRegion)
    this.setState({
      isRegionPickerOpen: false
    })
  }

  handleAnchorClick (event, location, box, position, activeDynamicLayout) {
    event.preventDefault()

    const {
      guid: id,
      title: name,
      link
    } = box

    sendArticleClick({
      id,
      position,
      url: link,
      title: name,
      boxName: 'regiony',
      variant: this.props.itemVariant
    })

    if (activeDynamicLayout === DEVICES.DESKTOP) {
      window.open(location)
      return false
    } else {
      document.location.href = location
    }
  }
}
