const OS_NAMES = {
  WINDOWS: 'Windows',
  ANDROID: 'Android',
  LINUX: 'Linux'
}
const BROWSER_NAMES = {
  IE: 'Internet Explorer'
}

/**
 * System detection util
 */
export default class System {
  constructor () {
    this.isClientSide = typeof window !== 'undefined'
    this.osName = undefined
    this.osMajorVersion = undefined
    this.browserName = undefined
    if (this.isClientSide) {
      const {
        osName,
        osMajorVersion
      } = System.detectOs()
      this.osName = osName
      this.osMajorVersion = osMajorVersion
      this.browserName = System.detectBrowser()
    }
  }

  /**
   * return operating system name
   * @return {string}
   */
  static detectOs () {
    let match
    let osName
    let osMajorVersion
    const isAndroid = !!(match = window.navigator.userAgent.match(/android\s([0-9]+)/i))

    if (isAndroid) {
      osName = OS_NAMES.ANDROID
      if (match[1]) {
        osMajorVersion = match[1]
      }
    } else if (navigator.appVersion.indexOf('Windows NT 6.1') !== -1) {
      osName = OS_NAMES.WINDOWS
      osMajorVersion = '7'
    }

    return {
      osName,
      osMajorVersion
    }
  }

  /**
   * return browser name
   * @return {string}
   */
  static detectBrowser () {
    let browserName
    const hasDocumentMode = !!document.documentMode
    if (hasDocumentMode) {
      browserName = BROWSER_NAMES.IE
    }
    return browserName
  }

  /**
   * @return {boolean}
   */
  isIEOnWin7 () {
    return this.osName === OS_NAMES.WINDOWS && this.osMajorVersion === '7' && this.browserName === BROWSER_NAMES.IE
  }

  /**
   * @return {string|undefined}
   */
  get osName () {
    return this._osName
  }

  /**
   * @param value
   */
  set osName (value) {
    this._osName = value
  }

  /**
   * @return {String.osMajorVersion}
   */
  get osMajorVersion () {
    return this._osMajorVersion
  }

  /**
   * @param {string} value
   */
  set osMajorVersion (value) {
    this._osMajorVersion = value
  }

  /**
   * @return {string|undefined}
   */
  get browserName () {
    return this._browserName
  }

  /**
   * @param value
   */
  set browserName (value) {
    this._browserName = value
  }

  /**
   * @return {boolean}
   */
  get isClientSide () {
    return this._isClientSide
  }

  /**
   * @param {boolean} value
   */
  set isClientSide (value) {
    this._isClientSide = value
  }
}

export {
  OS_NAMES,
  BROWSER_NAMES
}
