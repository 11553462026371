import React from 'react'
import PersistedComponent from '../../PersistedComponent'
import utmLinkGenerator from '../../../utils/UTMLinkGenerator'
import { Label } from './StyledLabel'
import { CloseButton, DownloadLink } from './StyledLinks'
import { Container, Wrapper } from './StyledContainers'
import { SystemContext } from '../../../../context/System'

class Android extends PersistedComponent {
  constructor (props) {
    super(props)

    this.state = Object.assign({}, this.state, {
      apps: {}
    })
    this.persistedKeys = ['apps']
    this.handleClickClose = this.handleClickClose.bind(this)
    this.handleClickInstall = this.handleClickInstall.bind(this)
  }

  componentDidMount () {
    const {
      state: {
        apps
      },
      props: {
        config: {
          minVersion,
          lastUpdate,
          storeAppId,
          maxImpressions,
          showAgainAfterSeconds
        }
      }
    } = this

    if (this.isAndroidVersionSupported(minVersion)) {
      let app = apps[storeAppId]
      if (!app || app.configLastUpdate !== lastUpdate) {
        app = this.newApplicationSettings(lastUpdate)
      }

      if (!app.isInstalled) {
        if (Date.now() - new Date(app.shownDate).getTime() > showAgainAfterSeconds * 1000) {
          app = this.newApplicationSettings(lastUpdate)
        }
        if (!app.isClosed && app.shownTimes < maxImpressions) {
          app.shownTimes++
        } else {
          app.isClosed = true
        }
      }

      const updatedApps = this.updateCurrentApplicationSettings(app)
      this.setState({ apps: updatedApps })
    }
  }

  newApplicationSettings (lastUpdate) {
    return {
      shownTimes: 0,
      shownDate: new Date(),
      isClosed: false,
      isInstalled: false,
      configLastUpdate: lastUpdate
    }
  }

  isAndroidVersionSupported (minimalVersion) {
    const system = this.context
    return system.osMajorVersion >= minimalVersion
  }

  updateCurrentApplicationSettings (values) {
    const {
      props: {
        config: {
          storeAppId
        }
      },
      state: {
        apps
      }
    } = this
    const currentSettings = apps[storeAppId] || {}
    const updatedSettings = Object.assign({}, currentSettings, values)

    return Object.assign({}, apps, { [storeAppId]: updatedSettings })
  }

  handleClickClose (event) {
    event.preventDefault()
    this.setState(() => {
      const apps = this.updateCurrentApplicationSettings({ isClosed: true })
      return {
        apps
      }
    })
  }

  handleClickInstall (event, location) {
    event.preventDefault()
    this.setState(() => {
      const apps = this.updateCurrentApplicationSettings({ isClosed: true, isInstalled: true })
      return {
        apps
      }
    }, () => {
      document.location.href = location
    })
  }

  render () {
    const {
      props: {
        config: {
          storeLink,
          storeAppId
        },
        widget: {
          utmMedium
        }
      },
      state: {
        apps: {
          [storeAppId]: app = {
            isClosed: true
          }
        }
      }
    } = this

    if (app.isClosed) {
      return null
    }
    const utmLink = utmLinkGenerator.createLink(storeLink, {
      utmMedium
    })

    return (
      <Wrapper>
        <Container>
          <CloseButton href={'#'} onClick={this.handleClickClose}>×</CloseButton>
          <img src={`${STATICS_PATH}images/promo/centrum_email.svg`} alt='Centrum email'/>
          <Label>Centrum Mail vždy po ruce. Stáhněte si aplikaci do vašeho mobilu</Label>
          <DownloadLink href={utmLink}
            onClick={event => {
              this.handleClickInstall(event, utmLink)
            }}>
            Stáhnout
          </DownloadLink>
        </Container>
      </Wrapper>
    )
  }
}

Android.contextType = SystemContext
export default Android
