import styled from 'styled-components'

export const imageStyles = `
  height: 86px;
  object-fit: cover;
  overflow: hidden;
  width: 150px;
  float: left;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 4px;
`

export default styled.img`
  ${imageStyles}
`
