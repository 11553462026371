import styled from 'styled-components'

export default styled.li`
  border-bottom: 1px solid #d6d6d6;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 7px;
  display: block;
  
  & a {
    display: flex;
    display: -ms-flexbox;
    -ms-flex-direction: row;
    flex: 1 1 0%;
    overflow: hidden;
    padding-bottom: 7px;
    position: relative;
    color: ${props => props.theme.dynamicLead.smallBox.color};
  }

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  & div {
    position: relative;
    width: 82px;
    height: 62px;

    & .video {
      margin-left: 0;
      left: 0;
      bottom: 0;
      border-radius: 0;

      &:before {
        top: 3px;
      }
    }
  }
  & span {
    margin-left: 10px;
    display: flex;
    display: -ms-flexbox;
    -ms-flex-negative: 1;
    flex: 1 1 0%;
    -ms-flexbox: 1 1 0%;
    text-overflow: ellipsis;
    max-height: 63px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  & span span {
    display: block;
    width: 100%;
    max-height: 62px;
    overflow: hidden;
  }
  &:hover .span {
    color: inherit;
  }
  & img {
    width: 82px;
    height: 62px;
    object-fit: cover;
  }
  @media screen and (max-width: 800px) and (-ms-high-contrast: active), screen and (max-width: 800px) and (-ms-high-contrast: none) {
    border-bottom: 0px;
  }
`
