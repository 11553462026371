import styled from 'styled-components'

export default styled.li`
  color: ${props => props.theme.header.search.sections.color};
  cursor: pointer;
  display: inline-block;
  font-size: 12px;
  line-height: normal;
  padding-bottom: 8px;
  & span {
    padding-bottom: 8px;
    padding-right: 13px;
    padding-left: 13px;
  }
  &.active span, &:hover span {
    border-bottom: 2px solid ${props => props.theme.header.search.sections.activeColor};
    color: ${props => props.theme.header.search.sections.activeColor};
  }
`
