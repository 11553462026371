import { PureComponent } from 'react'
import * as Sentry from '@sentry/browser'
import { WIDGETS } from '../../constants'

const ADVERT_WIDGETS = [
  WIDGETS.COMMON.LEADERBOARD,
  WIDGETS.COMMON.RIGHT_BANNER,
  WIDGETS.COMMON.HALF_PAGE,
  WIDGETS.COMMON.SMART_ADVERT_1,
  WIDGETS.COMMON.SMART_ADVERT_2,
  WIDGETS.COMMON.SMART_ADVERT_3,
  WIDGETS.COMMON.SMART_ADVERT_4
]

export default class SentryWrapper extends PureComponent {
  constructor (props) {
    super(props)
    const {
      config,
      version,
      environment
    } = this.props

    Sentry.init({
      ...config,
      environment,
      release: version,
      beforeSend (event) {
        const {
          tags: {
            widget = null
          } = {}
        } = event
        if (!widget || ADVERT_WIDGETS.includes(widget)) {
          return null
        }
        return event
      }
    })

    window.addEventListener('resize', () => this.setViewportTag())
    this.setViewportTag()
  }

  setViewportTag () {
    Sentry.configureScope((scope) => {
      scope.setTag('viewport', `${window.innerWidth}x${window.innerHeight}`)
      scope.setTag('adblock', !!window.canRunAds)
    })
  }

  render () {
    return this.props.children
  }
}
