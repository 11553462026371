import React from 'react'
import styled from 'styled-components'
import ArticleImageBox from '../../boxes/StyledArticleImageBox'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import { DEVICES } from '../../../constants'

const Container = styled.div`
  padding-right: 45px;
`
const Box = styled.div`
  padding-bottom: 15px;
`

export const Articles = props => {
  const {
    widget: {
      boxes,
      imageSize,
      imageSizeSmall,
      imageSizeMobile,
      utmMedium
    },
    layout
  } = props

  const desktopImageSizes = [imageSize, imageSizeSmall]

  return (
    <Container>
      {
        boxes.map(({ guid, link, images, title }, index) => {
          const utmLink = utmLinkGenerator.createLink(link, { utmMedium })
          const image = layout === DEVICES.DESKTOP ? images[desktopImageSizes[index]] : images[imageSizeMobile]

          return (
            <Box>
              <ArticleImageBox
                key={guid}
                link={utmLink}
                image={image}
                title={title}
                isImage={true}
                layout={layout}
              />
            </Box>
          )
        })
      }
    </Container>
  )
}
