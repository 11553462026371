import * as React from 'react'
import SectionList from './StyledSectionList'
import Section from './StyledSection'
import { SECTIONS } from '../../../../../constants'

type Props = {|
  activeSection: Object,
  onSectionClick: Function
|}

class SectionsMenu extends React.PureComponent<Props> {
  render () {
    const {
      activeSection,
      onSectionClick
    } = this.props

    return (
      <SectionList>
        {
          Object.values(SECTIONS).map(section => {
            return (
              <Section key={section.name} onClick={() => onSectionClick(section.name)}
                className={section.name === activeSection.name ? 'active' : ''}>
                <span>
                  { section.text }
                </span>
              </Section>
            )
          })
        }
      </SectionList>
    )
  }
}

export default SectionsMenu
