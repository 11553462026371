import React from 'react'
import styled from 'styled-components'
import WidgetContainer from '../../containers/WidgetContainer'
import { DEVICES, SIZES } from '../../../constants'
import { NewLabelMobile } from '../../utils/NewLabelMobile'
import { NewLabelDesktop } from '../../utils/NewLabelDesktop'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import { sendWidgetClick } from '../../utils/ga4Events'
import { getLinkTarget } from '../../utils/getLinkTarget'

const ThemeArticle = styled.a`
  color: #333;
  font-weight: 500;
  
  :hover {
    color: ${props => props.theme.button.hover.background};
  }
`

const Articles = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: column;

  a:not(:last-child) {
    margin-bottom: 10px;
  }

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    margin-right: 40px;
    margin-bottom: 10px;
    
    a:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`

const Article = styled.a`
  color: #333;
  display: flex;
  line-height: 16px;
  max-height: 50px;
  
  :hover {
    color: ${props => props.theme.button.hover.background};
  }
`

const ArticleImage = styled.img`
  width: 50px;
  height: 50px;
`

const ArticleTitle = styled.p`
  font-size: 14px;
  margin-left: 10px;
  margin-bottom: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
`

const MoreLink = styled.a`
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  color: ${props => props.theme.moreLink.color};

  :hover {
    color: ${props => props.theme.link.colorHover};
  }
`

const Tags = styled.div`
  display: flex;
  flex-grow: 1;
  margin-top: 15px;
  width: 100%;
  
  span:not(:last-child) {
    margin-right: 9px;
  }

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    width: auto;
  }
`

const Tag = styled.span`
  color: #F44336;
  border: 1px solid #F44336;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  text-align: center;

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    width: auto;
    font-size: 14px;
    padding: 3px 14px;
  }
`

const TagLink = styled.a`
  color: #F44336;

  :hover {
    color: #F44336;
  }
`

export default props => {
  const {
    activeDynamicLayout,
    widget,
    googleAnalytics,
    widget: {
      utmMedium,
      name,
      isNew,
      meta: {
        moreVareni: {
          text: moreText,
          location: moreLocation
        }
      },
      boxes,
      imageSize
    }
  } = props

  if (!boxes || !boxes.length) return null

  const sortedBoxes = boxes.reduce((acc, item) => {
    const category = item.category
    return {
      ...acc,
      [category]: acc[category] ? [...acc[category], item] : [item]
    }
  }, {})

  const newLabel = activeDynamicLayout === DEVICES.MOBILE ? <NewLabelMobile/> : <NewLabelDesktop/>
  const headlineContent = isNew ? newLabel : null

  const {
    claim: [{
      title: themeArticleTitle,
      link: themeArticleLink
    }],
    article: articles,
    tag: tags
  } = sortedBoxes

  const utmParams = {
    utmMedium,
    utmCampaign: 'Vareni'
  }

  const handleArticleClick = ({ link, position }) => () =>
    sendWidgetClick({
      name,
      click: {
        position,
        value: link,
        type: 'link'
      }
    })

  const handleMoreClick = () =>
    sendWidgetClick({
      name,
      click: {
        value: 'more',
        type: 'link'
      }
    })

  const handleTagClick = ({ url, position }) => () =>
    sendWidgetClick({
      name,
      click: {
        position,
        value: url,
        type: 'link'
      }
    })

  const linkTarget = getLinkTarget(activeDynamicLayout)

  return (
    <WidgetContainer googleAnalytics={googleAnalytics} widget={widget} activeDynamicLayout={activeDynamicLayout} headlineContent={headlineContent}>
      <ThemeArticle href={themeArticleLink} target={linkTarget} rel='noopener'>
        {themeArticleTitle}
      </ThemeArticle>
      <Articles>
        {
          articles.map(({ title, link, images }, index) => (
            <Article
              onClick={handleArticleClick({ link, position: index + 1 })}
              href={utmLinkGenerator.createLinkWithOriginalQuery(link, { utmTerm: `position-${index + 1}`, ...utmParams })}
              target={linkTarget}
            >
              <ArticleImage src={images[imageSize]} alt={title}/>
              <ArticleTitle>{title}</ArticleTitle>
            </Article>
          ))
        }
      </Articles>
      <MoreLink onClick={handleMoreClick} href={moreLocation}>
        {moreText}
      </MoreLink>
      {
        tags && !!tags.length &&
        <Tags>
          {
            tags.map(({ title, link }, index) => (
              <Tag>
                <TagLink onClick={handleTagClick({ url: link, position: index + 1 })} href={link}>{title}</TagLink>
              </Tag>
            ))
          }
        </Tags>
      }
    </WidgetContainer>
  )
}
