import { SIZES, DEVICES } from '../../constants'

/**
 * @return {string}
 */
export function detectDevice (): string {
  let device = DEVICES.DESKTOP

  if (typeof window !== 'undefined') {
    if (window.innerWidth < SIZES.BREAKPOINT_XS) {
      device = DEVICES.MOBILE
    } else if (window.innerWidth >= SIZES.BREAKPOINT_XS && window.innerWidth < SIZES.BREAKPOINT_MD) {
      device = DEVICES.TABLET
    }
  }

  return device
}

/**
 * @param device
 * @return {boolean}
 */
export function isValidDevice (device: string): boolean {
  return Object.values(DEVICES).indexOf(device) !== -1
}
