import styled from 'styled-components'
import { SIZES } from '../../../constants'

export default styled.div`
  font-size: 10px;
  font-weight: 500;
  position: absolute;
  right: 0;
  bottom: 6px;
  height: 20px;
  text-align: right;
  width: calc(100% - 160px);
  z-index: inherit;
  border-bottom: 1px solid #eee;

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    bottom: 0;
    width: 100%;
  }
`
