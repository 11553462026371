import React from 'react'
import VerticalBox from './VerticalBox'
import SimpleImage from './images/SimpleImage'
import HousingTitle from './titles/HousingTitle'
import { type HousingBoxProps } from './Types'

export default (props: HousingBoxProps) => {
  const {
    annotation,
    image,
    imageClass,
    link,
    price,
    title,
    handleAnchorClick,
    layout
  } = props

  return (
    <VerticalBox
      image={<SimpleImage className={imageClass} src={image}/>}
      title={<HousingTitle annotation={annotation} price={price} title={title}/>}
      link={link}
      handleAnchorClick={handleAnchorClick}
      layout={layout}
    />
  )
}
