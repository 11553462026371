import { SASHEC_DEVICES } from '../../constants'

export function detectSashecDevice () {
  let fittingDevice

  if (typeof window !== 'undefined') {
    const viewPortWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0)
    fittingDevice = Object.keys(SASHEC_DEVICES).find(sashecDevice => {
      return viewPortWidth >= SASHEC_DEVICES[sashecDevice]
    })
  }

  return fittingDevice
}

export function sashecReloadPosition (targetId, pos, ab = undefined) {
  if (window._sashec) {
    const adPosition = window._sashec.getPositionsByElementId(targetId)[0]
    if (adPosition) {
      let targets = {
        pos: pos
      }
      if (ab) {
        targets = Object.assign(targets, { ab })
      }
      window._sasic_queue.push(['position', {
        elementId: adPosition.elementId,
        groupId: adPosition.groupId,
        options: {
          targets
        }
      }])
      adPosition.load()
    }
  }
}

function isWindow () {
  return typeof window !== 'undefined'
}

export function sashecQueuePush (payload) {
  if (isWindow()) {
    window._sashec_queue = window._sashec_queue || []
    window._sashec_queue.push(payload)
  }
}
