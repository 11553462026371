import React, { PureComponent } from 'react'
import AdSense from 'react-adsense'
import Cookie from '../utils/Cookie'

class AdSenseValidation extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isVisible: false
    }
  }

  componentDidMount () {
    const cookieHandler = new Cookie()
    const forceAdsense = cookieHandler.isSet('forceAdsence')
    this.setState(
      {
        isVisible: forceAdsense
      }
    )
  }

  render () {
    const {
      state: {
        isVisible
      }
    } = this

    if (!isVisible) {
      return null
    }

    return (
      <AdSense.Google
        client='ca-pub-3285327631278021'
        slot='6802897674'
        style={
          {
            display: 'inline-block',
            width: 728,
            height: 90
          }
        }
        format=''
      />
    )
  }
}

export default AdSenseValidation
