import styled from 'styled-components'

export const StyledClickableIcon = styled.svg`
  cursor: ${({ onClick }) => onClick ? 'pointer' : 'default'};

  path {
    fill: ${({ theme }) => theme.headerLogin.icon.color};
  }

  &:hover { 
    path {
      fill: ${({ theme }) => theme.headerLogin.icon.hover.color};
    }
  }
`
