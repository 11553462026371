import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-bottom: 0;
`

/**
 * SpirMon wrapper for News feed advert articles
 */
class SpirMonWrapperWithoutImpression extends Component {
  shouldComponentUpdate () {
    return false
  }

  componentDidMount () {
    const {
      commentTop,
      commentBottom
    } = this.props

    this.spirStart.outerHTML = commentTop
    this.spirEnd.outerHTML = commentBottom
  }

  getAttributeFromWrapperString (attribute) {
    if (this.props.wrapper) {
      return this.props.wrapper.split(`${attribute}=`)[1].split('"')[1]
    }
  }

  /**
   * Render
   * @returns {XML} markup
   */
  render () {
    const wrapperAttributes = {
      'id': this.getAttributeFromWrapperString('id'),
      'className': this.getAttributeFromWrapperString('class'),
      'data-sasia-view-time': this.getAttributeFromWrapperString('data-sasia-view-time'),
      'data-sasia-view-percent': this.getAttributeFromWrapperString('data-sasia-view-percent'),
      'data-sasia-view-url': this.getAttributeFromWrapperString('data-sasia-view-url')
    }

    return (
      <Wrapper {...wrapperAttributes}>
        <span ref={spir => {
          this.spirStart = spir
        }}/>
        { this.props.children }
        <span ref={spir => {
          this.spirEnd = spir
        }}/>
      </Wrapper>
    )
  }
}

SpirMonWrapperWithoutImpression.propTypes = {
  commentBottom: PropTypes.string.isRequired,
  commentTop: PropTypes.string.isRequired,
  wrapper: PropTypes.string.isRequired
}

export default SpirMonWrapperWithoutImpression
