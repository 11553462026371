import React from 'react'
import SpirMonWrapperWithoutImpression from '../widgets/Advert/SpirMonWrapperWithoutImpression'
import PrAnnotationListBox from './StyledPrAnnotationListBox'

export default props => {
  const {
    commentBottom,
    commentTop,
    id: guid,
    title,
    url: link,
    wrapper
  } = props

  return (
    <SpirMonWrapperWithoutImpression commentTop={commentTop} commentBottom={commentBottom} wrapper={wrapper}>
      <PrAnnotationListBox
        key={guid}
        link={link}
        title={`Reklama: ${title}`}
      />
    </SpirMonWrapperWithoutImpression>
  )
}
