import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TipLink from './StyledTipLink'
import ImageWrapper from './StyledImageWrapper'
import TextWrapper from './StyledTextWrapper'
import Title from './StyledTitle'
import Perex from './StyledPerex'
import { getLinkTarget } from '../../utils/getLinkTarget'

class BigTipBox extends Component {
  render () {
    const {
      handleClick,
      box: {
        image,
        link,
        perex,
        title
      },
      layout
    } = this.props

    return (
      <TipLink onClick={handleClick} href={link} target={getLinkTarget(layout)} rel={'noopener'}>
        <ImageWrapper>
          <img src={image} alt={title}/>
        </ImageWrapper>
        <TextWrapper>
          <Title>
            { title }
          </Title>
          <Perex>
            { perex }
          </Perex>
        </TextWrapper>
      </TipLink>
    )
  }
}

BigTipBox.propTypes = {
  box: PropTypes.object.isRequired
}

export default BigTipBox
