import React from 'react'
import WidgetContainer from '../../containers/WidgetContainer'
import ImagesSection from '../../containers/ImagesSection'
import LinksSection from '../../containers/LinksSection'
import ImageBoxWrapper from '../Articles/ImageBoxWrapper'
import ArticleImageBox from '../../boxes/StyledArticleImageBox'
import RegionPickerTrigger from './RegionPickerTrigger'
import { DRBNA_REGIONS } from '../../../constants'
import Regions from './Regions'
import Loader from '../../utils/Loader'
import ReactDOM from 'react-dom'
import RegionalWidget from './RegionalWidget'
import HeadlineContent from './StyledHeadlineContent'

export default class Regional extends RegionalWidget {
  render () {
    const {
      props: {
        activeDynamicLayout,
        isOpening,
        widget,
        googleAnalytics,
        widget: {
          ad,
          boxes,
          name,
          utmContent,
          utmMedium,
          utmSource,
          imageSize
        },
        renderAdditionalComponent
      },
      state: {
        isRegionPickerOpen,
        shouldShow,
        activeRegion,
        isLoading,
        selectedRegionNews
      }
    } = this

    const utmParams = {
      utmContent,
      utmMedium,
      utmSource
    }

    const renderImageBox = (box, index) => (
      <ImageBoxWrapper
        box={box}
        imageBoxComponent={ArticleImageBox}
        imageSize={imageSize}
        index={index}
        key={`articlesBox${index}`}
        utmParams={utmParams}
        handleAnchorClick={this.handleAnchorClick}
        layout={activeDynamicLayout}
      />
    )

    const textBoxes = selectedRegionNews.length ? [...selectedRegionNews] : [...boxes]
    const headBoxes = textBoxes.splice(0, 2)

    if (!boxes || !boxes.length || !shouldShow) {
      return null
    }

    const headlineContent = (
      <HeadlineContent>
        <RegionPickerTrigger
          activeRegion={activeRegion}
          isOpen={isRegionPickerOpen}
          onClick={() => this.setState({
            isRegionPickerOpen: !isRegionPickerOpen
          })}
          activeDynamicLayout={activeDynamicLayout}
          ref={ref => { this.regionPickerRef = ReactDOM.findDOMNode(ref) }}
        />
      </HeadlineContent>
    )

    return (
      <WidgetContainer googleAnalytics={googleAnalytics} widget={widget} isOpening={isOpening} headlineContent={headlineContent}>
        {
          isLoading &&
          <Loader/>
        }
        { renderAdditionalComponent && renderAdditionalComponent() }
        {
          headBoxes.length > 0 &&
          <ImagesSection boxes={headBoxes} renderBox={renderImageBox}/>
        }
        {
          textBoxes.length > 0 &&
          <LinksSection
            ad={ad}
            boxes={textBoxes}
            indexShift={headBoxes.length}
            device={activeDynamicLayout}
            name={name}
            utmParams={utmParams}
            handleAnchorClick={this.handleAnchorClick}
          />
        }
        <Regions
          ref={ref => { this.regionsRef = ReactDOM.findDOMNode(ref) }}
          regions={Object.keys(DRBNA_REGIONS)}
          activeRegion={activeRegion}
          device={activeDynamicLayout}
          isOpen={isRegionPickerOpen}
          onRegionClick={(newRegion) => {
            this.handleRegionChange(newRegion)
          }}
          onRemoveClick={this.removeWidget}
        />
      </WidgetContainer>
    )
  }
}
