/* @flow */
import React, { Component } from 'react'
import styled from 'styled-components'
import Form from 'reactstrap/lib/Form'
import Input from 'reactstrap/lib/Input'
import { RegularCol, RegularRow } from '../../utils/Grid'
import ExchangeInput from './StyledExchangeInput'
import ExchangeButton from './StyledExchangeButton'
import { EXCHANGE_FORM, EXCHANGE_FORMAT_REGEXP, SIZES } from '../../../constants'
import LinkHandler from '../../utils/LinkHandler'
import { type ExchangeFormProps } from './Types'

const InputCol = styled(RegularCol)`
  display: flex;
  display: -ms-flexbox;
  -ms-flex-pack: justify;
  justify-content: space-between;
`

const AmountInput = styled(ExchangeInput)`
  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    flex: 1 1 0%;
    -ms-flex: 1 1 0%;
    width: auto;
  }
  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    margin: 0 16px;
  }
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    max-width: 60px;
  }
`

const OperationSelect = styled(ExchangeInput)`
  max-width: 132px;
`

const CurrencySelect = styled(ExchangeInput)`
  max-width: 88px;
`

export default class ExchangeForm extends Component<ExchangeFormProps> {
  constructor (props: ExchangeFormProps) {
    super(props)

    this.handleAmountChange = this.handleAmountChange.bind(this)
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this)
    this.handleOperationChange = this.handleOperationChange.bind(this)
    this.handleExchangeFormSubmit = this.handleExchangeFormSubmit.bind(this)
  }

  handleAmountChange: (event: SyntheticInputEvent<>) => void | boolean
  handleAmountChange (event: SyntheticInputEvent<>): void | boolean {
    const amount = event.target.value

    if (!(new RegExp(EXCHANGE_FORMAT_REGEXP).test(amount) || amount === '' || amount === '---')) {
      return false
    }

    this.props.handleExchangeFormChange({
      amount
    })
  }

  handleCurrencyChange: (event: SyntheticInputEvent<>) => void
  handleCurrencyChange (event: SyntheticInputEvent<>) {
    this.props.handleExchangeFormChange({
      activeCurrency: event.target.value
    })
  }

  handleOperationChange: (event: SyntheticInputEvent<>) => void
  handleOperationChange (event: SyntheticInputEvent<>) {
    this.props.handleExchangeFormChange({
      activeOperation: event.target.value
    })
  }

  handleExchangeFormSubmit: (event: Object) => void | boolean
  handleExchangeFormSubmit (event: Object): void | boolean {
    const {
      activeCurrency,
      activeOperation,
      amount,
      goId,
      redirectOptions
    } = this.props

    try {
      const relocationQuery = `amnt=${amount}&op=${activeOperation}&curr=${activeCurrency}&go=${goId}`
      const relocationUri = `${event.target.action}?${relocationQuery}`

      event.preventDefault()
      document.location.href = new LinkHandler(relocationUri).applySASRedirect(redirectOptions).link
    } catch (e) {
      return true
    }
  }

  render () {
    const {
      activeCurrency,
      activeOperation,
      amount,
      goId
    } = this.props

    return (
      <Form action='https://www.exchange.cz/' onSubmit={this.handleExchangeFormSubmit}>
        <RegularRow>
          <InputCol>
            <OperationSelect type='select' name='operation' value={activeOperation}
              onChange={this.handleOperationChange}>
              {
                EXCHANGE_FORM.OPERATIONS.map(({ value, text }) => {
                  return (
                    <option value={value} key={`operation_${value}`}>
                      {text}
                    </option>
                  )
                })
              }
            </OperationSelect>
            <AmountInput type='text' name='amount' pattern={EXCHANGE_FORMAT_REGEXP} value={amount}
              onChange={this.handleAmountChange}/>
            <CurrencySelect type='select' name='currency' value={activeCurrency} onChange={this.handleCurrencyChange}>
              {
                EXCHANGE_FORM.CURRENCIES.map(currency => {
                  return (
                    <option value={currency} key={`currency_${currency}`}>
                      {currency}
                    </option>
                  )
                })
              }
            </CurrencySelect>
            <Input type='hidden' name='go' value={goId}/>
          </InputCol>
        </RegularRow>
        <RegularRow>
          <RegularCol>
            <ExchangeButton type='submit'>
              Kolik { activeOperation === EXCHANGE_FORM.OPERATIONS[0].value ? 'ušetřím' : 'zaplatím' }
            </ExchangeButton>
          </RegularCol>
        </RegularRow>
      </Form>
    )
  }
}
