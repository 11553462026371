/* @flow */
import React, { Component, type Node } from 'react'
import styled from 'styled-components'
import CurrencyRow from './StyledCurrencyRow'
import CurrencyLabel from './StyledCurrencyLabel'
import ExchangeInput from './StyledExchangeInput'
import { EXCHANGE_FORM, EXCHANGE_FORMAT_REGEXP, EXCHANGE_IMAGES_PATH, SIZES } from '../../../constants'
import { type ExchangeRatesProps } from './Types'
import { sendWidgetClick } from '../../utils/ga4Events'

declare var UNVERSIONED_STATICS_PATH: string

const InputWrapper = styled.div`
  display: -ms-flexbox;
  flex: 1 1 100%;
  -ms-flexbox: 1 1 100%;
  margin: 0 8px;
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    max-width: 90px;
  }
`

const CurrencyInput = styled(ExchangeInput)`
  text-align: right;
  width: 100%;
`

export default class ExchangeRates extends Component<ExchangeRatesProps> {
  handleInputFocus (event: SyntheticInputEvent<>, currency: string, amount: string, direction: string): void {
    const {
      handleCurrencyChange
    } = this.props

    sendWidgetClick({
      name: 'kurzy',
      click: {
        value: currency,
        type: 'input'
      }
    })
    event.target.select()
    handleCurrencyChange(currency, amount, direction)
  }

  renderExchangeRates (): Node {
    const {
      currencies,
      handleCurrencyChange
    } = this.props

    return Object.entries(currencies).map(([currency, { currencyAmount, czkAmount }]: [string, any]) => {
      return (
        <CurrencyRow key={currency}>
          <img src={`${UNVERSIONED_STATICS_PATH}${EXCHANGE_IMAGES_PATH.FLAGS}${currency.toLowerCase()}.png`}/>
          <InputWrapper>
            <CurrencyInput
              type='text'
              id={`currency-${currency}`}
              value={currencyAmount}
              pattern={EXCHANGE_FORMAT_REGEXP}
              onChange={event => handleCurrencyChange(currency, event.target.value, EXCHANGE_FORM.OPERATIONS[0].value)}
              onFocus={event => this.handleInputFocus(event, currency, currencyAmount, EXCHANGE_FORM.OPERATIONS[0].value)}
            />
          </InputWrapper>
          <CurrencyLabel for={`currency-${currency}`}>{ currency }</CurrencyLabel>
          <InputWrapper>
            <CurrencyInput
              type='text'
              id={`czk-${currency}`}
              value={czkAmount}
              pattern={EXCHANGE_FORMAT_REGEXP}
              onChange={event => handleCurrencyChange(currency, event.target.value, EXCHANGE_FORM.OPERATIONS[1].value)}
              onFocus={event => this.handleInputFocus(event, currency, czkAmount, EXCHANGE_FORM.OPERATIONS[1].value)}
            />
          </InputWrapper>
          <CurrencyLabel for={`czk-${currency}`}>CZK</CurrencyLabel>
        </CurrencyRow>
      )
    })
  }

  render () {
    return (
      <div>
        { this.renderExchangeRates() }
      </div>
    )
  }
}
