import React, { Component } from 'react'
import PropTypes from 'prop-types'
import BigTipBox from './BigTipBox'
import SmallTipBox from './SmallTipBox'
import { VARIANTS } from '../../../constants'
import { sendArticleClick } from '../../utils/ga4Events'

class TipBox extends Component {
  handleCLick = () => {
    const { box, itemVariant } = this.props
    sendArticleClick({
      boxName: 'tipOfTheDay',
      url: box.link,
      id: box.guid,
      title: box.title,
      variant: itemVariant
    })
  }
  render () {
    const {
      box,
      variant,
      layout
    } = this.props

    if (variant === VARIANTS.BIG) {
      return <BigTipBox handleClick={this.handleCLick} box={box} layout={layout}/>
    } else {
      return <SmallTipBox handleCLick={this.handleCLick} box={box} layout={layout}/>
    }
  }
}

TipBox.propTypes = {
  box: PropTypes.object.isRequired
}

export default TipBox
