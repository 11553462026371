import React, { useEffect, useRef, useState } from 'react'
import { DefaultCraPlayerOptions } from './DefaultCraPlayerOptions'
import styled from 'styled-components'
import { getLinkTarget } from '../../../utils/getLinkTarget'

const mimeTypesMap = new Map([
  ['video/mp4', 'MP4']
])

export const CraPlayer = ({ video, layout }) => {
  const videoRef = useRef(null)
  const playerRef = useRef(null)

  const [hasInitializationError, setHasInitializationError] = useState(false)

  const { url, type } = video

  useEffect(() => {
    const label = mimeTypesMap.get(type)
    if (!label) {
      console.error(`Unsupported video format: ${type}`)
      setHasInitializationError(true)
      return
    }

    const options = {
      ...DefaultCraPlayerOptions,
      tracks: {
        [label]: [{
          src: url,
          type: type
        }]
      }
    }

    const initPlayer = async () => {
      if (!videoRef.current) {
        return
      }

      try {
        playerRef.current = await ottPlayer(videoRef.current, options)
        setHasInitializationError(false)
      } catch (err) {
        console.error('Error creating player instance', err)
        setHasInitializationError(true)
      }
    }

    initPlayer()

    return () => {
      playerRef.current?.dispose?.()
    }
  }, [url, type])

  if (hasInitializationError) {
    return (
      <StyledUnsupportedBrowserMessage>
        Nastala chyba při přehrávání. Zkuste to prosím později nebo zkuste
        tento{' '}
        <StyledSourceVideoLink
          href={url}
          target={getLinkTarget(layout)}
          rel="noopener"
        >
          odkaz
        </StyledSourceVideoLink>.
      </StyledUnsupportedBrowserMessage>
    )
  }

  return (
    <video
      ref={videoRef}
      className="video-js vjs-show-big-play-button-on-pause vjs-big-play-centered"
    >
      <StyledGenericErrorMessage className="vjs-no-js">
        Video se nepřehrává, protože používáte prohlížeč, který přehrávač
        nepodporuje. Zkuste prohlížeč aktualizovat, nebo použít jiný. Video
        naleznete také{' '}
        <StyledSourceVideoLink
          href={url}
          target={getLinkTarget(layout)}
          rel="noopener"
        >
          zde
        </StyledSourceVideoLink>.
      </StyledGenericErrorMessage>
    </video>
  )
}

const StyledGenericErrorMessage = styled.p`
  width: 100%;
`

const StyledUnsupportedBrowserMessage = styled.p`
  margin: 0;
`

const StyledSourceVideoLink = styled.a`
  display: inline !important;
`
