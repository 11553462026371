import React from 'react'
import styled from 'styled-components'

const Line = styled.div`
  border-top: 1px solid #d1d1d1;
  height: 1px;
  position: relative;
  width: 100%;
`

export const Separator = styled.div`
  background: #fff;
  bottom: 0;
  display: flex;
  height: 25px;
  justify-content: flex-end;
  left: 0;
  position: absolute;
  right: 0;
`

export const SeparatorLine = () => <Separator><Line/></Separator>
