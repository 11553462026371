import styled from 'styled-components'

export default styled.div`
  background: #eee;
  overflow: hidden;
  & .brand-text {
    color: #00467f;
    float: left;
    font-weight: bold;
    font-size: 11px;
    line-height: 24px;
    margin-left: 8px;
  }
  & .autocomplete-disable {
    float: right;
    color: #333;
    font-size: 11px;
    line-height: 24px;
    margin-right: 13px;
  }
  & .autocomplete-disable:hover {
    color: ${props => props.theme.header.search.form.autocomplete.color};
    cursor: pointer;
    text-decoration: underline;
  }
`
