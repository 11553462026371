import styled from 'styled-components'

export default styled.a`
  display: flex;
  display: -ms-flexbox;
  flex: 1;
  -ms-flexbox: 1;
  flex-direction: column;
  -ms-flex-direction: column;
  position: relative;

  & div:last-child {
    color: ${props => props.theme.dynamicLead.smallBox.color};
    font-weight: 400;
    display: block;
    font-size: 17px;
    height: 67px;
    line-height: 20px;
    overflow: hidden;
    padding: 7px 0px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &:hover {
    color: ${props => props.theme.link.colorHover}; 
    text-decoration: underline;

    & div:last-child {
      color: inherit;
    }
  }

  & span.video, span.live {
    height: 19px;
    left: 14px;
    bottom: 14px;
    font-size: 10px;
    line-height: 17px;
    padding: 0 7px 0 17px;

    &::before {
    background-size: 8px 10px;
    height: 10px;
    width: 8px;
    left: 5px;
    top: 4px;
    }
  }
`
