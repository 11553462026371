import { buildLoginPopupFeatures } from './loginPopup'
import {
  buildFallbackLoginPageUrl,
  buildLoginPageURL, extractOrigin
} from '../components/utils/Login'

let loginPopup = null
let intervalId = null

export const login = ({ loginLocation, clientId }) => {
  const loginUrl = buildLoginPageURL({
    clientId,
    loginLocation
  })

  if (loginPopup && !loginPopup.closed) {
    loginPopup.focus()
    return
  }

  const windowFeatures = buildLoginPopupFeatures()
  const popup = window.open(loginUrl, '_blank', windowFeatures)

  if (!popup) {
    window.location.href = buildFallbackLoginPageUrl({
      clientId,
      loginLocation
    })
  }

  loginPopup = popup
  intervalId = setInterval(checkPopupClosed, 50)

  window.addEventListener('message', handleMessage, false)
}

const handleMessage = (event) => {
  const currentOrigin = extractOrigin(window.location.href)

  if (event.origin !== currentOrigin) {
    return
  }

  try {
    if (event.data === 'login_success' && loginPopup) {
      window.removeEventListener('message', handleMessage, false)
      loginPopup.postMessage('confirm', currentOrigin)
      clearState()
      location.reload()
    }
  } catch (e) {
    console.error(`An error occurred while sending confirm message to origin ${currentOrigin}.`)
    clearState()
  }
}

const checkPopupClosed = () => {
  if (loginPopup?.closed) {
    clearInterval(intervalId)
    clearState()
  }
}

const clearState = () => {
  loginPopup = null
  intervalId = null
}
