import React, { createRef } from 'react'
import styled from 'styled-components'
import { Avatar } from './Avatar/Avatar'
import UserMenu from './UserMenu/UserMenu'
import { createPortal } from 'react-dom'
import { MailIcon } from '../../../icons/Mail/MailIcon'
import { getUnreadData } from '../../Email/EmailUtils'
import {
  StyledUnreadMessagesCount
} from '../../../ui/UnreadMessagesCount/StyledUnreadMessagesCount'
import {
  StyledEnvelopeContainer
} from '../../../ui/Envelope/StyledEnvelopeContainer'

export class LoggedInHeaderLogin extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isMenuOpen: false,
      error: null // TODO: network error state
    }

    this.avatarRef = createRef()
    this.menuRef = createRef()

    this.toggleMenuOpen = this.toggleMenuOpen.bind(this)
    this.closeMenu = this.closeMenu.bind(this)
    this.navigateToEmail = this.navigateToEmail.bind(this)
  }

  componentDidMount () {
    document.addEventListener('click', this.closeMenuWhenOutsideClick)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.closeMenuWhenOutsideClick)
    clearInterval(this.interval)
  }

  closeMenuWhenOutsideClick = (event) => {
    if (!this.menuRef.current || !this.avatarRef.current) {
      return
    }

    if (!this.menuRef.current.contains(event.target) && !this.avatarRef.current.contains(event.target)) {
      this.closeMenu()
    }
  }

  toggleMenuOpen () {
    this.setState((prevState) => ({
      isMenuOpen: !prevState.isMenuOpen
    }))
  }

  closeMenu () {
    this.setState({ isMenuOpen: false })
  }

  navigateToEmail () {
    window.location.href = this.props.emailWidget.meta.inbox.location
  }

  render () {
    const {
      props: {
        inbox,
        userInfo,
        leaderboardHeight,
        rtbLeaderboardHeight,
        userMenuElement,
        headerHeight,
        emailWidget,
        userMenuWidget,
        ecoIdentity,
        layout
      },
      state: {
        isMenuOpen
      }
    } = this

    const { hasMailbox } = userInfo

    const {
      unreadMessagesCountString,
      unreadFormatType
    } = getUnreadData(inbox?.summary?.unread || 0)

    return (
      <StyledContainer $unreadFormatType={unreadFormatType}>
        {hasMailbox && <StyledEnvelopeContainer>
          <MailIcon type="medium" onClick={this.navigateToEmail} />
          <StyledUnreadMessagesCount $unreadFormatType={unreadFormatType}>
            {unreadMessagesCountString}
          </StyledUnreadMessagesCount>
        </StyledEnvelopeContainer>}
        <Avatar
          userInfo={userInfo}
          ecoIdentity={ecoIdentity}
          onClick={this.toggleMenuOpen}
          ref={this.avatarRef}
        />
        {isMenuOpen && userMenuElement && createPortal(
          <UserMenu
            userMenuWidget={userMenuWidget}
            userInfo={userInfo}
            onClose={this.closeMenu}
            ref={this.menuRef}
            unreadString={unreadMessagesCountString}
            emailWidget={emailWidget}
            leaderboardHeight={leaderboardHeight}
            rtbLeaderboardHeight={rtbLeaderboardHeight}
            headerHeight={headerHeight}
            ecoIdentity={ecoIdentity}
            layout={layout}
          />,
          userMenuElement
        )}
      </StyledContainer>
    )
  }
}

const StyledContainer = styled.div`
  display: flex;
  align-items: end;
  gap: ${({ $unreadFormatType }) => $unreadFormatType === 'overMaxShown'
    ? '32px'
    : '24px'};
`
