const RETURN_URL_KEY = 'url'

export const buildLogoutURL = ({ logoutLocation }) => {
  const queryParams = {
    [RETURN_URL_KEY]: window.location.href
  }

  const searchParams = new URLSearchParams(queryParams)
  const querystring = searchParams.toString()

  return `${logoutLocation}?${querystring}`
}
