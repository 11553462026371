import React from 'react'
import styled, { keyframes } from 'styled-components'

export const LoadingBubble = () => {
  return (
    <StyledSpinner>
      <StyledBounceOne></StyledBounceOne>
      <StyledBounceTwo></StyledBounceTwo>
      <StyledBounceThree></StyledBounceThree>
    </StyledSpinner>
  )
}

const bounceAnimation = keyframes`
  0%, 80%, 100% { transform: scale(0.0); }
  40% { transform: scale(1.0); }
`

const StyledSpinner = styled.div`
  display: flex;
  align-items: end;
  gap: 12px;
`

const StyledBounce = styled.div`
  width: 48px;
  height: 48px;
  background-color: #edf2f6;
  border-radius: 100%;
  display: inline-block;
  animation: ${bounceAnimation} 1.6s infinite ease-in-out;
`

const StyledBounceOne = styled(StyledBounce)`
  animation-delay: -0.32s;
`

const StyledBounceTwo = styled(StyledBounce)`
  animation-delay: -0.16s;
`

const StyledBounceThree = styled(StyledBounce)`
  animation-delay: 0s;
`
