import React, { Component } from 'react'
import Link from '../../utils/Link'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import FeedList from './StyledFeedList'
import FeedContainer from './StyledFeedContainer'
import { sendWidgetClick } from '../../utils/ga4Events'

/**
 * Single Footer Feed ListBox renderer
 */
export default class FooterFeed extends Component {
  handleLinkClick = name => () => {
    const { widget } = this.props
    sendWidgetClick({
      name: widget.name,
      click: {
        type: 'footer',
        value: name
      }
    })
  }
  /**
   * @return {XML}
   */
  render () {
    if (!this.props.widget) return null
    const {
      widget: {
        utmMedium,
        boxes = []
      }
    } = this.props

    let feedItems = boxes.slice(0)
    feedItems.sort((a, b) => {
      return a.forcedPosition - b.forcedPosition
    })
    const feedCaption = feedItems.length > 0 ? feedItems.shift() : { title: '' }
    return (
      <FeedContainer>
        <h4>
          { feedCaption.title }
        </h4>
        <FeedList>
          {
            feedItems.length > 0 && feedItems.map((feedItem, index) => {
              const {
                link,
                source,
                title
              } = feedItem
              const utmLink = utmLinkGenerator.createLink(link, {
                utmTerm: `position-${index}`,
                utmCampaign: source,
                utmMedium
              })

              return (
                <li key={`${source}-${index}`}>
                  <Link onClick={this.handleLinkClick(title)} href={utmLink}>
                    { title }
                  </Link>
                </li>
              )
            })
          }
        </FeedList>
      </FeedContainer>
    )
  }
}
