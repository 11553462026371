import React, { Component } from 'react'

export default class FooterCopyRight extends Component {
  render () {
    return (
      <span>
        &nbsp;&nbsp;1999 – { (new Date()).getFullYear() }&nbsp;&copy;&nbsp;Economia,&nbsp;a.s.
      </span>
    )
  }
}
