import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TipLink from './StyledTipLink'
import Icon from './StyledIcon'
import SmallTitle from './StyledSmallTitle'
import { TIP_OF_THE_DAY_ICONS } from '../../../constants'
import { getLinkTarget } from '../../utils/getLinkTarget'

class SmallTipBox extends Component {
  getIcon (tags) {
    let result = 'default.png'
    for (let tag of tags) {
      const value = Object.values(tag)[0]
      const icon = TIP_OF_THE_DAY_ICONS[value]
      if (icon) {
        result = icon
        break
      }
    }

    return `${UNVERSIONED_STATICS_PATH}images/tipoftheday/${result}`
  }

  render () {
    const {
      handleCLick,
      box: {
        link,
        smallTitle,
        tags
      },
      layout
    } = this.props

    return (
      <TipLink onClick={handleCLick} href={link} target={getLinkTarget(layout)} rel='noopener'>
        <Icon src={this.getIcon(tags)}/>
        <div>
          <SmallTitle>
            { smallTitle }
          </SmallTitle>
        </div>
      </TipLink>
    )
  }
}

SmallTipBox.propTypes = {
  box: PropTypes.object.isRequired
}

export default SmallTipBox
