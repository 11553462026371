export const buildLoginPopupFeatures = () => {
  // https://stackoverflow.com/questions/32599613/javascript-differences-between-window-properties
  // https://www.google.com/search?q=is+internet+explorer+supported&sca_esv=89411bb37f4aa9f8&ei=gapEZrKODoyA9u8P5-yd4AE&ved=0ahUKEwjy0smV1I-GAxUMgP0HHWd2BxwQ4dUDCBA&uact=5&oq=is+internet+explorer+supported&gs_lp=Egxnd3Mtd2l6LXNlcnAiHmlzIGludGVybmV0IGV4cGxvcmVyIHN1cHBvcnRlZDIFEAAYgAQyBhAAGBYYHjIGEAAYFhgeMgYQABgWGB4yBhAAGBYYHjIGEAAYFhgeMgYQABgWGB4yBhAAGBYYHjIGEAAYFhgeMgYQABgWGB5IsBRQ3gpY8hNwAHgCkAEAmAFToAGmAqoBATS4AQPIAQD4AQGYAgSgAt8BwgIEEAAYR8ICBxAAGIAEGA3CAggQABgHGAgYHsICBhAAGA0YHsICCBAAGAgYDRgewgIEEAAYHsICBhAAGAgYHpgDAOIDBRIBMSBAiAYBkAYIkgcBNKAHzCg&sclient=gws-wiz-serp
  const screenPosition = {
    x: window.screenX || window.screenLeft,
    y: window.screenY || window.screenTop
  }

  const windowSize = {
    w: window.outerWidth,
    h: window.outerHeight - 100
  }

  const popupSize = {
    w: 960,
    h: 630
  }

  const centeredPosition = {
    x: screenPosition.x + (windowSize.w - popupSize.w) / 2,
    y: screenPosition.y + (windowSize.h - popupSize.h) / 2
  }

  const popupOptions = {
    left: centeredPosition.x,
    top: centeredPosition.y,
    width: popupSize.w,
    height: popupSize.h,
    scrollbars: 1
  }

  const optionsString = []
  for (const option in popupOptions) {
    optionsString.push(option + '=' + popupOptions[option])
  }

  return optionsString.join(',')
}
