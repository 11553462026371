import styled from 'styled-components'

const SelectList = styled.ul`
  background: white;
  box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.32);
  list-style: none;
  padding: 5px;
  position: absolute;
  top: 50px;
  width: 100%;
  z-index: 10;
`

const SelectListItem = styled.li`
  cursor: pointer;
  position: relative;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  & .name {
    font-size: 22px;
    font-weight: bold;
    padding-top: -3px;
  }

  & .date {
    font-size: 13px;
  }
`

export { SelectList, SelectListItem }
