import styled from 'styled-components'
import Link from '../../../utils/Link'

export default styled(Link)`
  background: url(${STATICS_PATH}images/email/mail-icon.png) center right no-repeat;
  bottom: 15px;
  color: #333;
  display: block;
  font-size: 15px;
  font-weight: bold;
  height: 16px;
  line-height: 16px;
  position: absolute;
  right: 15px;
  text-decoration: none;
  text-transform: uppercase;
  width: 85px;
  
  &:hover {
    color: #333 !important;
    text-decoration: none;
  }
`
