import React from 'react'
import styled, { keyframes } from 'styled-components'

export const LoadingSpinner = () => {
  return <Spinner />
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${rotate} 1s linear infinite;
  margin-top: 8px;
  margin-bottom: 2px;
`
