import React from 'react'
import PropTypes from 'prop-types'
import PersistedComponent from '../PersistedComponent'
import WidgetContainer from '../../containers/WidgetContainer'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import CloseButton from './CloseButton'
import TipBox from './TipBox'
import { DEFAULT_VISIBILITY, VARIANTS } from '../../../constants'
import { sendWidgetClick } from '../../utils/ga4Events'

class TipOfTheDay extends PersistedComponent {
  constructor (props) {
    super(props)

    const {
      widget: {
        boxes
      }
    } = props

    this.persistedKeys = [ 'tipSettings' ]

    this.state = Object.assign({}, this.state, {
      box: (boxes && boxes.length) ? Object.assign({}, boxes[0]) : {},
      tipSettings: {}
    })

    this.closeTip = this.closeTip.bind(this)
  }

  componentDidMount () {
    const {
      box: {
        annotation,
        guid,
        perex
      },
      tipSettings
    } = this.state

    if (guid) {
      const settings = tipSettings[guid] || {}

      if (!Object.keys(settings).length) {
        Object.assign(settings, {
          remaining: this.getNumericValue(annotation, DEFAULT_VISIBILITY),
          variant: perex ? VARIANTS.BIG : VARIANTS.SMALL
        })
      }

      if (settings.variant === VARIANTS.SMALL) {
        settings.remaining = Math.max(settings.remaining - 1, 0)
        if (settings.remaining === 0) {
          settings.variant = VARIANTS.NONE
        }
      }

      this.updateSettings(settings)
    }
  }

  updateSettings (settings) {
    const {
      box: {
        guid
      },
      tipSettings
    } = this.state

    this.setState({
      tipSettings: Object.assign({}, tipSettings, {
        [guid]: settings
      })
    })
  }

  getNumericValue (variable, defaultValue = NaN) {
    const parsed = parseInt(variable)
    return (!isNaN(parsed)) ? parsed : defaultValue
  }

  closeTip () {
    const {
      box: {
        annotation
      }
    } = this.state

    sendWidgetClick({
      name: 'tipOfTheDay',
      click: {
        type: 'button',
        value: 'close'
      }
    })
    this.updateSettings({
      variant: VARIANTS.SMALL,
      remaining: this.getNumericValue(annotation, DEFAULT_VISIBILITY)
    })
  }

  render () {
    const {
      props: {
        widget,
        googleAnalytics,
        activeDynamicLayout,
        widget: {
          imageSize,
          utmMedium
        },
        itemVariant
      },
      state: {
        box,
        box: {
          guid,
          images = {},
          link,
          seoTitle,
          source
        },
        tipSettings: {
          [guid]: {
            variant
          } = {}
        }
      }
    } = this

    if (!Object.keys(box).length || variant === VARIANTS.NONE) {
      return null
    }

    const utmParams = {
      utmCampaign: source,
      utmMedium,
      utmTerm: 'position-1'
    }

    const tipBoxWithUtmAndImage = Object.assign(box, {
      image: images[imageSize],
      link: utmLinkGenerator.createLink(link, utmParams)
    })

    const newWidget = Object.assign({}, widget)

    if (seoTitle) {
      Object.assign(newWidget.meta, {
        headline: {
          text: seoTitle,
          location: link
        }
      })
    }

    const headlineContent = (variant === VARIANTS.BIG) && <CloseButton closeMethod={this.closeTip}/>

    return (
      <WidgetContainer googleAnalytics={googleAnalytics} widget={newWidget} headlineContent={headlineContent}>
        <TipBox
          googleAnalytics={googleAnalytics}
          box={tipBoxWithUtmAndImage}
          variant={variant}
          layout={activeDynamicLayout}
          itemVariant={itemVariant}
        />
      </WidgetContainer>
    )
  }
}

TipOfTheDay.propTypes = {
  widget: PropTypes.object.isRequired
}

export default TipOfTheDay
