/* @flow */
import React from 'react'
import { WIDGETS } from '../../../constants'
import MenuItemWrapper from './StyledMenuItemWrapper'
import ItemDescription from './StyledItemDescription'
import EmailIcon from './EmailIcon'
import TvProgramIcon from './StyledTvProgramIcon'
import HoroscopeIcon from './HoroscopeIcon'
import MobileWeatherPreview from '../Weather/MobileWeatherPreview'
import { type MenuItemProps } from './Types'
import { Avatar } from '../Header/HeaderLogin/Avatar/Avatar'
import { EmailConsumer } from '../../../context/Email'
import { LoadingSpinner } from './LoadingSpinner'
import styled from 'styled-components'
import { StyledClickableIcon } from '../../icons/StyledClickableIcon'
import { StyledAvatarContainer } from '../../ui/Avatar/StyledAvatar'
import { isResponseFull } from '../Email/EmailUtils'

const ITEM_CONTENT = {
  [WIDGETS.HOMEPAGE.EMAIL]: {
    content: <EmailIcon/>,
    displayName: 'E-mail'
  },
  [WIDGETS.HOMEPAGE.MOBILE_USER_MENU]: {
    content: ({ ecoIdentity, isLoading, userInfo, variant, isLoggedIn }) => {
      if (isLoading) {
        return <LoadingSpinner />
      }

      return (
        <StyledAvatarWrapper
        >
          <Avatar
            userInfo={userInfo}
            ecoIdentity={ecoIdentity}
          />
        </StyledAvatarWrapper>
      )
    },
    displayName: 'Můj účet'
  },
  [WIDGETS.HOMEPAGE.TV_PROGRAM]: {
    content: <TvProgramIcon/>,
    displayName: 'TV program'
  },
  [WIDGETS.HOMEPAGE.HOROSCOPES]: {
    content: <HoroscopeIcon/>,
    displayName: 'Horoskopy'
  },
  [WIDGETS.HOMEPAGE.WEATHER]: {
    content: <MobileWeatherPreview/>
  }
}

export default (props: MenuItemProps) => {
  const {
    handleWidgetSelection,
    isSelected,
    widgetName,
    ecoIdentity,
    variant
  } = props

  const {
    content,
    displayName
  } = ITEM_CONTENT[widgetName]

  return (
    <EmailConsumer>
      {({ userInfo, isLoading, inbox }) => {
        const isLoggedIn = isResponseFull(inbox)

        return (
          <MenuItemWrapper className={isSelected ? 'active' : ''}
            onClick={() => handleWidgetSelection(widgetName)}>
            {WIDGETS.HOMEPAGE.MOBILE_USER_MENU === 'userMenu' && typeof content === 'function'
              ? content({ ecoIdentity, userInfo, isLoading, variant, isLoggedIn })
              : content}
            {
              displayName &&
              <ItemDescription>
                {displayName}
              </ItemDescription>
            }
          </MenuItemWrapper>
        )
      }}
    </EmailConsumer>
  )
}

const StyledAvatarWrapper = styled.div`
  margin-left: 0;
  margin-top: 8px;
  margin-bottom: 2px;
  
  ${StyledClickableIcon} {
    path {
      fill: ${({ theme }) => theme.mobileUserMenu.iconColor};
    }
  }

  ${StyledAvatarContainer} {
    color: ${({ theme }) => theme.mobileUserMenu.color};
    border: 1px solid ${({ theme }) => theme.mobileUserMenu.borderColor};
  }
`
