import React from 'react'
import styled from 'styled-components'
import { type TitleWithPriceAndAnnotationProps } from '../Types'

const TitleContainer = styled.div`
  & .price {
    font-weight: bold;
  }
`

export default (props: TitleWithPriceAndAnnotationProps) => {
  const {
    annotation,
    className,
    price,
    title
  } = props

  return (
    <TitleContainer className={className}>
      <div className='title'>
        { title }
      </div>
      <div className='annotation'>
        { annotation }
      </div>
      <div className='price'>
        { price }
      </div>
    </TitleContainer>
  )
}
