const { formatDuration } = require('./VideoDuration')

export const getVideoLabel = (type, duration) => {
  switch (type) {
    case 'video':
      return formatDuration(duration)
    case 'live':
      return 'živě'
  }
}
