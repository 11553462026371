import React, { Component } from 'react'
import WidgetContainer from '../../containers/WidgetContainer'
import ImagesSection from '../../containers/ImagesSection'
import LinksSection from '../../containers/LinksSection'
import ImageBoxWrapper from './ImageBoxWrapper'
import ArticleImageBox from '../../boxes/StyledArticleImageBox'
import { NewLabelDesktop } from '../../utils/NewLabelDesktop'
import URL from 'url-parse'
import { sendArticleClick } from '../../utils/ga4Events'
import { DEVICES } from '../../../constants'

export default class Articles extends Component {
  render () {
    const {
      activeDynamicLayout,
      isOpening,
      widget,
      widget: {
        ad,
        boxes,
        imageSize = '4_3',
        name,
        utmContent,
        utmMedium,
        utmSource,
        enhancedEcommerce,
        isNew,
        sendGaCategory
      },
      renderAdditionalComponent,
      itemVariant
    } = this.props

    const handleEcommerceLink = (event, location, box, position) => {
      event.preventDefault()

      const {
        guid: id,
        title: name,
        link
      } = box

      const purchaseFieldObject = {
        id,
        name
      }

      sendGaCategory && Object.assign(purchaseFieldObject, { category: new URL(link).hostname })

      if (activeDynamicLayout === DEVICES.DESKTOP) {
        window.open(location)
      } else {
        document.location.href = location
      }

      sendArticleClick({
        id,
        position,
        boxName: widget.name,
        url: link,
        title: name,
        variant: itemVariant
      })
    }

    if (!boxes || !boxes.length) {
      return null
    }

    const textBoxes = [...boxes]
    const headBoxes = textBoxes.splice(0, 2)

    const utmParams = {
      utmContent,
      utmMedium,
      utmSource
    }

    const handleAnchorClick = enhancedEcommerce ? handleEcommerceLink : null
    const headlineContent = isNew ? <NewLabelDesktop/> : null

    const renderImageBox = (box, index) => <ImageBoxWrapper
      box={box}
      imageBoxComponent={ArticleImageBox}
      imageSize={imageSize}
      index={index}
      key={`articlesBox${index}`}
      utmParams={utmParams}
      handleAnchorClick={handleAnchorClick}
      layout={activeDynamicLayout}
    />

    return (
      <WidgetContainer
        widget={widget}
        isOpening={isOpening}
        headlineContent={headlineContent}
        activeDynamicLayout={activeDynamicLayout}
      >
        { renderAdditionalComponent && renderAdditionalComponent() }
        {
          headBoxes.length > 0 &&
          <ImagesSection boxes={headBoxes} renderBox={renderImageBox}/>
        }
        {
          textBoxes.length > 0 &&
          <LinksSection
            ad={ad}
            boxes={textBoxes}
            indexShift={headBoxes.length}
            device={activeDynamicLayout}
            name={name}
            utmParams={utmParams}
            handleAnchorClick={handleAnchorClick}
          />
        }
      </WidgetContainer>
    )
  }
}
