import styled from 'styled-components'

export default styled.span`
  &.video, &.live {
    background: ${props => props.theme.featured.video.backgroundColor};
    border: 1px solid ${props => props.theme.featured.video.borderColor};
    border-radius: 4px;
    bottom: 3.5px;
    color: ${props => props.theme.featured.video.color};
    font-size: 8px;
    font-weight: bold;    
    height: 17px;
    left: 3.5px;
    line-height: 16px;
    padding: 0 6px 0 13px;
    position: absolute;
    width: auto;
    font-family: Arial, lucida, helvetica, sans-serif;
  }

  &.video::before, &.live::before {
    background: url(${UNVERSIONED_STATICS_PATH}images/video/play-icon.svg);
    background-size: 6px 8px;
    content: ' ';
    display: inline-block;
    filter: invert(${props => props.theme.featured.video.iconInvert});
    height: 8px;
    left: 4px;
    position: absolute;
    top: 4px;
    width: 6px;
  }

  &.online {
    background-color: #f44336;
    bottom: 0;
    color: #fff;
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    height: 20px;
    left: 0;
    line-height: 20px;
    padding: 0 4px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
  }

  &.online::before {
    content: '● ONLINE';
  }

  &.online.small {
    font-size: 8px;
    height: 15px;
    line-height: 15px;
  }

  &.online.big {
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    padding: 0 15px;
  }

  &.online.inline, &.video.inline, &.live.inline {
    display: inline-block;
    font-size: 10px;
    height: 18px;
    line-height: 18px;
    margin-left: 10px;
    margin-right: 0;
    position: unset;
    vertical-align: top;
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
      position: relative;
      float: right;
    };
  }
  
  &.video.inline, &.live.inline {
    padding-left: 6px
  }
  
  &.video.inline::before, &.live.inline::before {
    position: unset;
    margin-right: 2px;
  }
`
