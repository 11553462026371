import React, { Component } from 'react'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import styled from 'styled-components'
import WidgetContainer from '../containers/WidgetContainer'
import LinkHandler from '../utils/LinkHandler'
import Link from '../utils/Link'
import { SERVICES_LINKS, SIZES } from '../../constants'
import { sendWidgetClick } from '../utils/ga4Events'
import { getLinkTarget } from '../utils/getLinkTarget'

const LinkBox = styled(Link)`
  color: ${props => props.theme.services.color};
  display: inline-block;
  padding: 5px 0;
  margin-right: 12px;

  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    margin-right: 13px;
  }
`

const LinkCol = styled(Col)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  -ms-flex-direction: row;
  margin-top: -10px;
  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    justify-content: flex-start;
    -ms-flex-pack: start;
  }
`

export default class Services extends Component {
  renderLinks () {
    const {
      activeDynamicLayout,
      widget: {
        utmMedium,
        utmSource,
        utmContent
      }
    } = this.props

    return SERVICES_LINKS.map(link => {
      const utmParams = {
        utmSource,
        utmMedium,
        utmContent
      }
      const location = new LinkHandler(link.location).mergeUTMParams(utmParams).link
      const targetLink = getLinkTarget(activeDynamicLayout)

      return (
        <LinkBox
          onClick={this.handleLinkClick(link.text)}
          href={location}
          key={link.text}
          target={targetLink}
          rel='noopener'
        >
          {link.text}
        </LinkBox>
      )
    })
  }

  handleLinkClick = serviceName => () =>
    sendWidgetClick({
      name: this.props.widget.name,
      click: {
        type: 'link',
        label: serviceName
      }
    })

  render () {
    const {
      widget,
      googleAnalytics
    } = this.props

    return (
      <WidgetContainer googleAnalytics={googleAnalytics} widget={widget}>
        <Row>
          <LinkCol>
            {this.renderLinks()}
          </LinkCol>
        </Row>
      </WidgetContainer>
    )
  }
}
