import React, { PureComponent } from 'react'
import styled from 'styled-components'
import { SIZES } from '../../../constants'

const Wrapper = styled.div`
  align-items: flex-end;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-align: end;
  margin-bottom: 20px;

  @media (min-width: ${SIZES.BREAKPOINT_XS}px) {
    border-bottom: 1px solid #d1d1d1;
  }
`

const WeatherContainer = styled.div`
  flex: 1 1 auto;

  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    flex: 1 1 300px;
    max-width: 300px;
  }
  
  & .widgetCentrum-forecast, & .widgetAtlas-forecast, & .widgetVolny-forecast {
    margin-top: 0;
  }
`

export default class ForecastWeather extends PureComponent {
  componentDidMount () {
    const {
      widget: {
        loader: {
          desktop
        }
      }
    } = this.props

    const script = document.createElement('script')
    script.src = desktop
    this.container.appendChild(script)
  }

  render () {
    const {
      widget: {
        meta: {
          container: {
            id
          }
        }
      }
    } = this.props

    return (
      <Wrapper>
        <WeatherContainer id={`widget_tiny_forecast${id}_container`}/>
        <div id='widget_tiny_forecast_desktop_container' ref={container => { this.container = container }}/>
      </Wrapper>
    )
  }
}
