import React, { Component } from 'react'
import { RegularCol, RegularRow } from '../../utils/Grid'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import FooterCopyright from './FooterCopyright'
import Link from '../../utils/Link'
import { FOOTER_LINKS_SITES, FOOTER_LINKS_SECTIONS } from '../../../constants'
import FooterSitesContainer from './StyledFooterSitesContainer'
import FooterLinksContainer from './StyledFooterLinksContainer'
import UnorderedList from './StyledUnorderedList'
import ListItem from './StyledListItem'
import styled from 'styled-components'
import { sendWidgetClick } from '../../utils/ga4Events'

const FooterCol = styled(RegularCol)`
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  -ms-flex-direction: column;
  -ms-flex-pack: center;
`

/**
 * Footer Bottom Links renderer
 */
export default class FooterLinks extends Component {
  handleFooterLink = name => () => {
    sendWidgetClick({
      name: this.props.widget.name,
      click: {
        value: name,
        type: 'footer'
      }
    })
  }
  /**
   * Sections Links renderer
   * @param {Object} widgetUTMParams
   * @return {XML}
   */
  renderLinkSections (widgetUTMParams) {
    const {
      widget: {
        baseUri = ''
      }
    } = this.props

    return (
      <FooterLinksContainer>
        <RegularCol sm={12}>
          <UnorderedList>
            {
              FOOTER_LINKS_SECTIONS.map(({ location, text, linkProps, omitUTM = false }, index) => {
                const utmParams = Object.assign({}, widgetUTMParams, {
                  utmTerm: `position-${index}`
                })

                const uri = (baseUri !== '' && location && location.indexOf('http') === -1)
                  ? `${baseUri}${location}`
                  : location
                const href = omitUTM ? uri : utmLinkGenerator.createLink(uri, utmParams)

                return (
                  <ListItem key={`footerSite-${index}`}>
                    <Link onClick={this.handleFooterLink(text)} href={href} title={text} {...linkProps}>
                      { text }
                    </Link>
                  </ListItem>
                )
              })
            }
          </UnorderedList>
        </RegularCol>
      </FooterLinksContainer>
    )
  }

  /**
   * Sites Links renderer
   * @param {Object} widgetUTMParams
   * @return {XML}
   */
  renderLinkSites (widgetUTMParams) {
    return (
      <FooterSitesContainer>
        <RegularCol sm={12}>
          <UnorderedList>
            {
              FOOTER_LINKS_SITES.map((link, index) => {
                const utmParams = Object.assign({}, widgetUTMParams, {
                  utmTerm: `position-${index}`
                })
                const utmLink = utmLinkGenerator.createLink(link.location, utmParams)

                return (
                  <ListItem key={`footerLink-${index}`}>
                    <strong>
                      <Link onClick={this.handleFooterLink(link.text)} href={utmLink} title={link.text}>
                        { link.text }
                      </Link>
                    </strong>
                  </ListItem>
                )
              })
            }
          </UnorderedList>
          <UnorderedList>
            <ListItem>
              <FooterCopyright/>
            </ListItem>
          </UnorderedList>
        </RegularCol>
      </FooterSitesContainer>
    )
  }

  /**
   * @return {XML}
   */
  render () {
    if (!this.props.widget) return null

    const {
      widget: {
        utmMedium
      }
    } = this.props

    const widgetUTMParams = {
      utmMedium,
      utmCampaign: 'static'
    }

    return (
      <RegularRow>
        <FooterCol sm={12}>
          { this.renderLinkSites(widgetUTMParams) }
          { this.renderLinkSections(widgetUTMParams) }
        </FooterCol>
      </RegularRow>
    )
  }
}
