import styled from 'styled-components'
import { SIZES } from '../../../../../constants'
import SearchInput from '../StyledSearchInput'

export default styled(SearchInput)`
  @media (min-width: ${SIZES.BREAKPOINT_XS}px) {
    background-color: #fff;

    &:focus {
      background-color: #fff;
    }
  }
`
