import styled from 'styled-components'

export default styled.span`
  background-image: url(${UNVERSIONED_STATICS_PATH}images/horoscopes/${props => `${props.theme.name}/${props.sign}.png`});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  content: '';
  height: 44px;
  opacity: 0.2;
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
`
