/**
 * Cookies Handler
 */
export default class Cookie {
  constructor () {
    this.cookieJar = {}
    this.init()
  }

  init () {
    try {
      if (typeof document !== 'undefined' && document.cookie) {
        const decodedCookie = decodeURIComponent(document.cookie)
        if (decodedCookie) {
          let cookieJar = {}
          for (const cookieItem of decodedCookie.split(';')) {
            const cookieItemParts = cookieItem.trim().split('=')
            cookieJar[cookieItemParts[0].trim()] = cookieItemParts[1] ? cookieItemParts[1] : ''
          }
          this.cookieJar = cookieJar
        }
      }
    } catch (e) {}
  }

  isSet (cookieName) {
    return typeof this.cookieJar[cookieName] !== 'undefined'
  }

  isSetWithValue (cookieName, cookieValue) {
    return this.cookieJar[cookieName] === cookieValue
  }

  get cookieJar () {
    return this._cookieJar
  }

  set cookieJar (cookieJar) {
    this._cookieJar = cookieJar
  }
}
