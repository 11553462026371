import React, { Component } from 'react'
import styled from 'styled-components'
import SashecAdvertPositionWrapper from './SashecAdvertPositionWrapper'

const TOP_OFFSET = 80

const Wrapper = styled.div`
display: contents;
position: relative;
height: 100%;
`

const StickyElement = styled.div`
position: sticky;
top: ${TOP_OFFSET}px;
`

export default class StickyHalfpage extends Component {
  constructor (props) {
    super(props)

    this.state = {
      width: 0
    }
  }

  componentDidMount () {
    const halfPage = document.getElementById('reklama-halfpage')
    const headerElement = document.getElementById('header')
    const newsFeedElement = document.getElementById('newsfeed-container')
    const previousWidget = this.wrapper.parentNode.previousSibling
    const width = this.wrapper.clientWidth

    this.setState({
      halfPage,
      headerElement,
      newsFeedElement,
      previousWidget,
      width
    })
  }

  render () {
    const {
      widget
    } = this.props

    return (
      <Wrapper>
        <StickyElement innerRef={el => {
          this.wrapper = el
        }}>
          <SashecAdvertPositionWrapper widget={widget}/>
        </StickyElement>
      </Wrapper>
    )
  }
}
