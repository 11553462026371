import styled from 'styled-components'
import { SIZES } from '../../../../../constants'

export default styled.div`
  background: #fff;
  border: 1px solid #ccc;
  border-top: 0;
  left: 0;
  position: absolute;
  top: 30px;
  width: 100%;
  z-index: 100;
  
  @media (min-width: ${SIZES.BREAKPOINT_XS}px) {
    top: 47px;
    max-width: unset;
  }
`
