import React, { forwardRef } from 'react'
import { StyledClickableIcon } from '../StyledClickableIcon'

const DATA = {
  small: {
    size: 14,
    d: 'M1.66634 11.3334C1.29967 11.3334 0.985897 11.203 0.725008 10.9421C0.463675 10.6807 0.333008 10.3667 0.333008 10.0001V2.00008C0.333008 1.63341 0.463675 1.31964 0.725008 1.05875C0.985897 0.797415 1.29967 0.666748 1.66634 0.666748H12.333C12.6997 0.666748 13.0137 0.797415 13.275 1.05875C13.5359 1.31964 13.6663 1.63341 13.6663 2.00008V10.0001C13.6663 10.3667 13.5359 10.6807 13.275 10.9421C13.0137 11.203 12.6997 11.3334 12.333 11.3334H1.66634ZM6.99967 6.66675L1.66634 3.33341V10.0001H12.333V3.33341L6.99967 6.66675ZM6.99967 5.33341L12.333 2.00008H1.66634L6.99967 5.33341ZM1.66634 3.33341V2.00008V10.0001V3.33341Z'
  },
  medium: {
    size: 24,
    d: 'M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.196 19.021 2 18.55 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.97933 4.196 3.45 4 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.8043 4.97933 22 5.45 22 6V18C22 18.55 21.8043 19.021 21.413 19.413C21.021 19.8043 20.55 20 20 20H4ZM12 13L4 8V18H20V8L12 13ZM12 11L20 6H4L12 11ZM4 8V6V18V8Z'
  }
}

export const MailIcon = forwardRef(({ onClick, type }, ref) => {
  const data = DATA[type]

  if (!data) {
    return null
  }

  const { size, d } = data

  return (
    <StyledClickableIcon
      innerRef={ref}
      onClick={onClick}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path d={d} />
    </StyledClickableIcon>
  )
})
