import React, { Component } from 'react'
import styled from 'styled-components'
import LinkHandler from './LinkHandler'
import Link from './Link'
import { sendWidgetClick } from './ga4Events'
import { getLinkTarget } from './getLinkTarget'

const StyledMoreLink = styled(Link)`
  color: ${props => props.theme.moreLink.color};
  font-size: 14px;
  line-height: 22px;
  text-decoration: underline;
  z-index: 1;
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    margin-left: 0px;
  }
`

/**
 * Component for Widget more link rendering
 */
export default class MoreLink extends Component {
  handleClick = () => {
    const {
      widget: {
        name,
        meta: {
          more: { location }
        }
      }
    } = this.props
    sendWidgetClick({
      name,
      click: {
        value: location,
        type: 'more'
      }
    })
  }

  /**
   *
   * @returns {null|XML}
   */
  render () {
    if (typeof this.props.widget.meta.more === 'undefined') {
      return null
    }

    const {
      widget: {
        meta: {
          more
        },
        utmContent,
        utmMedium,
        utmSource
      },
      activeSection,
      layout
    } = this.props
    const {
      text,
      location
    } = (Array.isArray(more)) ? more[activeSection] : more
    const utmParams = {
      utmContent,
      utmMedium,
      utmSource,
      utmTerm: 'morelink'
    }
    const link = new LinkHandler(location).addUTMParams(utmParams).link

    return (
      <StyledMoreLink
        onClick={this.handleClick}
        href={link}
        title={text}
        target={getLinkTarget(layout)}
        rel='noopener'
      >
        { text }
      </StyledMoreLink>
    )
  }
}
