/* @flow */

import React, { Component } from 'react'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'
import { DEVICES, SIZES, TOGGLE_WIDGET_NAMES } from '../../constants'
import { RegularCol, RegularRow } from '../utils/Grid'
import Headline from '../utils/Headline'
import MoreLink from '../utils/MoreLink'
import { type WidgetContainerProps } from './Types'
import { Separator, SeparatorLine } from '../SeparatorLine'

const WrapperRow = styled(RegularRow)`
  display: -ms-flexbox;
  border: 0;
  min-height: 39px;
  position: relative;
`

const ChildrenWrapper = styled.div`
  margin-bottom: ${props => props.isOpen ? '39px' : '0'};
  margin-top: 39px;
  overflow: ${props => props.isOpening ? 'hidden' : 'none'};
  position: relative;
  transition: all ease 0.2s;
  width: 100%;
  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    margin-bottom: 10px;
    margin-top: 34px;
  }
`

const Toggler = styled.div`
  display: flex;
  align-items: flex-end;
  cursor: pointer;
  font-size: 12px;
  color: ${props => props.theme.moreLink.color};
`

const ToggleIconWrapper = styled.div`
  background: #fff;
  display: flex;
  margin: 0 auto;
  margin-bottom: ${props => props.isOpen ? '2px' : '7px'};
  width: 26px;
`

const ToggleIcon = styled.div`
  width: 8px;
  height: 8px;
  border-left: 1px solid ${props => props.theme.moreLink.color};
  border-top: 1px solid ${props => props.theme.moreLink.color};
  transform: ${props => props.isOpen ? 'rotate(45deg)' : 'rotate(225deg)'};
  margin: 0 auto;
`

export default class WidgetContainer extends Component<WidgetContainerProps> {
  static defaultProps = {
    activeSection: 0,
    isOpen: true,
    isOpening: false,
    showBottomLine: true
  }

  handleHeadlineClick = (e: any) => {
    const { handleHeadlineClick, googleAnalytics } = this.props
    if (!handleHeadlineClick || !googleAnalytics) {
      return
    }
    handleHeadlineClick(e)
  }

  render () {
    const {
      activeDynamicLayout: device,
      activeSection,
      children,
      handleToggleContent,
      headlineContent,
      additionalHeadlineContent,
      isOpen,
      isOpening,
      showBottomLine,
      className,
      widget,
      googleAnalytics,
      overrideHeadlineLink,
      hideTitle,
      widget: {
        name,
        showId
      }
    } = this.props
    const widgetTogglerName = TOGGLE_WIDGET_NAMES[name]
    const isReallyOpen = device === DEVICES.MOBILE ? isOpen : true

    return (
      <WrapperRow id={showId ? name : undefined} className={className}>
        <RegularCol sm={12}>
          <Headline
            handleHeadlineClick={this.handleHeadlineClick}
            isActive={isReallyOpen}
            widget={widget}
            label={headlineContent}
            layout={device}
            overrideLink={overrideHeadlineLink}
            hideTitle={hideTitle}
          >
            {
              device !== DEVICES.MOBILE && isReallyOpen &&
                <MoreLink
                  googleAnalytics={googleAnalytics}
                  widget={widget}
                  activeSection={activeSection}
                  layout={device}
                />
            }
            { device === DEVICES.MOBILE && showBottomLine &&
              <Separator>
                {additionalHeadlineContent}
                { isOpening &&
                  <Toggler onClick={handleToggleContent}>
                    <span>
                      { (isReallyOpen ? 'Skrýt ' : 'Zobrazit ') + widgetTogglerName }
                    </span>
                    <ToggleIconWrapper isOpen={isReallyOpen}>
                      <ToggleIcon isOpen={isReallyOpen}/>
                    </ToggleIconWrapper>
                  </Toggler>
                }
              </Separator>
            }
          </Headline>
          <ChildrenWrapper isOpen={isReallyOpen} isOpening={isOpening}>
            <AnimateHeight height={isReallyOpen ? 'auto' : '0'}>
              { children }
            </AnimateHeight>
          </ChildrenWrapper>
          { device !== DEVICES.MOBILE && showBottomLine && <SeparatorLine/> }
        </RegularCol>
      </WrapperRow>
    )
  }
}
