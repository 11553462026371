import styled from 'styled-components'
import { SIZES } from '../../constants'
import Box from './Box'

export default styled(Box)`
  color: #333;
  display: flex;
  display: -ms-flexbox;
  position: relative;
  overflow: hidden;
  max-width: 100%;

  & .image-column {
    height: auto;
    position: relative;
  }
  & .title-column {
    flex: 1 1 0;
    -ms-flexbox: 1 1 0;
    font-size: 13px;
    line-height: 18px;
    margin-left: 0;
    margin-top: 5px;
  }

  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    & .title-column {
      margin-left: 15px;
    }
  }

  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    flex-direction: column;
    -ms-flex-direction: column;
  }
`
