import Input from 'reactstrap/lib/Input'
import styled from 'styled-components'

export default styled(Input)`
  width: 100%;
  background-color: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 0;
  color: #333;
  font-size: 13px;
  height: 26px !important;
  line-height: 26px;
  padding: 0 6px;
  &:focus {
    border: 1px solid #d2d2d2;
    box-shadow: none;
  }
`
