import React, { Fragment, Component } from 'react'
import Main from '../components/containers/Main'
import Header from '../components/containers/Header'
import Content from '../components/containers/Content'
import LeftColumn from '../components/containers/LeftColumn'
import RightColumn from '../components/containers/RightColumn'
import Bottom from '../components/containers/Bottom'
import Footer from '../components/widgets/Footer'
import ErrorBoundaryComponent from '../components/utils/ErrorBoundaryComponent'
import FooterSections from '../components/widgets/Footer/FooterSections'
import FooterLinks from '../components/widgets/Footer/FooterLinks'
import ExternalScripts from '../components/widgets/ExternalScripts'
import { injectGlobal } from 'styled-components'

injectGlobal`
  #homepage {
    font-family: Roboto;
  }
  .sashec-preserve-scope {
    height: 0;
  }
`

export default class LayoutUI extends Component {
  render () {
    const {
      bottom,
      header,
      layout,
      leftColumn,
      outsideContent,
      overContent,
      rightColumn,
      top,
      widgets,
      googleAnalytics = {},
      loginModalRef,
      userMenuRef,
      onHeaderHeightChange
    } = this.props

    const {
      trackers: {
        events
      } = {}
    } = googleAnalytics
    return (
      <Fragment>
        { top }
        <Main layout={layout}>
          <Header
            loginModalRef={loginModalRef}
            userMenuRef={userMenuRef}
            onHeightChange={onHeaderHeightChange}
          >
            { header }
          </Header>
          { overContent }
          <Content>
            <LeftColumn>
              { leftColumn }
            </LeftColumn>
            <RightColumn>
              { rightColumn }
            </RightColumn>
          </Content>
          <Bottom>
            { bottom }
          </Bottom>
          <Footer>
            <ErrorBoundaryComponent widgetName='footerSections'>
              <FooterSections googleAnalytics={events} widgets={widgets}/>
            </ErrorBoundaryComponent>
            <ErrorBoundaryComponent widgetName='footerLinks'>
              <FooterLinks googleAnalytics={events} widget={widgets.footerlinks}/>
            </ErrorBoundaryComponent>
          </Footer>
          { outsideContent }
          <ErrorBoundaryComponent widgetName='bottomScripts'>
            <ExternalScripts widget={widgets.bottomscripts}/>
          </ErrorBoundaryComponent>
        </Main>
      </Fragment>
    )
  }
}
