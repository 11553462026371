// @flow
import React, { Component } from 'react'
import { VerticalCol, VerticalRow } from '../utils/Grid'
import WidgetContainer from '../containers/WidgetContainer'
import GamesBox from '../boxes/GamesBox'
import utmLinkGenerator from '../utils/UTMLinkGenerator'
import { type GamesWidgetProps } from './Types'
import { sendArticleClick } from '../utils/ga4Events'

declare var UNVERSIONED_STATICS_PATH: string

export default class Games extends Component<GamesWidgetProps> {
  render () {
    const {
      isOpening,
      widget,
      googleAnalytics,
      activeDynamicLayout,
      widget: {
        boxes,
        imageSize = '4_3',
        utmMedium
      },
      itemVariant
    } = this.props

    if (!boxes.length) {
      return null
    }

    const handleAnchorClick = ({ box, position }) => () =>
      sendArticleClick({
        position,
        id: box.guid,
        url: box.link,
        boxName: widget.name,
        title: box.title,
        variant: itemVariant
      })

    return (
      <WidgetContainer googleAnalytics={googleAnalytics} widget={widget} isOpening={isOpening}>
        <VerticalRow>
          {
            boxes.map((box, index) => {
              const {
                guid,
                images,
                link,
                source,
                title
              } = box

              const image = `${UNVERSIONED_STATICS_PATH}${images[imageSize]}`

              const utmLink = utmLinkGenerator.createLink(link, {
                utmMedium,
                utmTerm: `position-${boxes.indexOf(box)}`,
                utmCampaign: source
              })

              return (
                <VerticalCol key={guid}>
                  <GamesBox
                    image={image}
                    imageClass={`image-${imageSize}`}
                    link={utmLink}
                    title={title}
                    handleAnchorClick={handleAnchorClick({ box, position: index + 1 })}
                    layout={activeDynamicLayout}
                  />
                </VerticalCol>
              )
            })
          }
        </VerticalRow>
      </WidgetContainer>
    )
  }
}
