import styled from 'styled-components'
import Link from '../../utils/Link'

export default styled(Link)`
  background: ${props => props.theme.tip.background};
  color: ${props => props.theme.tip.color};
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 20px;
  margin-top: 12px;
  overflow: hidden;
  padding: 10px;
  text-align: center;
  text-overflow: ellipsis;
  width: 100%;
  
  &:hover {
    color: ${props => props.theme.tip.color};
  }
`
