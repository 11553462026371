import styled from 'styled-components'

export default styled.div`
  width: 24px;
  height: 24px;
  border: 4px solid #fff;
  border-top: 4px solid ${props => props.theme.button.background};
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`
