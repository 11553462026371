export const EXCHANGE_BASE_URL = 'https://www.exchange.cz/'

export const EXCHANGE_FORM = {
  CURRENCIES: [
    'AUD',
    'CAD',
    'CHF',
    'DKK',
    'EUR',
    'GBP',
    'HRK',
    'HUF',
    'JPY',
    'NOK',
    'PLN',
    'RUB',
    'SEK',
    'USD'
  ],
  OPERATIONS: [
    {
      'value': 'sell',
      'text': 'Chci prodat'
    },
    {
      'value': 'buy',
      'text': 'Chci koupit'
    }
  ]
}

export const EXCHANGE_FORMAT_REGEXP = '^\\d+([.,]\\d*)?$'

const EXCHANGE_IMAGES_BASE_PATH = 'images/exchange'
export const EXCHANGE_IMAGES_PATH = {
  ARTICLES: `${EXCHANGE_IMAGES_BASE_PATH}/articles/`,
  FLAGS: `${EXCHANGE_IMAGES_BASE_PATH}/flags/`
}
