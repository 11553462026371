import styled from 'styled-components'

export default styled.div`
  font-size: 15px;
  padding: 16px;
  background: ${props => props.embedded ? 'transparent' : props.theme.email.full.backgroundColor};
  border-radius: 8px;
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
`
