import atlas from './atlas'
import centrum from './centrum'
import volny from './volny'
import pocasi from './pocasi'
import kecy from './kecy'

export default {
  atlas,
  centrum,
  volny,
  pocasi,
  kecy
}
