import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AMPLION_URL_REGEXP, DEVICES } from '../../../constants'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import TipLink from './StyledTipLink'

class PromoLink extends Component {
  render () {
    const {
      activeDynamicLayout,
      widget: {
        name,
        boxes,
        utmMedium
      }
    } = this.props

    if (!boxes.length > 0) {
      return null
    }

    const {
      link,
      source,
      title
    } = boxes[0]

    if (activeDynamicLayout === DEVICES.MOBILE && new RegExp(AMPLION_URL_REGEXP).test(link)) {
      return null
    }

    const utmLink = utmLinkGenerator.createLink(link, {
      utmMedium,
      utmTerm: `position-0`,
      utmCampaign: source
    })

    return (
      <div id={name}>
        <TipLink href={utmLink}>
          { title }
        </TipLink>
      </div>
    )
  }
}

PromoLink.propTypes = {
  widget: PropTypes.object.isRequired
}

export default PromoLink
