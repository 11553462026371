import React, { Component } from 'react'
import LeafletCol from '../widgets/Leaflets/StyledLeafletCol'
import LeafletImage from './images/LeafletImage'
import LeafletLink from '../widgets/Leaflets/StyledLeafletLink'
import styled from 'styled-components'
import { SIZES } from '../../constants'
import { getLinkTarget } from '../utils/getLinkTarget'

const MoreButton = styled.div`
  background-color: ${props => props.theme.button.background};
  border: 1px solid ${props => props.theme.button.border};
  border-radius: 4px;
  color: ${props => props.theme.button.color};
  font-size: 11px;
  font-weight: 900;
  height: 35px;
  line-height: 35px;
  margin-top: 10px;
  text-align: center;
  text-transform: uppercase;
  width: 99px;
  align-self: flex-end;
  &:hover {
    color: ${props => props.theme.button.color};
    border: 1px solid ${props => props.theme.button.hover.border};
    background-color: ${props => props.theme.button.hover.background};
  }

  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    display: none;
  }
`

const LeafletTextBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    padding-right: 10px;
    justify-content: flex-start;
  }

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    flex: 0 0 100%;
    padding-right: 0;
  }
`

const Title = styled.div.attrs({
  className: 'title'
})`
  flex: 0 0 100%;
  -ms-flexbox: auto;
  font-size: 13px;
  line-height: 18px;
  max-width: 100%;
  overflow: hidden;
  & span {
    font-weight: bold;
    white-space: nowrap;
  }

  @media (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    flex: 0 0 100%;
    margin-top: 10px;
  }

  @media (max-width: ${SIZES.BREAKPOINT_XS - 1}px) {
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
  }
`

export default class LeafletBox extends Component {
  render () {
    const {
      image,
      imageClass,
      link,
      title,
      handleAnchorClick,
      guid,
      layout
    } = this.props

    return (
      <LeafletCol>
        <LeafletLink
          onClick={(event) => handleAnchorClick(event, link, title, guid)}
          target={getLinkTarget(layout)}
          rel='noopener'
        >
          <LeafletImage image={image} imageClass={imageClass} title={title}/>
          <LeafletTextBox>
            <Title>{title}</Title>
            <MoreButton>ZJISTĚTE VÍCE</MoreButton>
          </LeafletTextBox>
        </LeafletLink>
      </LeafletCol>
    )
  }
}
