import React, { Component } from 'react'
import styled from 'styled-components'
import FooterBrand from './FooterBrand'
import FooterFeed from './FooterFeed'

const Container = styled.div`
  display: flex;
  display: -ms-flexbox;
`

export default class FooterSections extends Component {
  render () {
    if (!this.props.widgets) return null

    const {
      widgets,
      googleAnalytics
    } = this.props

    return (
      <Container>
        <FooterBrand googleAnalytics={googleAnalytics} widget={widgets.footerbrand}/>
        <FooterFeed googleAnalytics={googleAnalytics} widget={widgets.footersectionnews}/>
        <FooterFeed googleAnalytics={googleAnalytics} widget={widgets.footersectionmagazines}/>
        <FooterFeed googleAnalytics={googleAnalytics} widget={widgets.footersectionservices}/>
        <FooterFeed googleAnalytics={googleAnalytics} widget={widgets.footersectioncatalogues}/>
        <FooterFeed googleAnalytics={googleAnalytics} widget={widgets.footersectioncommunication}/>
      </Container>
    )
  }
}
