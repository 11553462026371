import React, { Component } from 'react'
import styled from 'styled-components'
import { SIZES } from '../../../constants'
import utmLinkGenerator from '../../utils/UTMLinkGenerator'
import Link from '../../utils/Link'
import FooterBrandLinksContainer from './StyledFooterBrandLinksContainer'
import Logo from './StyledLogo'
import { sendWidgetClick } from '../../utils/ga4Events'

const Container = styled.div`
  flex: 1 1 auto;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  -ms-flex-direction: column;
  max-width: 100%;
  padding-right: 10px;
  @media (min-width: ${SIZES.BREAKPOINT_MD}px) {
    max-width: 230px;
  }
`

/**
 * Footer Brand renderer
 */
export default class FooterBrand extends Component {
  handleLinkClick = name => () => {
    sendWidgetClick({
      name: this.props.widget.name,
      click: {
        type: 'footer',
        value: name
      }
    })
  }
  /**
   * Brand Links Renderer
   * @param {Object} widget
   * @return {XML}
   */
  renderLinkBrand (widget) {
    const {
      utmMedium,
      meta: {
        linkBrand = []
      }
    } = widget

    return (
      <FooterBrandLinksContainer>
        {
          linkBrand.map((link, index) => {
            const utmParams = {
              utmMedium,
              utmTerm: `position-${index}`,
              utmCampaign: 'static'
            }
            const utmLink = utmLinkGenerator.createLink(link.location, utmParams)

            return (
              <li key={`footerBrand-${index}`}>
                <Link onClick={this.handleLinkClick(link.text)} href={utmLink} title={link.text}>
                  <strong>
                    { link.text }
                  </strong>
                </Link>
              </li>
            )
          })
        }
      </FooterBrandLinksContainer>
    )
  }

  /**
   * @return {XML}
   */
  render () {
    if (!this.props.widget) return null
    const {
      widget,
      widget: {
        meta: {
          logo
        }
      }
    } = this.props

    return (
      <Container>
        <Link href={logo.location} title={logo.text}>
          <Logo alt={logo.text} src={`${STATICS_PATH}${logo.src}`}/>
        </Link>
        { this.renderLinkBrand(widget) }
      </Container>
    )
  }
}
