import React from 'react'
import { ARTICLE_FEATURED_TYPES } from '../../constants'
import ArticleOverlay from '../utils/ArticleOverlay'
import Link from '../utils/Link'
import { type ListBoxProps } from './Types'
import { getVideoLabel } from '../utils/VideoLabel'
import ListItem from './StyledSmallVideoListItem'
import { getVideoFeaturedTypes } from '../utils/getVideoFeaturedTypes'

export default (props: ListBoxProps) => {
  const {
    image,
    className,
    featuredType = 'video',
    link,
    title,
    duration,
    handleAnchorClick
  } = props

  const isFeatured = ARTICLE_FEATURED_TYPES.COMMON.includes(featuredType)

  return (
    <ListItem className={className}>
      <Link onClick={handleAnchorClick} href={link} target='_blank' rel='noopener'>
        <div>
          <img
            src={image}
            alt={title}/>
          {
            getVideoFeaturedTypes().includes(featuredType) &&
            <ArticleOverlay className={`video`}>
              {
                getVideoLabel(featuredType, duration)
              }
            </ArticleOverlay>
          }
        </div>
        <span className={`title${isFeatured ? ' label' : ''}`}>
          { title }
        </span>
      </Link>
    </ListItem>
  )
}
