export const SIGN_NAMES = {
  BERAN: 'beran',
  BYK: 'byk',
  BLIZENCI: 'blizenci',
  RAK: 'rak',
  LEV: 'lev',
  PANNA: 'panna',
  VAHY: 'vahy',
  STIR: 'stir',
  STRELEC: 'strelec',
  KOZOROH: 'kozoroh',
  VODNAR: 'vodnar',
  RYBY: 'ryby'
}

export const SIGNS = {
  [SIGN_NAMES.BERAN]: {
    guid: 'beran',
    name: 'Beran',
    date: '21.3. - 20.4.'
  },
  [SIGN_NAMES.BYK]: {
    guid: 'byk',
    name: 'Býk',
    date: '21.4. - 21.5.'
  },
  [SIGN_NAMES.BLIZENCI]: {
    guid: 'blizenci',
    name: 'Blíženci',
    date: '22.5. - 21.6.'
  },
  [SIGN_NAMES.RAK]: {
    guid: 'rak',
    name: 'Rak',
    date: '22.6. - 22.7.'
  },
  [SIGN_NAMES.LEV]: {
    guid: 'lev',
    name: 'Lev',
    date: '23.7. - 22.8.'
  },
  [SIGN_NAMES.PANNA]: {
    guid: 'panna',
    name: 'Panna',
    date: '23.8. - 22.9.'
  },
  [SIGN_NAMES.VAHY]: {
    guid: 'vahy',
    name: 'Váhy',
    date: '23.9. - 23.10.'
  },
  [SIGN_NAMES.STIR]: {
    guid: 'stir',
    name: 'Štír',
    date: '24.10. - 22.11.'
  },
  [SIGN_NAMES.STRELEC]: {
    guid: 'strelec',
    name: 'Střelec',
    date: '23.11. - 21.12.'
  },
  [SIGN_NAMES.KOZOROH]: {
    guid: 'kozoroh',
    name: 'Kozoroh',
    date: '22.12. - 20.1.'
  },
  [SIGN_NAMES.VODNAR]: {
    guid: 'vodnar',
    name: 'Vodnář',
    date: '21.1. - 20.2.'
  },
  [SIGN_NAMES.RYBY]: {
    guid: 'ryby',
    name: 'Ryby',
    date: '21.2. - 20.3.'
  }
}

export const SIGN_INTERVALS = [
  {
    1: SIGN_NAMES.KOZOROH,
    21: SIGN_NAMES.VODNAR
  },
  {
    1: SIGN_NAMES.VODNAR,
    21: SIGN_NAMES.RYBY
  },
  {
    1: SIGN_NAMES.RYBY,
    21: SIGN_NAMES.BERAN
  },
  {
    1: SIGN_NAMES.BERAN,
    21: SIGN_NAMES.BYK
  },
  {
    1: SIGN_NAMES.BYK,
    22: SIGN_NAMES.BLIZENCI
  },
  {
    1: SIGN_NAMES.BLIZENCI,
    22: SIGN_NAMES.RAK
  },
  {
    1: SIGN_NAMES.RAK,
    23: SIGN_NAMES.LEV
  },
  {
    1: SIGN_NAMES.LEV,
    23: SIGN_NAMES.PANNA
  },
  {
    1: SIGN_NAMES.PANNA,
    23: SIGN_NAMES.VAHY
  },
  {
    1: SIGN_NAMES.VAHY,
    24: SIGN_NAMES.STIR
  },
  {
    1: SIGN_NAMES.STIR,
    23: SIGN_NAMES.STRELEC
  },
  {
    1: SIGN_NAMES.STRELEC,
    22: SIGN_NAMES.KOZOROH
  }
]

export const SIGNS_LIST = [
  SIGN_NAMES.BERAN,
  SIGN_NAMES.BYK,
  SIGN_NAMES.BLIZENCI,
  SIGN_NAMES.RAK,
  SIGN_NAMES.LEV,
  SIGN_NAMES.PANNA,
  SIGN_NAMES.VAHY,
  SIGN_NAMES.STIR,
  SIGN_NAMES.STRELEC,
  SIGN_NAMES.KOZOROH,
  SIGN_NAMES.VODNAR,
  SIGN_NAMES.RYBY
]
