import styled from 'styled-components'
import Link from '../../../utils/Link'

const StyledLink = styled(Link)`
  color: #fff;

  &:hover {
    color: #fff;
  }
`

const CloseButton = styled(StyledLink)`
  font-size: 19px;
  line-height: 22px;
  width: 22px;
  border-radius: 50%;
  background: #ff9b53;
  text-align: center;
  flex-shrink: 0;

  &:hover {
    text-decoration: none;
  }
`

const DownloadLink = styled(StyledLink)`
  margin-left: 8px;
  text-decoration: underline;
`

export {
  CloseButton,
  DownloadLink
}
