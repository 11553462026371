const TEST_KEY = 'localstorage-test'
const KEY = 'settings'
const DEFAULTS = {}

class Settings {
  constructor () {
    this.storage = {}
    this.setAll(Object.assign({}, DEFAULTS, this.getAll()))
  }

  /**
   * @return {Storage}
   */
  getDriver () {
    let driver = this

    try {
      window.localStorage.setItem(TEST_KEY, new Date())
      window.localStorage.removeItem(TEST_KEY)
      driver = window.localStorage
    } catch (error) {}

    return driver
  }

  /**
   * @return {Object}
   */
  getAll () {
    return JSON.parse(this.getDriver().getItem(KEY) || '{}')
  }

  /**
   * @param {Object} values
   */
  setAll (values) {
    this.getDriver().setItem(KEY, JSON.stringify(values))
  }

  /**
   * @param key
   * @return {*}
   */
  getItem (key) {
    return this.storage[key]
  }

  /**
   * @param key
   * @param value
   */
  setItem (key, value) {
    this.storage[key] = value
  }

  /**
   * @param key
   * @param defaultValue
   * @return {*}
   */
  getKey (key, defaultValue) {
    const settings = this.getAll()
    return (settings[key] !== undefined) ? settings[key] : defaultValue
  }

  /**
   * @param key
   * @param value
   */
  setKey (key, value) {
    const settings = this.getAll()
    settings[key] = value
    this.setAll(settings)
  }
}

const settings = new Settings()

export default settings
