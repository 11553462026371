import React, { Component } from 'react'
import { SystemConsumer } from '../../../context/System'
import Android from './Android'
import { OS_NAMES } from '../../utils/System'

class NativeAppPromo extends Component {
  shouldComponentUpdate () {
    return false
  }

  getPromoComponent (os, config) {
    const {
      widget: widgetConfig
    } = this.props
    let component

    if (os && config) {
      const components = {
        [OS_NAMES.ANDROID]: () => {
          const widget = Object.assign({}, widgetConfig, { name: 'androidAppPromo' })
          return <Android widget={widget} config={config}/>
        }
      }

      if (components[os]) {
        component = components[os]
      }
    }

    return component
  }

  render () {
    if (typeof window !== 'undefined') {
      const {
        widget: {
          meta
        }
      } = this.props
      const getPromoComponent = this.getPromoComponent.bind(this)

      return (
        <SystemConsumer>{
          system => {
            const config = meta[system.osName]
            const $component = getPromoComponent(system.osName, config)
            return $component ? <$component/> : null
          }
        }
        </SystemConsumer>
      )
    }
  }
}

export default NativeAppPromo
