export const FOOTER_LINKS_SECTIONS = [
  {
    text: 'O nás',
    location: 'https://www.economia.cz/project/o-nas',
    linkProps: {
      target: '_blank'
    }
  },
  {
    text: 'Všechny služby',
    location: '/sluzby/',
    linkProps: {
      target: '_blank'
    }
  },
  {
    text: 'Volná místa',
    location: 'http://economia.jobs.cz/',
    linkProps: {
      target: '_blank'
    }
  },
  {
    text: 'Služby firmám',
    location: '/agentura-najisto/',
    linkProps: {
      target: '_blank'
    }
  },
  {
    text: 'Všeobecné podmínky',
    location: 'https://economia.cz/smluvni-podminky-sluzby-centrum-cz-e-mail/',
    linkProps: {
      target: '_blank'
    }
  },
  {
    text: 'Cookies',
    location: 'https://economia.cz/prohlaseni-o-cookies/',
    linkProps: {
      target: '_blank'
    }
  },
  {
    text: 'Nastavení soukromí',
    location: 'javascript:void(0);',
    omitUTM: true,
    linkProps: {
      className: 'eco-cmp-show-settings',
      onClick: () => {
        try {
          window && window.OneTrust.ToggleInfoDisplay()
        } catch (e) {
        }
      }
    }
  },
  {
    text: 'Ochrana osobních údajů',
    location: 'https://economia.cz/ochrana-osobnich-udaju/',
    linkProps: {
      target: '_blank'
    }
  },
  {
    text: 'Zpracování osobních údajů',
    location: 'https://economia.cz/zpracovani-osobnich-udaju/',
    linkProps: {
      target: '_blank'
    }
  },
  {
    text: 'Nápověda',
    location: 'http://napoveda.centrum.cz/',
    linkProps: {
      target: '_blank'
    }
  }
]

export const FOOTER_LINKS_SITES = [
  {
    text: 'Centrum.cz',
    location: 'https://www.centrum.cz/'
  },
  {
    text: 'Atlas.cz',
    location: 'https://atlas.centrum.cz/'
  },
  {
    text: 'Volny.cz',
    location: 'https://www.volny.cz/'
  }
]
