import styled from 'styled-components'
import { RegularRow } from '../../utils/Grid'
import { SIZES } from '../../../constants'

export default styled(RegularRow)`
  margin: 15px auto 0;
  padding: 0;
  text-align: center;
  @media only screen and (max-width: ${SIZES.BREAKPOINT_MD - 1}px) {
    margin-top: 0;
  }
`
