/* @flow */
import React, { Component, type Element } from 'react'
import Col from 'reactstrap/lib/Col'
import Row from 'reactstrap/lib/Row'
import styled from 'styled-components'
import { DEVICES, DRBNA_REGIONS } from '../../../constants'
import Link from '../../utils/Link'

const RegionPicker = styled.div`
  background: #fff;
  box-shadow: 0 0 30px #b2b2b2;
  display: ${props => props.isOpen ? 'block' : 'none'};
  height: auto;
  padding: 20px;
  position: absolute;
  ${props => props.device === DEVICES.TABLET ? 'left: 166px' : 'right: 0'};
  top: 0;
  width: 340px;
  z-index: 10000;
`

const RegionItem = styled(Link)`
  color: ${props => props.theme.headline.color};
  display: block;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;

  &:focus {
    outline: none;
  }
`

const RemoveWidgetButton = styled(RegionItem)`
  position: absolute;
  bottom: -10px;
  right: 10px;
  font-size: 12px;
  color: #959595;
  
  &:hover {
    color: #959595;
  }
`

type RegionsProps = {|
  activeRegion: string,
  device: string,
  onRegionClick: (activeRegion: string) => void,
  regions: Array<string>,
  isOpen: boolean,
  onRemoveClick: () => void
|}

export default class Regions extends Component<RegionsProps> {
  renderListItems (items: Array<string>): Array<Element<*>> {
    const {
      onRegionClick
    } = this.props

    return (
      items.map(regionItem => {
        return (
          <RegionItem key={regionItem} href='#' onClick={event => {
            event.preventDefault()
            onRegionClick(regionItem)
          }}>
            { DRBNA_REGIONS[regionItem] }
          </RegionItem>
        )
      })
    )
  }

  render () {
    const {
      device,
      isOpen,
      regions,
      onRemoveClick
    } = this.props
    const halfIndex = Math.ceil(regions.length / 2)

    return (
      <RegionPicker isOpen={isOpen} device={device}>
        <Row>
          <Col xs={6}>
            {this.renderListItems(regions.slice(0, halfIndex))}
          </Col>
          <Col xs={6}>
            {this.renderListItems(regions.slice(halfIndex))}
            <RemoveWidgetButton href='#' onClick={(event) => {
              event.preventDefault()
              onRemoveClick()
            }}>
              Odebrat box
            </RemoveWidgetButton>
          </Col>
        </Row>
      </RegionPicker>
    )
  }
}
