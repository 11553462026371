import { isBrowser } from './isBrowser'
import { SCROLL_FIXED_OFFSET } from '../components/containers/Header'

export function isHeaderFixed ({
  leaderboardHeight,
  rtbLeaderboardHeight,
  mainContentContainerMarginTop
}) {
  return isBrowser()
    ? window.pageYOffset >= leaderboardHeight + rtbLeaderboardHeight + mainContentContainerMarginTop + SCROLL_FIXED_OFFSET
    : false
}
