import React, { Component } from 'react'
import { RegularCol, RegularRow } from './Grid'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import LinkHandler from './LinkHandler'
import Link from './Link'
import { getLinkTarget } from './getLinkTarget'

const HeadlineRow = styled(RegularRow)`
  position: absolute;
  width: 100%;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  -ms-flex-align: stretch;
`

const HeadlineCol = styled(RegularCol)`
  display: flex;
  display: -ms-flexbox;
  -ms-flex-wrap: wrap;
  -ms-flex-align: stretch;
  color: ${props => props.theme.headline.color};
`

const HeadlineLink = styled(Link)`
  color: ${props => props.isActive ? props.theme.headline.color : '#ccc'};
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  background: #fff;
  z-index: 1;
  padding: 0 10px 0 0;
`

const HeadlineContent = styled.div`
  display: flex;
  flex: 1;
  display: -ms-flexbox;
  justify-content: flex-end;
  -ms-flex-wrap: wrap;
  -ms-flex-align: stretch;
  -ms-flexbox: 1;
  padding-left: 10px;
  -ms-flex-pack: end;
`

/**
 * Component for Widget headline rendering
 */
class Headline extends Component {
  /**
   *
   * @returns {null|XML}
   */
  render () {
    const {
      children,
      isActive,
      widget: {
        meta: {
          headline: {
            text: headline,
            location
          } = {},
          admon = null
        },
        utmContent,
        utmMedium,
        utmSource
      },
      label,
      handleHeadlineClick,
      layout,
      overrideLink,
      hideTitle
    } = this.props

    if (hideTitle) {
      return null
    }

    let link = location

    if (typeof location === 'string' && location[0] !== '#') {
      const utmParams = {
        utmContent,
        utmMedium,
        utmSource,
        utmTerm: 'headline'
      }
      link = new LinkHandler(location).addUTMParams(utmParams).applySASRedirect(admon).link
    }

    return (
      <HeadlineRow>
        <HeadlineCol sm={12}>
          <HeadlineLink
            onClick={handleHeadlineClick}
            href={overrideLink || link}
            title={headline}
            isActive={isActive}
            target={getLinkTarget(layout)}
            rel='noopener'
          >
            {headline}
          </HeadlineLink>
          {label}
          <HeadlineContent>
            {children}
          </HeadlineContent>
        </HeadlineCol>
      </HeadlineRow>
    )
  }
}

Headline.propTypes = {
  utmContent: PropTypes.string,
  utmMedium: PropTypes.string,
  utmSource: PropTypes.string,
  isActive: PropTypes.bool
}

Headline.defaultProps = {
  isActive: true
}

export default Headline
